


// function SlashScreen () { 

//     return(
//         <section className="slash-screen">
//             <div className="slash-container">
//                 <div className="image">
//                     <div className="to-rotate">
//                         <svg xmlns="http://www.w3.org/2000/svg" width="91.921" height="91.924" viewBox="0 0 91.921 91.924">
                        
//                         <g transform="translate(-1173.229 -532.273)">
//                             <g className="logo-g-1" transform="translate(1173.229 532.273)">
//                                 <path className="a" d="M1214.539,623.953q-.658-.068-1.316-.155a3.309,3.309,0,1,1,.865-6.561,39.339,39.339,0,1,0-34.176-36.706,3.309,3.309,0,1,1-6.607.383,45.962,45.962,0,1,1,41.234,43.038Z" transform="translate(-1173.229 -532.273)"/>
//                                 </g>
//                                 <g transform="translate(1179.397 595.199)">
//                                     <path className="b" d="M1216.907,797.7a.511.511,0,0,1-.236-.058,43.472,43.472,0,0,1-19.493-20.79.509.509,0,0,1,.931-.413,42.449,42.449,0,0,0,19.035,20.3.509.509,0,0,1-.237.96Z" transform="translate(-1197.134 -776.136)"/>
//                                     </g>
//                                 </g>
//                         </svg>
//                     </div>
//                     <svg xmlns="http://www.w3.org/2000/svg" width="91.921" height="91.924" viewBox="0 0 91.921 91.924">
                        
//                         <g transform="translate(-1173.229 -532.273)">
//                             <g style={{visibility: 'hidden'}} className="logo-g-1" transform="translate(1173.229 532.273)">
//                                 <path className="a" d="M1214.539,623.953q-.658-.068-1.316-.155a3.309,3.309,0,1,1,.865-6.561,39.339,39.339,0,1,0-34.176-36.706,3.309,3.309,0,1,1-6.607.383,45.962,45.962,0,1,1,41.234,43.038Z" transform="translate(-1173.229 -532.273)"/>
//                                 </g>
//                                 <g style={{visibility: 'hidden'}} transform="translate(1179.397 595.199)">
//                                     <path className="b" d="M1216.907,797.7a.511.511,0,0,1-.236-.058,43.472,43.472,0,0,1-19.493-20.79.509.509,0,0,1,.931-.413,42.449,42.449,0,0,0,19.035,20.3.509.509,0,0,1-.237.96Z" transform="translate(-1197.134 -776.136)"/>
//                                     </g>
//                                     <g transform="translate(1188.409 557.888)">
//                                         <path className="a" d="M1260.87,631.541l-8.95,17.052-.009.017-5.2-10.251-3.469-6.818h-11.187l19.661,39.8,5.858-11.632,14.187-28.172Z" transform="translate(-1232.057 -631.541)"/>
//                                         <path className="b" d="M1339.375,633.378l-5.7,10.787,8.959,17.9h-17.206l-4.989,10.668h38.993Z" transform="translate(-1297.633 -632.904)"/>
//                                     </g>
//                                 </g>
//                     </svg>
//                 </div>
//             </div>
//         </section>
//     )
// }

function SlashScreen () {
    return (
      <div className="preloader">
        <div className="logo-wrapper">
    
          {/* <!-- SVG-based animation and responsive --> */}
          <svg id="svg1" xmlns="http://www.w3.org/2000/svg" width="91.921" height="91.924" viewBox="0 0 91.921 91.924">
            <defs>
              <style>
                {
                  `
                  .a {
                    fill: #151d41;
                  }
  
                  .b {
                    fill: #009289;
                  }`
                }
              </style>
            </defs>
            <g transform="translate(-1173.229 -532.273)">
              <g transform="translate(1173.229 532.273)" className="transform-1">
                <path className="a"
                  d="M1214.539,623.953q-.658-.068-1.316-.155a3.309,3.309,0,1,1,.865-6.561,39.339,39.339,0,1,0-34.176-36.706,3.309,3.309,0,1,1-6.607.383,45.962,45.962,0,1,1,41.234,43.038Z"
                  transform="translate(-1173.229 -532.273)" >
                  <animate id="an" attributeName="stroke-dasharray" begin="svg1.click" dur="8s" values="1281,0;0,1281" fill="freeze" repeatCount="1" /> 
                </path>
              </g>
              <g transform="translate(1179.397 595.199)">
                <path className="b"
                  d="M1216.907,797.7a.511.511,0,0,1-.236-.058,43.472,43.472,0,0,1-19.493-20.79.509.509,0,0,1,.931-.413,42.449,42.449,0,0,0,19.035,20.3.509.509,0,0,1-.237.96Z"
                  transform="translate(-1197.134 -776.136)" />
              </g>
              <g transform="translate(1188.409 557.888)">
                <path className="a"
                  d="M1260.87,631.541l-8.95,17.052-.009.017-5.2-10.251-3.469-6.818h-11.187l19.661,39.8,5.858-11.632,14.187-28.172Z"
                  transform="translate(-1232.057 -631.541)" />
                <path className="b" d="M1339.375,633.378l-5.7,10.787,8.959,17.9h-17.206l-4.989,10.668h38.993Z"
                  transform="translate(-1297.633 -632.904)" />
              </g>
            </g>
          </svg>
        </div>
    </div>
    )
}

export default SlashScreen