export const header3CssEffects = () => {
  // Center logo

  let centerLogo = document.getElementById('header-center-logo');
  if (centerLogo) {
    centerLogo.style.backgroundImage = "url('/favicon.svg')";
  }
  // Topbar elements chaidnging

  window.addEventListener('scroll', (scroll) => {
    // //console.log("scroll", scroll, window.scrollY)
    // On scroll
    if (window.scrollY > 0) {
      // on scroll, Change the green logo by default white logo
      let myOva = document.getElementById('header3-company');
      var greenLogoNode = document.getElementById('heaader3-green-logo');
      myOva?.removeAttribute('style');
      if (greenLogoNode) {
        myOva?.removeChild(greenLogoNode);
      }

      // Change green menu to white

      let toggleBar1 = document.getElementById('header3-toggle-bar1');
      let toggleBar2 = document.getElementById('header3-toggle-bar2');
      let toggleBar3 = document.getElementById('header3-toggle-bar3');

      if (toggleBar1) {
        toggleBar1!.style.backgroundColor = '#fff';
      }
      if (toggleBar2) {
        toggleBar2!.style.backgroundColor = '#fff';
      }
      if (toggleBar3) {
        toggleBar3!.style.backgroundColor = '#fff';
      }

      // position and phone icons changing

      let position = document.getElementById('header3-position');

      if (position) {
        position!.className = 'header3-position-scroll position';
      }

      let phone = document.getElementById('header3-phone');

      if (phone) {
        phone!.className = 'header3-phone-scroll phone';
      }

      // Center logo transform it to dark logo

      let centerLogo = document.getElementById('header-center-logo');
      if (centerLogo) {
        centerLogo.style.backgroundImage = "url('/logo_white.png')";
      }

      // make the mystore btn white
      const myStoreBtn = document.querySelector(
        '#header3-company + .my-ova-button.company'
      );
      if (myStoreBtn) myStoreBtn.classList.add('white-color');
    } else if (window.scrollY === 0) {
      //  restore default green image
      let myOva = document.getElementById('header3-company');
      let greenLogo = document.createElement('img');
      greenLogo.setAttribute('id', 'heaader3-green-logo');
      greenLogo.setAttribute('alt', 'ova green logo');
      greenLogo.setAttribute('src', '/assets/img/logo-green.png');
      if (myOva) {
        myOva.style.backgroundImage = 'none';
        myOva?.appendChild(greenLogo);
      }

      // restore green menu to white

      let toggleBar1 = document.getElementById('header3-toggle-bar1');
      let toggleBar2 = document.getElementById('header3-toggle-bar2');
      let toggleBar3 = document.getElementById('header3-toggle-bar3');

      if (toggleBar1) {
        toggleBar1!.style.backgroundColor = '#009289';
      }
      if (toggleBar2) {
        toggleBar2!.style.backgroundColor = '#009289';
      }
      if (toggleBar3) {
        toggleBar3!.style.backgroundColor = '#009289';
      }

      // position and phone icons changing

      let position = document.getElementById('header3-position');
      if (position) {
        position!.className = 'header3-position position';
      }

      let phone = document.getElementById('header3-phone');

      if (phone) {
        phone!.className = 'header3-phone phone';
      }

      // Center logo

      let centerLogo = document.getElementById('header-center-logo');
      if (centerLogo) {
        centerLogo.style.backgroundImage = "url('/favicon.svg')";
      }
      // make the mystore btn green
      const myStoreBtn = document.querySelector(
        '#header3-company + .my-ova-button.company'
      );
      if (myStoreBtn) myStoreBtn.classList.remove('white-color');
    }
  });
};
