import Page from '../../../../page/Page';
import Box from '@mui/material/Box';
import EquipementsStore from './components/EquipementsStore';
import { useParams } from 'react-router-dom';
import StoreNav from './components/navigation/StoreNav';
import Header1 from '../../../../components/header/Header1';
import Header3 from '../../../../components/header/Header3';
import Header from '../../../../components/header/Header';

const drawerWidth = 250;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

function LicensesEquipements(props: Props) {
  const { store } = useParams();

  return (
    <Page title="OVA consulting | Store">
      <div className="about">
        <Header />
      </div>
      <section className="store-banner">
        <div className="container h-100">
          <div className='d-flex'>
            <div className="m-auto">
            <h1>
              <span className="text-primary">OVA consuting store.</span> The
              best way to buy the {store} you love.
            </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="store-main">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-4 col-12">
              <div>
                <Box>
                  <StoreNav />
                </Box>
              </div>
            </div>
            <div className="col-md-9 col-sm-8 col-12">
              <div>
                <Box>
                  {/* <ShopDrawer/> */}
                  {store === 'equipments' ? (
                    <EquipementsStore />
                  ) : (
                    <EquipementsStore />
                  )}
                </Box>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
}

export default LicensesEquipements;
