import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/footer/Footer';
import Header3 from '../../components/header/Header3';
import Scroller, { ScrollerItem } from '../../components/scroller/Scroller';
import Page from '../../page/Page';
import { aboutAnimationEffects } from './components/animations/aboutAnimations';
import ImagesCaroussel from './components/ImagesCaroussel';
import OurClients from './components/OurClients';
import OurTeam1 from './components/OurTeam1';
import Strategies1 from './components/Strategies1';
import BlockPhotos from '../home/components/BlockPhotos';

export default function AboutUs() {
  const { t } = useTranslation();

  const aboutRef = useRef(null);
  const clientsRef = useRef(null);
  const strategyRef = useRef(null);
  const ourTeamRef = useRef(null);

  const scrollerItems: ScrollerItem[] = [
    {
      title: t('home_about'),
      dataScroller: 'about-us',
      ref: aboutRef,
      bagTheme: 'dark'
    },
    {
      title: t('home_our_clients'),
      dataScroller: 'clients',
      ref: clientsRef,
      bagTheme: 'white'
    },
    {
      title: t('our_strategy'),
      dataScroller: 'our-strategy',
      ref: strategyRef,
      bagTheme: 'white'
    },
    {
      title: t('our_team'),
      dataScroller: 'our-team',
      ref: ourTeamRef,
      bagTheme: 'white'
    }
  ];

  React.useEffect(() => {
    aboutAnimationEffects();
  }, []);

  return (
    <Page
      title={'OVA - Consulting | About us'}
      description={
        'Subsidiary of Ova Corporate SA France, OVA Consulting SARL is a digital company, located in the heart of the city of Douala and specialized in the realization of unique interactive digital experience.'
      }>
      <main className="bg-grey main digit homepage about">
        {/* <!-- SCROLLER START --> */}
        <Scroller items={scrollerItems} />
        {/* <div className="main-scroller">
                        <div className="link active" data-scroller="about-us">
                            <a className="link-text-no-effect" href="#about-us">
                                {t('home_about')}
                            </a>
                        </div> 
                        <div className="link" data-scroller="clients">
                            <a className="link-text-no-effect" href="#clients">
                                {t('home_our_clients')}
                            </a>
                        </div>
                        <div className="link" data-scroller="our-strategy">
                            <a className="link-text-no-effect" href="#our-strategy">
                                {t("our_strategy")}
                            </a>
                        </div>
                        <div className="link" data-scroller="our-team">
                            <a className="link-text-no-effect" href="#our-team">
                                {t("our_team")}
                            </a>
                        </div>
                        <div className="scrolltop"></div>
                    </div> */}
        {/* <!-- SCROLLER END --> */}
        <Header3 />
        <div style={{ height: '200px' }}></div>
        <section
          className="section"
          id="about-us"
          ref={aboutRef}
          style={{
            background: '#ECF4F4',
            textAlign: 'left',
            paddingBottom: '50px'
          }}>
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-9">
                <div style={{ paddingTop: '1rem' }}>
                  <h3 className="about-us-title1">{t('home_about')}</h3>
                  <p className="about-us-title2">{t('home_about')}</p>
                  <div style={{ height: '3rem' }}></div>
                  {/* <p className="about-us-text">{t('about_text_p_1')}</p>
                  <p className="about-us-text">{t('about_text_p_2')}</p>
                  <p className="about-us-text">{t('about_text_p_3')}</p>
                  <p className="about-us-text">{t('about_text_p_4')}</p> */}
                  <p className="about-us-text" dangerouslySetInnerHTML={{__html: t('about_text_v2')}}></p>
                </div>
              </div>
              <div className="col-md-6 col-3">
                <div className="about-div-image-container1 py-3 h-100">
                  <div className="about-div-image1 h-100 d-flex">
                    {/* <img
                      className="about-div-image-i"
                      src="/assets/img/about/about_us.jpg"
                      alt="..."
                    /> */}
                    <div className="about-div-image-deco1">
                    </div>
                    <div className="my-auto about-us-img" style={{height: 'fit-content'}}>
                      <img width={'100%'} src="/assets/img/about/_DSC9502.jpeg" alt="..." />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Section clients */}
        <div ref={clientsRef} className='py-3'>
          <OurClients />
        </div>
        {/* Images caroussel */}
        <div>
          {/* <ImagesCaroussel /> */}
          <BlockPhotos />
        </div>

        {/* <Strategies/> */}
        <div ref={strategyRef}>
          <Strategies1 />
        </div>
        {/* our team section */}

        <div ref={ourTeamRef} className='py-3'>
          <OurTeam1 />
        </div>

        {/* <section className="section" id="our-team">
                    {/* <OurTeam displTitle={true} /> *

                </section> */}
        {/* <div className="container" >
                    <section className="definition" id="definition">
                        <div className="container">
                            <div className="content">
                            <div className="title">
                                <p className="def-of-digit">
                                    {t('def_of')} {' '}
                                    <span style={{color: '#008E87'}} >{t('digit')}</span>
                                </p>
                            </div>
                            <div className="excerpt">
                                {t("to_us_text")}
                            </div>
                            </div>
                            <div className="picture d-flex">
                            <div className="img" style={{backgroundImage: "url(/assets/img/digit-img.png)"}}></div>
                            </div>
                        </div>
                    </section>
                </div> */}
        {/* <!-- SECTION END --> */}

        <Footer />
      </main>
    </Page>
  );
}
