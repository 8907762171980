import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../stores/types';
import { Badge, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import theme from '../../../../components/theme/theme';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

interface Props {
    isCart?: boolean
}

function CartDrawer (props: Props) {

    const { cart } = useSelector((state: ApplicationState) => state.shop);
    const [isOpen, setIsOpen] = React.useState(false);

    const {isCart} = props;

    const { store } = useParams();
    const navigate = useNavigate();

    const handleToggleCartDrawer = () => {
        setIsOpen(!isOpen);
    }

    const handleGoToCart = () => {
        navigate(`/ova-store/store/${store}/cart`);
    }

    return (
        <div>
            <React.Fragment>
                {/* <ListItem>
                    <ListItemButton onClick={handleToggleCartDrawer} sx={{ height: {sm: "60px"}, borderRadius: '10px', backgroundColor: isCart ? theme.palette.secondary.main : ""}}>
                        <ListItemIcon>
                            <Badge badgeContent={cart.length} color="primary">
                                <ShoppingCartOutlinedIcon sx={{ color: isCart ? theme.palette.primary.main : "" }} color="action" />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText 
                            sx={{ color: isCart ? "#fff": "" }} 
                            primaryTypographyProps={{
                                color: isCart ? "primary": "secondary",
                                fontWeight: 'medium',
                                // variant: 'body2',
                            }}
                            primary={"Cart"} />
                    </ListItemButton>
                </ListItem> */}
                <Button onClick={handleToggleCartDrawer} variant="outlined" sx={{margin: "auto"}} startIcon={<Badge badgeContent={cart.length}  color="primary"><ShoppingCartIcon color="primary" /></Badge>}>
                    CART
                </Button>
                <Drawer
                    anchor={"right"}
                    open={isOpen}
                    onClose={handleToggleCartDrawer}
                >
                    <Typography 
                        variant="h5"
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        My cart
                    </Typography>
                    <div
                        style={{ width: 250 }}
                        role="presentation"
                        // onClick={toggleCartDrawer}
                        // onKeyDown={toggleCartDrawer}
                    >
                        {
                            cart.length === 0 && <Typography className={"text-center"} >Your cart is empty</Typography>
                        }
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            {
                                cart.map((item) => { 

                                    return(
                                        <ListItem key={item.id}>
                                            <ListItemAvatar>
                                            <Avatar src={item.main_image}>
                                                {/* <ImageIcon /> */}
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    className: "cart-article-title"
                                                }}
                                                secondaryTypographyProps={{
                                                    className: "cart-article-description"
                                                }}
                                                primary={item.title} 
                                                secondary={item.description} />
                                        </ListItem>
                                    )
                                })
                            }
                            <ListItem>
                                <ListItemButton
                                    sx={{ 
                                        minWidth: '30px', height: {sm: "60px"}, borderRadius: '10px', backgroundColor: theme.palette.secondary.main, textAlign: 'center',
                                        ':hover': {
                                            backgroundColor: theme.palette.primary.main
                                        }
                                    }}
                                    onClick={handleGoToCart}
                                >
                                    <ListItemText 
                                        primaryTypographyProps={{
                                            color: '#ffffff'
                                        }}
                                        primary={"VIEW MORE"} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </div>
                </Drawer>
            </React.Fragment>
        </div>
    );
}

export default CartDrawer;