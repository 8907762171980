import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { redirect } from '../../../components/utils/utils';
import { successStories } from '../../../components/mocked/home/successStory';
import SuccessStoryCard from './components/SuccessStoryCard';



interface Props {
  ref: any;
}


export default function Announces(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { ref } = props;

  return (
    <section className="news pt-2 pb-2 references" id="news">
      <div className="container">
        <div>
          <div>
            <div className="container my-4">
              {/* <!--Carousel Wrapper--> */}
              <div className="news-carousel-title">
                {/* <p className="news-title">
                                {t("what_is")} <span style={{color: '#009289'}}  >{t("what_is_new")}</span>
                            </p> */}
                <p className="news-title text-white">Success stories</p>
              </div>
              <div>
                {/* <!--Controls--> */}
                {/* <div className="controls-top">
                                <a className="btn-floating" href="#multi-item-example" data-slide="prev" style={{paddingTop:'6px'}}>
                                    <img width={20} src="/assets/img/right-arrow.png" alt="arrow" />
                                </a>
                                <a className="btn-floating" href="#multi-item-example" data-slide="next">
                                    <img width={20} src="/assets/img/right-arrow.png" alt="arrow" />
                                </a>
                            </div> */}
                {/* <!--/.Controls--> */}

                {/* <!--Slides--> */}

                <div className="row">
                  {successStories.map((success, index) => {
                    return (
                      <SuccessStoryCard success={success}  key={"success_" + index}/>
                      // <div key={"success" + index} className="col-md-4 col-sm-6 col-12 mt-3" style={{float: 'left'}}>
                      //     <div onClick={() => redirect(success.link, navigate, true)} className="card-link h-100">
                      //         <div className="card mb-2 border-none s-story-card h-100" style={{borderRadius: 0}}>
                      //             <div className="success-story-image" style={{backgroundImage: `url(${success.image})`}}></div>
                      //             {/* <img className="card-img-top br-0"
                      //                 height={"200px"}
                      //                 src={success.image} alt="Card image cap"/>
                      //             <div className="card-body">
                      //                 {/* <p className="new-category">Category</p>
                      //                 {/* <h4 className="card-title">Emrald Securities Services</h4>
                      //                 <h4 className="text-left">{t(success.title)}</h4>
                      //                 <p className="text-left">{t(success.description)}</p>
                      //             </div>
                      //         </div>
                      //     </div>
                      // </div>
                    );
                  })}
                </div>

                {/* <!--/.First slide--> */}

                {/* <!--Second slide--> */}
                {/* <div className="carousel-item">

                            <div className="col-md-4 col-sm-6 col-12" style={{float: 'left'}}>
                                <div className="card mb-2">
                                <img className="card-img-top"
                                    src="https://mdbootstrap.com/img/Photos/Horizontal/City/4-col/img%20(60).jpg" alt="Card image cap"/>
                                <div className="card-body">
                                    <p className="new-category">Category</p>
                                    <h4 className="card-title">Card title</h4>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the
                                    card's content.</p>
                                    
                                </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-12" style={{float: 'left'}}>
                                <div className="card mb-2">
                                <img className="card-img-top"
                                    src="https://mdbootstrap.com/img/Photos/Horizontal/City/4-col/img%20(47).jpg" alt="Card image cap"/>
                                <div className="card-body">
                                    <p className="new-category">Category</p>
                                    <h4 className="card-title">Card title</h4>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the
                                    card's content.</p>
                                    
                                </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-12" style={{float: 'left'}}>
                                <div className="card mb-2">
                                <img className="card-img-top"
                                    src="https://mdbootstrap.com/img/Photos/Horizontal/City/4-col/img%20(48).jpg" alt="Card image cap"/>
                                <div className="card-body">
                                    <p className="new-category">Category</p>
                                    <h4 className="card-title">Card title</h4>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the
                                    card's content.</p>
                                    
                                </div>
                                </div>
                            </div>
                            </div> */}
                {/* <!--/.Second slide--> */}
              </div>
              {/* <!--/.Slides--> */}
            </div>
            {/* <!--/.Carousel Wrapper--> */}
          </div>
        </div>
      </div>
    </section>
  );
}
