import React, { useState } from 'react';
import { Link, Outlet } from "react-router-dom";
import Header from "../../components/header/Header";
import Page from "../../page/Page";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


// import required modules
import { Mousewheel, Pagination } from "swiper";

interface SwipeProps {
    activeIndex: number;
    isEnd: boolean
}

function Ovastore (props: any) {
    
    const [currentSwipe, setCurrentSwipe] = useState(0);
    const [swipeIsEnd, setSwipeIsEnd] = useState(false);

    const handleSwipeChange = (swipe: SwipeProps) => {

        setCurrentSwipe(swipe.activeIndex);
        setSwipeIsEnd(swipe.isEnd);

    }


    return(
        <Page title="OVA consulting | store"
            description="OVA store"
        > 
            <main className="ova-store">
                <Header />
                <section className="banner">
                    {/* <div className="container">
                        <div className="menu"></div>
                    </div> */}
                    <Swiper
                        direction={"vertical"}
                        slidesPerView={1}
                        spaceBetween={30}
                        mousewheel={true}
                        pagination={{
                        clickable: true,
                        }}
                        modules={[Mousewheel, Pagination]}
                        className="mySwiper"
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={(swiper) => handleSwipeChange(swiper)}
                    >
                        <SwiperSlide>
                            <div className="container h-100">
                                <div className=" h-100"> 
                                    <div className="row h-100">
                                        <div className="col-lg-6 col-12 d-flex">
                                            <div className="banner-text">
                                                <h1>Fully Managed Cloud &nbsp; Web Hosting</h1>
                                                <p>Deidcated resources, full root access, & easy scaling. It’s the virtual private server you’ve been cravin</p>
                                                <Link to="/ova-store/hosting" className="btn btn-primary">Read more</Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 d-flex">
                                            <div className="banner-image">
                                                <img src="/assets/img/hosting-image.png" alt="Ova consulting hosting" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="container h-100">
                                <div className=" h-100"> 
                                    <div className="row h-100">
                                        <div className="col-lg-6 col-12 d-flex">
                                            <div className="banner-text">
                                                <h1>Equipments & Licenses</h1>
                                                <p>Dedicated resources, full root access, & easy scaling. It’s the virtual private server you’ve been cravin</p>
                                                <Link to="/ova-store/store/equipments" className="btn btn-primary">Read more</Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 d-flex">
                                            <div className="banner-image">
                                                <img src="/assets/img/store-bg.png" alt="Ova consulting store" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="scroll">
                        <svg className={`arrows ${swipeIsEnd ? "end" : ""}`}>
                            <path className="a1" d="M0 0 L30 32 L60 0"></path>
                            <path className="a2" d="M0 20 L30 52 L60 20"></path>
                            <path className="a3" d="M0 40 L30 72 L60 40"></path>
                        </svg>
                    </div>
                </section>
            </main>
            {/* <Outlet/> */}
        </Page>
    )
}

export default Ovastore;