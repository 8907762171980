import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OwlCarousel from 'react-owl-carousel';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import { Expertise, expertises } from '../../const/expertises';
import Page from "../../page/Page";
import { getBgImage, getClients } from '../../utils/functions';
import Timeline from './components/timeline/Timeline';


function ExpertiseDetails () {


    const {expertise} = useParams();
    const [selectedExpertise, setSelectedExpertise] = useState<Expertise | undefined>(undefined)
    const [display, setDisplay] = useState(false);
    const { t } = useTranslation();

    const navigate = useNavigate();

    if(expertise === "infrastructure") {
        navigate("/expertise/infrastructure");
        
    }
    else if(expertise === "data-science") {
        navigate("/expertise/data-science");
    }
    else if(expertise === "software-engineering") {
        navigate("/expertise/software-engineering");
    } 

    useEffect(() => {
        const selected = _.filter(expertises, {'slug': expertise})
        if(selected && selected.length > 0){
            setSelectedExpertise(selected[0]);
        }

        setDisplay(true);
        
    }, [])

    return(
        <Page
            title={"OVA - Consulting | Expertise Details"}
        >
            {
                display && <>
                <Header/>
            <section className="expertise-details">
                <section className="banner"  style={{backgroundImage: getBgImage(expertise)}}>
                    <div className="banner-1">
                        <div className="container">
                            <div className='row'>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div>
                                        <Link to="/expertises" className="expertses-return h5">
                                            <span className='d-flex'>
                                                <img src="/assets/img/stat-speed-up.png" alt="..." className='m-auto' />
                                            </span>
                                            <span>Expertises</span>
                                        </Link>
                                        <p className="text h3">{t(`${expertise?.replace("-", " ")}`)}</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div>
                                        <div className='banner-description'>
                                            <p>
                                                {t(selectedExpertise?.description ?? "")}
                                            </p>
                                            <Link to='/contact' className="button button--pan" role={'button'}><span>{t("contact_contact_us")}</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-2">
                        <OwlCarousel className='owl-theme our-clients-carousel' 
                            loop 
                            margin={10} 
                            autoplayHoverPause
                            dots={false} 
                            autoplay 
                            smartSpeed={2000}
                            autoplayTimeout={3000}
                            responsive= {{
                                0:{
                                items:2,
                                loop:true
                                },
                                600:{
                                items:4,
                                loop:true
                                },
                                1000:{
                                items:5,
                                loop:true
                                }
                            }} 
                        >
                            {
                                getClients(expertise).map((item, index) => {

                                    return(
                                        <div className='item' key={index} >
                                            <div className="reference" style={{backgroundImage: `url(${item.logo})`, height: '100px'}}></div>
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                </section>
                <section className="main-techno"> 
                    <div className="container">
                        <div>
                            <h2>
                                {t("Our main technologies stacks")}
                            </h2>
                        </div>
                        <div className='stacks'>
                            <div className="row">
                                {
                                    selectedExpertise?.expertises.map((expert, index) => {

                                        return expert.contents.map(content => {

                                            if(index%2 === 0) {

                                                return(
                                                    <div key={"design" + content.id} className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                        <div className='stack-items'>
                                                            <div className="d-flex">
                                                                <div className="item">
                                                                    <img height={100} src={content.image} alt="Python programing language" />
                                                                </div>
                                                                <div className="item">
                                                                    <div className="m-auto">
                                                                        <p className="language-name">
                                                                            {content.name}
                                                                        </p>
                                                                        <p className="language-text">
                                                                            <span>
                                                                                {t(content.type)}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } else {

                                                return (
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                        <div className='stack-items'>
                                                            <div className="d-flex row-reverse">
                                                                <div className="item">
                                                                    <img height={100} src={content.image} alt="typescript programing language" />
                                                                </div>
                                                                <div className="item">
                                                                    <div className="m-auto">
                                                                        <p className="language-name">
                                                                            {content.name}
                                                                        </p>
                                                                        <p className="language-text">
                                                                            <span>
                                                                                {t(content.type)}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    })
                                }
                                {/* <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                    <div className='stack-items'>
                                        <div className="d-flex">
                                            <div className="item">
                                                <img src="/assets/stacks/typescript.png" alt="typescript programing language" />
                                            </div>
                                            <div className="item">
                                                <div className="m-auto">
                                                    <p className="language-name">
                                                        Typescript
                                                    </p>
                                                    <p className="language-text">
                                                        <span>
                                                            Language
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                    <div className='stack-items'>
                                        <div className="d-flex row-reverse">
                                            <div className="item">
                                                <img src="/assets/stacks/typescript.png" alt="typescript programing language" />
                                            </div>
                                            <div className="item">
                                                <div className="m-auto">
                                                    <p className="language-name">
                                                        Typescript
                                                    </p>
                                                    <p className="language-text">
                                                        <span>
                                                            Language
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                    <div className='stack-items'>
                                        <div className="d-flex row-reverse">
                                            <div className="item">
                                                <img src="/assets/stacks/typescript.png" alt="typescript programing language" />
                                            </div>
                                            <div className="item">
                                                <div className="m-auto">
                                                    <p className="language-name">
                                                        Typescript
                                                    </p>
                                                    <p className="language-text">
                                                        <span>
                                                            Language
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                    <div className='stack-items'>
                                        <div className="d-flex row-reverse">
                                            <div className="item">
                                                <img src="/assets/stacks/typescript.png" alt="typescript programing language" />
                                            </div>
                                            <div className="item">
                                                <div className="m-auto">
                                                    <p className="language-name">
                                                        Typescript
                                                    </p>
                                                    <p className="language-text">
                                                        <span>
                                                            Language
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="expertise-projects">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                <div>
                                    <p className="title">
                                        {t("Projetcts we have")}
                                        <br />
                                        <span>
                                            carried out
                                        </span>
                                    </p>
                                    {/* <Link to="/references" className="view-all">
                                        {t("view all")}
                                        <span>
                                            <img src="/assets/img/stat-speed-up.png" alt="" />
                                        </span>
                                    </Link> *}
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-6 col-12">
                                <div> 
                                    <div className="row">
                                        {
                                            selectedExpertise?.projects.map((project, index) => {

                                                return(
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={`project-${index}`}>
                                                        <div>
                                                            <div className="card">
                                                                <img className="card-img-top h-200" src={project.image} alt={project.name}/> 
                                                                <div className="card-body">
                                                                    <h2 className="card-title">{project.name}</h2>
                                                                    <p className="card-text" dangerouslySetInnerHTML={{__html: t(project.description)}} />
                                                                </div>
                                                                <div className="card-desc">
                                                                    <div className="d-flex h-100">
                                                                        <Link to={project.link} target={'_blank'} className="view-all m-auto">
                                                                            {t("Read more")}
                                                                            {/* <span>
                                                                                <img width={''} src="/assets/img/stat-speed-up.png" alt="" />
                                                                            </span> *}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {selectedExpertise?.methodologies && <section className="expertise-section pt-0 pb-0">
                    <div className="container">
                        <div>
                            {/* <p className="title-1 ">{t("")}</p> */}
                            <div className='title-1 '>
                                <h1 className="text-primary text-left">
                                    {t("our_methodology")}
                                </h1>
                            </div>
                        </div>
                        <div>
                            <Timeline timeLines={selectedExpertise?.methodologies} />
                        </div>
                    </div>
                </section>}
            </section>
            <Footer/>
                </>
            }
        </Page>
    )
}



export default ExpertiseDetails