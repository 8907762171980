import { useTranslation } from 'react-i18next';
import { SuccessStories } from '../../../../components/types/successStory';
import { redirect } from '../../../../components/utils/utils';
import { useNavigate } from 'react-router-dom';

interface Props {
  success: SuccessStories;
}

const SuccessStoryCard = (props: Props) => {
  const { success } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="col-md-4 col-sm-4 col-12">
      <div
        className="ref-card "
        onClick={() => redirect(success.link, navigate, true)}
        style={{
          backgroundImage: `url(${success.illustration})`
        }}>
        <div
          className="ref-logo p-2"
          // style={{ backgroundColor: 'var(--bs-blue)',  }}
        >
          <span
            style={{
              backgroundImage: `url(${success.cleint_logo})`,
              backgroundSize: String(success.cleint_logo).includes('cadyst')
                ? '46%'
                : 'contain'
            }}></span>
          {/* <img
                                src={`${success.cleint_logo}`}
                                width={'70px'}
                                alt=""
                              /> */}
        </div>
        <div className="text-content pb-5">
          <p className="title">{t(success.company)}</p>
          <p className="text">Projet: {t(success.title)}</p>
          <p className="text-white text">
            Description: {t(success.description)}
          </p>
        </div>
        <div className="ref-ova-logo">{/* <span>OVA</span> */}</div>
        <div className="ref-country ref-country-bottom">
          <span className="m-auto">{success.country}</span>
        </div>
      </div>
    </div>
  );
};

export default SuccessStoryCard;
