import { Action } from "redux";
import { StoreArticle } from "../../components/components/types/storeCard";



export const LOADING_ARTICLES_REQUEST = 'LOADING_ARTICLES_REQUEST';
export const LOADING_ARTICLES_SUCCESS = 'LOADING_ARTICLES_SUCCESS';
export const LOADING_ARTICLES_FAILED = 'LOADING_ARTICLES_FAILED';

export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILED = "ADD_TO_CART_FAILED";

export const REMOVE_FROM_CART_REQUEST = "REMOVE_FROM_CART_REQUEST";
export const REMOVE_FROM_CART_SUCCESS = "REMOVE_FROM_CART_SUCCESS";
export const REMOVE_FROM_CART_FAILED = "REMOVE_FROM_CART_FAILED";

export const LOAD_ARTICLE_REQUEST = "LOAD_ARTICLE_REQUEST";
export const LOAD_ARTICLE_SUCCESS = "LOAD_ARTICLE_SUCCESS";
export const LOAD_ARTICLE_FAILED = "LOAD_ARTICLE_FAILED";

export const ADD_TO_WISHLIST_REQUEST = "ADD_TO_WISHLIST_REQUEST";
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS";
export const ADD_TO_WISHLIST_FAILED = "ADD_TO_WISHLIST_FAILED";

export const REMOVE_FROM_WISHLIST_REQUEST = "REMOVE_FROM_WISHLIST_REQUEST";
export const REMOVE_FROM_WISHLIST_SUCCESS = "REMOVE_FROM_WISHLIST_SUCCESS";
export const REMOVE_FROM_WISHLIST_FAILED = "REMOVE_FROM_WISHLIST_FAILED";


export interface LoadingArticlessState {
    loading_articles_request: boolean;
    loading_articles_success: boolean;
    loading_articles_failed: boolean;
    loading_article_request: boolean;
    loading_article_success: boolean;
    loading_article_failed: boolean;
    add_to_cart_request: boolean;
    add_to_cart_success: boolean;
    add_to_cart_failed: boolean;
    remove_from_cart_request: boolean;
    remove_from_cart_success: boolean;
    remove_from_cart_failed: boolean;
}


export interface LoadArticlesRequest extends Action {
    type: typeof LOADING_ARTICLES_REQUEST
}

export interface LoadArticlesSuccess extends Action {
    type: typeof LOADING_ARTICLES_SUCCESS,
    articles: ArticleResuts
}

export interface LoadArticlesFailed extends Action {
    type: typeof LOADING_ARTICLES_FAILED
}

// CART ACTIONS

export interface AddToCartRequest extends Action {
    type: typeof ADD_TO_CART_REQUEST
}

export interface AddToCartSuccess extends Action {
    type: typeof ADD_TO_CART_SUCCESS
    article: StoreArticle
}

export interface AddToCartFailed extends Action {
    type: typeof ADD_TO_CART_FAILED
}

export interface RemoveFromCartRequest extends Action {
    type: typeof REMOVE_FROM_CART_REQUEST
}

export interface RemoveFromCartSuccess extends Action {
    type: typeof REMOVE_FROM_CART_SUCCESS
    article: StoreArticle
}

export interface RemoveFromCartFailed extends Action {
    type: typeof REMOVE_FROM_CART_FAILED
}

export interface LoadArticleRequest extends Action {
    type: typeof LOAD_ARTICLE_REQUEST
}

export interface LoadArticleSuccess extends Action {
    type: typeof LOAD_ARTICLE_SUCCESS
    article: StoreArticle
}

export interface LoadArticleFailed extends Action {
    type: typeof LOAD_ARTICLE_FAILED
}

export interface ArticlesState {
    loading: LoadingArticlessState,
    articles: ArticleResuts,
    cart: StoreArticle[],
    whishlist: StoreArticle[],
    article?: StoreArticle
}

export interface ArticleResuts {
    count: number;
    results: StoreArticle[];
}


// CART ACTIONS
export interface AddToWishlistRequest extends Action {
    type: typeof ADD_TO_WISHLIST_REQUEST
}

export interface AddToWishlistSuccess extends Action {
    type: typeof ADD_TO_WISHLIST_SUCCESS
    article: StoreArticle
}

export interface AddToWishlistFailed extends Action {
    type: typeof ADD_TO_WISHLIST_FAILED
}

export interface RemoveFromWishlistRequest extends Action {
    type: typeof REMOVE_FROM_WISHLIST_REQUEST
}

export interface RemoveFromWishlistSuccess extends Action {
    type: typeof REMOVE_FROM_WISHLIST_SUCCESS
    article: StoreArticle
}

export interface RemoveFromWishlistFailed extends Action {
    type: typeof REMOVE_FROM_WISHLIST_FAILED
}