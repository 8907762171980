import { Button, Card, CardMedia, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel, {
    FormControlLabelProps,
  } from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DefaultButton } from './DefaultButton'
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';


interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  '.MuiFormControlLabel-label': checked && {
    color: theme.palette.primary.main,
  },
}));

function MyFormControlLabel(props: FormControlLabelProps) {
    const radioGroup = useRadioGroup();
  
    let checked = false;
  
    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }
  
    return <StyledFormControlLabel checked={checked} {...props} />;
  }


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }

interface PaymentCardTypes {
    cardNumber: string
    nameOnCard: string
    expirationDate: Date
    cardCVC: string
}


function PaymentMethods () {

    const [shippingName, setShippingName] = useState<string>("");
    const [streetName, setStreetName] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [stateProvince, setStateProvince] = useState<string>("");
    const [country, setCountry] = useState<string>("");
    const [saveShippingDetails, setSaveShippingDetails] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("creditCard");
    
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    
    const handleChangeShippingName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShippingName(event.target.value);
    };

    const handleChangeStreetName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStreetName(event.target.value);
    };

    const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);
    };

    const handleChangeStateProvince = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStateProvince(event.target.value);
    };

    const handleChangeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCountry(event.target.value);
    };

    const handleChangeSaveShippingDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSaveShippingDetails(event.target.checked);
    };

    const handleChangePaymentMethod = (event: React.ChangeEvent<HTMLInputElement>) => {

        setPaymentMethod(event.target.value);
    }

    return(
        <Card sx={{padding: '1rem'}}>
            <Typography variant="h5" className="text-left" >Payment methods</Typography>
            <Grid container>
                <Grid item md={12} xs={12}>
                    <Grid>
                        <Box sx={{textAlign: "left"}}>
                            <RadioGroup onChange={handleChangePaymentMethod} row name="use-radio-group" defaultValue="creditCard">
                                <MyFormControlLabel value="creditCard" label={
                                    <img 
                                        src="/assets/img/payments/credit-card.png"
                                        alt=""
                                        height={100}
                                    />
                                } control={<Radio />} />
                                <MyFormControlLabel value="paypal"  label={
                                    <img 
                                        src="/assets/img/payments/paypal.png"
                                        alt=""
                                        height={70}
                                    />
                                } control={<Radio />} />
                                <MyFormControlLabel value="bitcoin"  label={
                                    <img 
                                        src="/assets/img/payments/bitcoin.png"
                                        alt=""
                                        height={70}
                                    />
                                } control={<Radio />} />
                            </RadioGroup>
                        </Box>
                       
                    </Grid>
                </Grid>
                {/* <Grid item md={4} xs={12}>
                    <Grid>
                        <Button variant="outlined" onClick={handleClickOpen}>Change</Button>
                        <BootstrapDialog
                            onClose={handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                            maxWidth={"sm"}
                            fullWidth={true}
                        >
                            <DialogTitle sx={{ m: 0, p: 2 }}>
                                <Typography> Select a Card </Typography>
                                    <IconButton
                                    aria-label="close"
                                    onClick={handleClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                    >
                                    <CloseIcon />
                                    </IconButton>
                            </DialogTitle>
                            <DialogContent dividers>
                                
                            </DialogContent>
                            <DialogActions>
                            <DefaultButton onClick={handleClose}>
                                Cancel
                            </DefaultButton>
                            <Button autoFocus variant="contained" color="primary" onClick={handleClose}>
                                Save changes
                            </Button>
                            </DialogActions>
                        </BootstrapDialog>
                    </Grid>
                </Grid> */}
                <Grid item md={8}>
                {
                            paymentMethod === "creditCard" && <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { width: '100%', marginBottom: "1rem" },
                            }}
                            noValidate
                            autoComplete="off"
                            >
                            <TextField 
                                id="outlined-card-name" 
                                value={shippingName} 
                                onChange={handleChangeShippingName} 
                                label="Enter Card Name"
                                placeholder="Card Name"
                                variant="standard" />
                            <TextField 
                                id="outlined-card-number" 
                                label="Card number" 
                                // value={streetName} 
                                // onChange={handleChangeStreetName} 
                                placeholder="*** *** *** ***" 
                                InputProps={{
                                    inputProps: {
                                        type: "number"
                                    }
                                }}
                                variant="standard" />
                            <Box
                                sx={{
                                    '& > :not(style)': { width: {sm: "48%", xs: "100%"} },
                                    display: "flex",
                                    justifyContent: 'space-between'
                                }}
                            >
                                <TextField 
                                    id="outlined-expiration" 
                                    label="Expiration" 
                                    placeholder="" 
                                    value={city} 
                                    type={"date"}
                                    onChange={handleChangeCity} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <CalendarTodayIcon />
                                            </InputAdornment>
                                        )
                                    }}      
                                    variant="standard" />
                                <TextField 
                                    id="outlined-state" 
                                    label="CVV Code" 
                                    placeholder="California"
                                    value={stateProvince} 
                                    onChange={handleChangeStateProvince} 
                                    variant="standard" />
                            </Box>
                        </Box>
                    }
                    <Typography variant="body2" className="text-left"> By clicking *Confirm Payment* I agree to company terms of services </Typography>
                    <Box className="d-flex mt-2">
                        <Button 
                        variant="contained" 
                        sx={{
                            color: "#fff",
                            marginLeft: "auto"
                        }}
                        color="secondary">Confirm Payment</Button>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    )
}

export default PaymentMethods;