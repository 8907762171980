import { successStories } from "../components/mocked/home/successStory";

export interface Client {
    name: string;
    logo: string;
    category: string[]
};

export const clients:Client[] = [
    {
        name: "Korione",
        logo: "/assets/clients/korione.png",
        category: [
            'software',
            'design',
            'software-engineering'
        ]
    },
    {
        name: "LBS",
        logo: "/assets/clients/lbs.png",
        category: [
            'software',
            'design',
            'software-engineering'
        ]
    },
    {
        name: "Ellios",
        logo: "/assets/clients/ellios.png",
        category: [
            'software',
            'design',
            'software-engineering'
        ]
    },
    {
        name: "Emrald Securities Services",
        logo: "/assets/clients/ess-logo.svg",
        category: [
            'software',
            'design',
            'software-engineering'
        ]
    },
    {
        name: "Bois deco",
        logo: "/assets/clients/bois-deco.svg",
        category: [
            'software',
            'design',
            'software-engineering'
        ]
    },
    {
        name: "Nourishka",
        logo: "/assets/clients/nourishka.png",
        category: [
            'software',
            'design',
            'software-engineering'
        ]
    },
    {
        name: "MbengOPays",
        logo: "/assets/clients/logo-mbeng-au-pays.png",
        category: [
            'software',
            'design',
            'software-engineering'
        ]
    },
    {
        name: "AUSY",
        logo: "/assets/clients/ausy.png",
        category: [
            'software',
            'design',
            'software-engineering'
        ]
    },
    {
        name: "Snailfarm",
        logo: "/assets/clients/snail-farm.jpg",
        category: [
            'software',
            'design',
            'software-engineering'
        ]
    },
    ...successStories.map((item) => {
        return {
            name: item.company,
            logo: item.cleint_logo ?? "",
            category: [
                'software',
                'design',
                'software-engineering'
            ]
        }
    })

]