import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Header3 from '../../components/header/Header3';
import Page from '../../page/Page';
import { successStories } from '../../components/mocked/home/successStory';
import SuccessStoryCard from '../home/components/components/SuccessStoryCard';

function Expertise() {
  const { t } = useTranslation();

  return (
    <Page title={'OVA - Consulting | Expertises'}>
      <Header3 />
      <section className="expertise-section">
        <div className="container">
          <div>
            <div className="row">
              <div>
                <div className="expertises-details">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t('creative_company')
                    }}></div>
                </div>
              </div>
            </div>
            <div className="expertise-container">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="x-card">
                    <div className="face face1">
                      <div className="content text-center">
                        <img alt='' src="/assets/expertises/design.png" />
                        <h3>Design</h3>
                      </div>
                    </div>
                    <div className="face face2">
                      <div className="content">
                        <p>{t('expertise_design_text')}</p>
                        <p className=" text-center">
                          <Link to="/expertises/design">{t('Read More')}</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="x-card">
                    <div className="face face1">
                      <div className="content text-center">
                        <img alt='' src="/assets/expertises/software-engineer.png" />
                        <h3>{t('expertise_software_engineering')}</h3>
                      </div>
                    </div>
                    <div className="face face2">
                      <div className="content">
                        <p>{t('expertise_software_engineering_text')}</p>
                        <p className=" text-center">
                          <Link to="/expertise/software-engineering">
                            {t('Read More')}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="x-card">
                    <div className="face face1">
                      <div className="content text-center">
                        <img src="/assets/img/cyber-security.png" alt='' />
                        <h3>{t('expertise_infrastructure')}</h3>
                      </div>
                    </div>
                    <div className="face face2">
                      <div className="content">
                        <p>{t('expertise_infrastructure_text')}</p>
                        <p className=" text-center">
                          <Link to={`/expertise/infrastructure`}>
                            {t('Read More')}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="x-card">
                    <div className="face face1">
                      <div className="content text-center">
                        <img src="/assets/img/teaching.png" alt=''/>
                        <h3>{t('expertise_training')}</h3>
                      </div>
                    </div>
                    <div className="face face2">
                      <div className="content">
                        <p>{t('training_text')}</p>
                        <p className=" text-center">
                          <Link to="/trainings">{t('Read More')}</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="x-card">
                    <div className="face face1">
                      <div className="content text-center">
                        <img src="/assets/img/dashboard.png"alt=''/>
                        <h3>{t('expertise_data_science')}</h3>
                      </div>
                    </div>
                    <div className="face face2">
                      <div className="content text-center">
                        <p>{t('expertise_data_science_text')}</p>
                        <p className=" text-center">
                          <Link to="/expertises/data-science">
                            {t('Read More')}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        style={{ marginBottom: '2rem' }}
        className="expertise-section references pt-0 pb-0">
        <div className="container">
          <div>
            <p className="title-1 h3">{t('header_our_reference')}</p>
          </div>
          <div className="row">
          {
              successStories?.slice(0, 3)?.map((success, index) => {
                return <SuccessStoryCard success={success} key={'sucess_exp_' + index} />
              })
            }
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="read-more-block">
                  <Link
                    to={'/references/'}
                    className="btn btn-primary rounded-primary">
                    {t('read_more')}
                  </Link>
                </div>
              </div>
            {/* <div className="col-lg-4 col-sm-6 col-12">
                            <div className="ref-card" style={{backgroundImage: "url('assets/img/lbs1.png')", minHeight: '300px'}} >
                                <div  style={{background:'rgba(0, 0, 0, 0.7) linear-gradient(0deg, rgb(21, 29, 65), transparent) repeat scroll 0% 0%'}} >
                                    <p className="ref-card-prod-name" >
                                        <Link to="https://lome-bs.com/" target={"_blank"}>Lome Business School</Link>
                                    </p>
                                    {/* <p className="ref-card-prod-category">
                                        Category
                                    </p> *}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                            <div className="ref-card" style={{backgroundImage: "url('/assets/img/solar-home.png')", minHeight: '300px'}} >
                                <div  style={{background:'rgba(0, 0, 0, 0.7) linear-gradient(0deg, rgb(21, 29, 65), transparent) repeat scroll 0% 0%'}} >
                                    <p className="ref-card-prod-name" >
                                        <Link to="http://www.solarhut.energy/" target={"_blank"}>Solar Hut</Link>
                                    </p>
                                    {/* <p className="ref-card-prod-category">
                                        Category
                                    </p> *}
                                </div>
                            </div>
                        </div> */}
            {/* <div className="col-lg-4 col-sm-6 col-12">
                            <div className="ref-card" style={{backgroundImage: "url('assets/img/modal-image.jpg')", minHeight: '300px'}} >
                                <div  style={{background:'rgba(0, 0, 0, 0.7) linear-gradient(0deg, rgb(21, 29, 65), transparent) repeat scroll 0% 0%'}} >
                                    <p className="ref-card-prod-name" >
                                        Product name
                                    </p>
                                    <p className="ref-card-prod-category">
                                        Category
                                    </p>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </section>
      <Footer />
    </Page>
  );
}

export default Expertise;
