import _ from "lodash";
import { Ref, useEffect, useState } from "react";



export interface ScrollerItem {
    dataScroller: string
    title: string
    ref: any,
    bagTheme: string
}

interface Props {
    items: ScrollerItem[]
}


export default function (props: Props) {

    
    const { items } = props;
    const [active, setActive] = useState<ScrollerItem>(items[0])
    const handleScroll = (ref: any, item: ScrollerItem) => {    
        if(ref){
            ref.current.scrollIntoView();
            setActive(item)
        }
    }

    useEffect(() => {
        var intersectionObserver = new IntersectionObserver(function(entries) {
            // Si intersectionRatio vaut 0 ou moins, la cible
            // est hors de vue et rien n'est alors fait
            if (entries[0].intersectionRatio <= 0) return;
          
                // loadItems(10);
                console.log('Nouveaux éléments chargés', entries[0].target.id);
                const activeSection = entries[0].target.id;
                if(activeSection){
                    const activeItem = _.filter(items, {dataScroller: activeSection})[0];
                    if(activeItem) {
                        setActive(activeItem);
                    }
                }
        });
        
        // début de l'observation
        items.forEach((itemToObserve) => {
            var element = document.getElementById(itemToObserve.dataScroller);
            if(element) {
                intersectionObserver.observe(element);
            }
        })
          
    }, [])


    return(
        <div className={`main-scroller ${active.bagTheme === "dark" && 'dark-bg'}`}>
            {
                items.map((item, index) => {

                    return(
                        <div key={"link" + index} onClick={() => handleScroll(item.ref, item)} className={`link ${active.dataScroller === item.dataScroller ? 'active' : ""}`} data-scroller={item.dataScroller}>{item.title}</div>
                    );
                })
            }
            <div className="scrolltop"></div>
        </div>
    )
}