import { Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/header/Header";
import { redirect } from "../../../components/utils/utils";
import Page from "../../../page/Page";




function Page404 () {


    const { t } = useTranslation();
    const navigate = useNavigate();

    return(
        <Page title={ "OVA - Consulting |  Error 404"} description={"Page not found"} >
            {/* <Header></Header> */}
            <section className="error-404">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div>
                                <p className="h3">Opss...</p>
                                <p className="h4">{t("not_found_text")}</p>
                                <button onClick={() => redirect("/", navigate)}  className="btn btn-primary">
                                    {t("not_found_home")}
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xs-0">
                            <div>
                                <img height={"100%"} src="/assets/img/cat-404.svg" alt="not found page" />
                            </div>
                        </div>
                    </div>
                    {/* <div className="box">
                        <p className="h1">{t("not_found_text")}</p>
                    </div> */}
                </div>
            </section>
        </Page>
    )
}

export default Page404