import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { trainings } from "../../../const/training"

interface Props {
    ref: any
}


export default function () {


    const { t } = useTranslation()

    return(
        <section id="trainings" className="trainings" >
            <div className="container">
                <div>
                    <p className="title">{t('training_title') + " " + "/ Certification"}</p>
                    <p className="description">{t('training_text')}</p>
                </div>
                <div className="row">
                    {
                        trainings.slice(0, 5).map((training, index) => {

                            return(

                                <div key={"training" + index} className="col-lg-4 col-sm-6 col-12">
                                    <Link to={"/trainings#" + training.slug} style={{textDecoration: 'none'}}>
                                        <div className="training-card">
                                            <div className="hover-hand">
                                                <img alt="pointer hand" className="" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E"/>
                                            </div>
                                            <p className="training-title">
                                                {t(training.title)}
                                            </p>
                                            <p className="training-duration">
                                                {t(training.duration) + " " + t("Days")}
                                            </p>
                                            <hr style={{width: "40%", marginLeft: 0}} />
                                            <p className="training-description">
                                                {t(training.description)}
                                            </p>
                                            <div className="training-bottom-branding">
                                                <div className="socials">
                                                    <div className="linkedin">
                                                        <Link to={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.protocol + "//" +window.location.host + "/trainings/" + training.slug}`}>
                                                            <i className="fa fa-linkedin" aria-hidden="true"></i>
                                                        </Link>
                                                    </div>
                                                    <div className="instagram">
                                                        <Link to={`https://www.instagram.com/?url=${window.location.protocol + "//" +window.location.host + "/trainings/" + training.slug}`}>
                                                            <i className="fa fa-instagram" aria-hidden="true"></i>
                                                        </Link>
                                                    </div>
                                                    <div className="twitter">
                                                        <Link to={`https://twitter.com/intent/tweet?url=${window.location.protocol + "//" +window.location.host + "/trainings/" + training.slug}`}>
                                                            <i className="fa fa-twitter" aria-hidden="true"></i>
                                                        </Link>
                                                    </div>
                                                    <div className="facebook">
                                                        <Link target={"_blank"} to={`https://www.facebook.com/sharer/sharer.php?u=${window.location.protocol + "//" +window.location.host + "/trainings/" + training.slug}`}>
                                                            <i className="fa fa-facebook" aria-hidden="true"></i>
                                                        </Link>
                                                    </div>
                                                    <div className="behance">
                                                        <Link to="#">
                                                            <i className="fa fa-behance" aria-hidden="true"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="d-flex h-100">
                        <Link to="/trainings" className="view-all m-auto">
                            {t("view all")}
                            <span>
                                <img src="/assets/img/stat-speed-up.png" alt="" />
                            </span>
                        </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12"></div>
                    <div className="col-lg-4 col-sm-6 col-12"></div>
                    <div className="col-lg-4 col-sm-6 col-12"></div>
                </div>
            </div>
        </section>
    )
}