import { useTranslation } from "react-i18next";
import Header3 from "../../components/header/Header3";
import { Formik, Field, Form } from "formik";
import { useState } from "react";
import { RECRUITMENTAPI } from "../../api/recruitment";
import { useParams } from "react-router-dom";
import Page from "../../page/Page";
import { toast } from "react-toastify";


interface Alert {
    type: string;
    message: string
}

export default function () {

    const [cv, setcv] = useState(null);
    const [coverLetter, setCoverLetter] = useState(null);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [alert, setAlert] = useState<Alert | null >(null);

    const { job_id } = useParams();

    const { t } = useTranslation();

    const handleChangeCoverLetter = (event: any) => {
        const file = event.target.files[0];
        setCoverLetter(file);
    }

    const handleChangeCV = (event: any) => {
        const file = event.target.files[0];
        setcv(file);
    }

    const initialValues = {
        email: "",
        fullName: "",
        middleName: "",
        lastName: "",
        phone: "",
        address: "",
        city: "",
        region: "",
    }

    const handleSubmit = (values: any) => {
        // //console.log("values ===> ", values);
        setIsSubmiting(true);
        const data = new FormData()
        data.append("first_name", values.fullName)
        data.append("last_name", values.lastName)
        data.append("middle_name", values.middleName)
        data.append("email", values.email)
        data.append("phone", values.phone)
        data.append("region", values.region)
        // @ts-ignore
        data.append("job", job_id)
        data.append("address", values.address)
        data.append("city", values.city)
        
        
        if(cv && coverLetter){

            data.append("cv", cv)
            data.append("cover_letter", coverLetter)
            RECRUITMENTAPI.post("applications/jobs/",data).then((resp) => {
                
                toast.success(t("job_apply_success") , {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                setIsSubmiting(false);
            }).catch(err => {
                toast.error( err.data.detail ?? t("job_apply_error"), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                setIsSubmiting(false);
            })
        } else {
            toast.error(t("input_files"), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            setIsSubmiting(false);
        }
    }

    return(
        <Page
            title={"OVA - Consulting | " + t('recruitement_text')}
            description={t("recruitement_our_profiles")}
        >
            <main id="" style={{paddingTop: "50px", background: "#f2f2f2"}} >
                <Header3/>
                <section className="main-content">
                    <section className="job-application">
                        <div className="container">
                            <div className="job-application-container">
                                <p className="title">{t('job_application_title')}</p>
                                <p className="text">{t("job_application_text")}</p>
                                <div className="text-left">
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={ (values) => {
                                            handleSubmit(values);
                                        }}
                                    >
                                        <Form>
                                            <div className="row">
                                                {alert && <div className={`alert alert-${alert?.type}`}>
                                                    {alert.message}
                                                </div>}
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                    <div>
                                                        <div className="mb-3">
                                                            <label htmlFor="fullName" className="form-label">{t('job_application_first_name')}</label>
                                                            <Field type="text" className="form-control" name="fullName" id="fullName" placeholder={t('job_application_first_name')}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                    <div>
                                                        <div className="mb-3">
                                                            <label htmlFor="middleName" className="form-label"> <p></p></label>
                                                            <Field type="text" className="form-control" name="middleName" id="middleName" placeholder={t('job_application_middle_name')}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                    <div>
                                                        <div className="mb-3">
                                                            <label htmlFor="lastName" className="form-label"><p></p></label>
                                                            <Field type="text" className="form-control" name="lastName" id="lastName" placeholder={t('job_application_last_name')}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                    <div>
                                                        <div className="mb-3">
                                                            <label htmlFor="phone" className="form-label"><p></p></label>
                                                            <Field type="tel" className="form-control" name="phone" id="phone" placeholder={t("contact_phone")}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                    <div>
                                                        <div className="mb-3">
                                                            <label htmlFor="email" className="form-label"><p className="mb-0">{t("contact_email")}</p></label>
                                                            <Field type="email" className="form-control" name="email" id="email" placeholder="name@example.com"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                    <div>
                                                        {/* <div className="mb-3">
                                                            <label htmlFor="password" className="form-label"><p></p></label>
                                                            <input type="password" className="form-control" id="password" placeholder="Ess@strong"/>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                    <div>
                                                        <div className="mb-3">
                                                            <label htmlFor="address" className="form-label">{t("contact_current_address")}</label>
                                                            <Field type="text" className="form-control" name="address" id="address" placeholder={t('job_application_address')}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                    <div>
                                                        <div className="mb-3">
                                                            <label htmlFor="city" className="form-label"><p></p></label>
                                                            <Field type="text" className="form-control" name="city" id="city" placeholder={t("contact_city")}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                    <div>
                                                        <div className="mb-3">
                                                            <label htmlFor="region" className="form-label"><p></p></label>
                                                            <Field type="text" className="form-control" name="region" id="region" placeholder={t("contact_region")}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-4 col-md-4 col-sm-6 col-12"></div> */}
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                    <div>
                                                        <div className="mb-3">
                                                            <label htmlFor="coverLetter" className="form-label">Documents</label>
                                                            <p className="label-text">{t("job_application_cover_letter")} (in PDF, DOC, DOCX. Format)  </p>
                                                            <input onChange={handleChangeCoverLetter} accept=".pdf, .doc, .docx" className="form-control" type="file" id="coverLetter"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                    <div>
                                                        <div className="mb-3">
                                                            <label htmlFor="cv" className="form-label"><p></p></label>
                                                            <p className="label-text">Curriculum Vitae CV (in PDF, DOC, DOCX. Format)  </p>
                                                            <input onChange={handleChangeCV} className="form-control" type="file" id="cv"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                    <div className="h-100">
                                                        <div className="mb-3 h-100 d-flex">
                                                            {isSubmiting ? <button type="submit" disabled={true}className="read-more-lines h-auto m-auto" >{t("SEND")}</button> : 
                                                            <button type="submit" className="read-more-lines h-auto m-auto btn-primary" >{t("SEND")}</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {alert && <div className={`alert alert-${alert?.type}`}>
                                                    {alert.message}
                                                </div>}
                                            </div>
                                        </Form>
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </main>
        </Page>
    )
}