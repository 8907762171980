import { useTranslation } from "react-i18next";
import Footer from "../../components/footer/Footer";
import Header3 from "../../components/header/Header3";
import Page from "../../page/Page";
import { successStories } from "../../components/mocked/home/successStory";
import SuccessStoryCard from "../home/components/components/SuccessStoryCard";
import BlockPhotos from "../home/components/BlockPhotos";

export interface Reference{
    logo: string,
    country: string,
    clientName: string,
    illustration: string,
    link: string,
    description: string,
}

const referencesList:Reference[] = [
    {
        logo: "assets/clients/korione.png",
        country: "Cameroon",
        clientName: "Korione",
        illustration: "/assets/img/e-learning.jpg",
        link: "https://korione.com",
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias tenetur molestias suscipit libero minus ipsa vel veniam, blanditiis quos sunt similique laudantium ab facilis vero eveniet voluptate nostrum nisi? Libero?",
    },
    {
        logo: "assets/clients/ess-logo.svg",
        country: "Cameroon",
        clientName: "Emrald securities services",
        illustration: "/assets/clients/ess-banner.png",
        link: "https://emraldsecuritiesservices.com/",
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias tenetur molestias suscipit libero minus ipsa vel veniam, blanditiis quos sunt similique laudantium ab facilis vero eveniet voluptate nostrum nisi? Libero?",
    },
    {
        logo: "assets/clients/ellios.png",
        country: "Cameroon",
        clientName: "Ellios security",
        illustration: "",
        link: "https://www.ellios-security.com/",
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias tenetur molestias suscipit libero minus ipsa vel veniam, blanditiis quos sunt similique laudantium ab facilis vero eveniet voluptate nostrum nisi? Libero?",
    },
    {
        logo: "assets/clients/lbs.png",
        country: "Cameroon",
        clientName: "Lome Business School",
        illustration: "",
        link: "https://lome-bs.com/",
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias tenetur molestias suscipit libero minus ipsa vel veniam, blanditiis quos sunt similique laudantium ab facilis vero eveniet voluptate nostrum nisi? Libero?",
    },
    {
        logo: "assets/clients/solar-hut.svg",
        country: "Cameroon",
        clientName: "Solar Hut",
        illustration: "",
        link: "http://www.solarhut.energy/",
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias tenetur molestias suscipit libero minus ipsa vel veniam, blanditiis quos sunt similique laudantium ab facilis vero eveniet voluptate nostrum nisi? Libero?",
    }
];


export default function OurRef () {

    const { t } = useTranslation();

    return (
        <Page
            title={"OVA - Consulting | " + t("header_our_reference")}
            description={t("header_our_reference")}
        >
            <div className="bg-grey main homepage" >
                <Header3 />
                <div style={{ height: "200px" }} ></div>
                <section style={{marginBottom: "2rem"}}>
                    <div className="container position-relative">
                        <div className="our-ref-title1 h3">
                            {t("header_our_reference")}
                        </div>
                    </div>
                </section>
                <section className="references" >
                    
                    <div className="container">
                        
                        <div className="row">
                            {
                                successStories.map((reference, index) => {

                                    return(
                                        <SuccessStoryCard success={reference} key={'reference_' + index} />
                                    )
                                })
                            }
                            {/* <div className="col-lg-4 col-sm-6 col-12">
                                <div className="ref-card" style={{backgroundImage: "url('assets/img/solar.png')", minHeight: '300px'}} >
                                    <div  style={{background:'rgba(0, 0, 0, 0.7) linear-gradient(0deg, rgb(21, 29, 65), transparent) repeat scroll 0% 0%'}} >
                                        <p className="ref-card-prod-name" >
                                            <a href="" target={"_blank"}>Solar Hut</a>
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
                {/* Section clients */}
                <BlockPhotos />
                <Footer />
            </div>
        </Page>
    )
}