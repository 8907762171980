


export const servicesAnimation = () => {

    // le but ici est d'observer les sections au scroll afin de voir celle qui actuelement visible.

    //  Scroller links

    const scrollerLinks = document.querySelectorAll(".main-scroller .link");

    // Creer l'observeur.

    let observer = new IntersectionObserver(observables => {
        
        for ( let observable of observables) {
            // //console.log("sections 1 ===>", observable)
          if( observable.intersectionRatio > 0.1 ) {
            for(let link of scrollerLinks) {
              if ( link.getAttribute('data-scroller') === observable.target.id ) {
                link.classList.add('active')
              }
            }
          } else {
              for(let link of scrollerLinks) {
                if ( link.getAttribute('data-scroller') === observable.target.id ) {
                  link.classList.remove('active')
                }
              }
          }
        }
    }, 
    {
      threshold: [0.1]
    }
    ) 

    // Recupere les sections de la page;

    const sections = document.querySelectorAll('section');

    // //console.log("sections ===>", sections)


    for( let section of sections) {
      // @ts-ignore
      observer.observe(section);
    }

    const scrolTop = document.querySelector('.scrolltop');

    scrolTop?.addEventListener('click', () => {
      window.scrollTo(0, 0);
    })


    document.addEventListener('scroll', function () {
      // @ts-ignore
      const currentScroller = document.querySelector(".main-scroller .active");
      const darkSections = ["digit"];
      const scroller = document.querySelector(".main-scroller");
      const dataScroller = currentScroller ? currentScroller!.getAttribute("data-scroller") : null;

      const scrollerLinks = document.querySelectorAll(".main-scroller .link")

      if( dataScroller && darkSections.includes(dataScroller)) {

        scroller!.className = "main-scroller dark-bg";

        // //console.log("add")

      } else if (dataScroller === 'contact') {

        scroller!.className = "main-scroller";

        // //console.log("remove")
      } else {
        
        scroller!.className = "main-scroller";
        // //console.log("remove")
      }

    //   //console.log("currentScroller", scrollerLinks);


    }, {
      passive: true
    });

    // caroussel cusstomisations

    // @ts-ignore
    let carrousselRight = window.$('carouselMethodsRightControls');
    let carrousselLeft = document.getElementById('carouselMethodsLeftControls');

    // @ts-ignore
    window.$('#carouselMethodsRightControls').carousel({
        interval: 2000
    });
    
    // Could be slid or slide (slide happens before animation, slid happens after)
    // @ts-ignore
    window.$('#carouselMethodsRightControls').bind('slid.bs.carousel', function() {
        // //console.log("Slide Event");
        // @ts-ignore
        window.$('#carouselMethodsRightControls').on('slide.bs.carousel', function(event: any) {
            // //console.log(event.to);
            // @ts-ignore
            window.$('#carouselMethodsLeftControls').carousel(event.to)
        // You can use use 'event.from' in whatever way works best for you here
        });
        // //console.log("window.$('#myCarousel .active').index('#myCarousel .item')", window.$('#carouselMethodsRightControls .active').index('#carouselMethodsRightControls .item'))
    });

}