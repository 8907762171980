import { Grid } from '@mui/material';
import { CardProps, StoreArticle } from '../types/storeCard';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../stores/types';
import _ from 'lodash';
import {
  addToCartEffect,
  removeFromCartEffect
} from '../../../stores/effects/storeArticles';
import { useSnackbar } from 'notistack';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useNavigate, useParams, Link } from 'react-router-dom';
import StoreBlock from '../../views/home/components/StoreBlock';
import { redirect } from '../utils/utils';

interface Props {
  product: StoreArticle;
}

export default function StoreCard(props: Props) {
  const { product } = props;
  const { cart } = useSelector((state: ApplicationState) => state.shop);
  const { store } = useParams();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleAddToCart = (article: StoreArticle) => {
    dispatch(addToCartEffect(article, enqueueSnackbar));
  };

  const handleRemoveFromCart = (article: StoreArticle) => {
    dispatch(removeFromCartEffect(article, enqueueSnackbar));
  };

  const handleSelectArticle = (article: StoreArticle) => {
    navigate(`/ova-store/store/${store}/${article.id}`);
  };

  return (
    <Grid item md={4} sm={6} xs={12} key={product.id}>
      <div className="product-card">
        <div className="img">
          <img height="194" src={product.main_image} alt="Paella dish" />
        </div>
        <div className="content">
          <p className="product-name">{product.title}</p>
          <p className="product-category">{product.category.title}</p>
          <p className="product-price">{product.price} XAF</p>
          <div className="actions">
            <button
              type="button"
              className="btn btn-primary add-to-pack"
              data-toggle="modal"
              data-target="#exampleModalCenter">
              Add to pack
            </button>
            <button className="know-more btn">En savoir plus</button>
          </div>
        </div>
        {/* <!-- Modal --> */}
        <div
          className="modal fade "
          id="exampleModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-dialog modal-lg"
            role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {/* Modal title */}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="p-4">
                  <div className="row">
                    <div className="col-md-12">
                      <div>
                        <p className="title">
                          <span>ET HOP !</span> C'EST DANS VOTRE PANIER
                        </p>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div>
                        <div className="cart-item">
                          <div className="image">
                            <img src={product.main_image} alt="" />
                          </div>
                          <div className="title">{product.title}</div>
                        </div>
                        <div className="cart-item">
                          <div className="image">
                            <img src={product.main_image} alt="" />
                          </div>
                          <div className="title">{product.title}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="right-side">
                        <Link to="cart" className="btn see-cart">
                          Voir mon panier <span>›</span>
                        </Link>
                        {/*
                        <Link to="/ova-store/store/equipments/cart" className="btn see-cart">
                          Voir mon panier <span>›</span>
                        </Link> */}
                        {/* <button
                          onClick={() =>
                            redirect(
                              '/ova-store/store/equipments/cart',
                              navigate,false
                            )
                          }
                          className="btn see-cart">
                          Voir mon panier <span>›</span>
                        </button> */}
                        {/* pour quoi ne pas juste fermer le modal ? */}
                        <Link
                          to="#"
                          type="button"
                          data-dismiss="modal"
                          aria-label="Close"
                          className="btn continue-shopping close">
                          <span>›</span>
                          Continuer mes achats
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: '#80808082', padding: '1rem' }}>
                  <p className="text-grey mb-0">
                    ET SI VOUS CRAQUIEZ AUSSI POUR ...?
                  </p>
                </div>
                <StoreBlock itemsPer={3} hideTitle />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Card sx={{ borderRadius: "1rem" }} className="product-card">
                {/* <CardHeader
                    avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        R
                    </Avatar>
                    }
                    action={ 
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                    }
                    title="Shrimp and Chorizo Paella"
                    subheader="September 14, 2016"
                /> *
                <CardContent className="article-card-content">
                    <Typography variant="body2" color="text.secondary" style={{textTransform: "uppercase"}}>
                        {product.category}
                    </Typography>
                    <Typography variant="h5" className="article-card-title">
                        {product.title}
                    </Typography>
                    <Typography variant="h5" color="text.secondary" className="article-card-title">
                        $ {product.price}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" className="cart-article-description">
                        {product.description}
                    </Typography>
                </CardContent>
                <div className="product-actions">
                    <CardActions disableSpacing>
                        <IconButton 
                            onClick={() => handleSelectArticle(product)}
                            sx={{marginLeft: 'auto'}} 
                            aria-label="remove from cart"
                        >
                            <RemoveRedEyeOutlinedIcon />
                        </IconButton>
                        { _.filter(cart, {id: product.id}).length > 0 ?
                            <IconButton 
                                onClick={() => handleRemoveFromCart(product)}
                                sx={{marginRight: 'auto'}} 
                                aria-label="remove from cart">
                                    <ShoppingCartIcon color="primary" />
                            </IconButton> : 
                            <IconButton 
                                onClick={() => handleAddToCart(product)}
                                sx={{marginRight: 'auto'}}
                                aria-label="add to cart"><AddShoppingCartIcon />
                            </IconButton>
                        }
                        {/* <IconButton aria-label="share">
                            <ShareIcon />
                        </IconButton> }
                    </CardActions>
                </div>
                <CardMedia
                    component="img"
                    height="194"
                    image={product.image}
                    alt="Paella dish"
                />
            </Card> */}
    </Grid>
  );
}
