import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import Header from '../../../../components/header/Header';
import Page from '../../../../page/Page';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from '../../../../components/footer/Footer';

const clientsResponsive: any = {
    // breakpoint from 0 up
    0 : {
        items : 1,
        loop: true,
        dots: true
    },
    // breakpoint from 480 up
    480 : {
        items : 2,
        loop: true,
        dots: true
    },
    // breakpoint from 768 up
    768 : {
        items : 3,
    }
}

const partnersResponsive: any = {
    // breakpoint from 0 up
    0 : {
        items : 2,
        loop: true,
        dots: true
    },
    // breakpoint from 480 up
    480 : {
        items : 4,
        loop: true,
        dots: true
    },
    // breakpoint from 768 up
    768 : { 
        items : 6,
    }
}


function Hosting (props: any) {



    return (
        <Page title='OVA consulting | Hosting' >
            <main className="ova-store hosting">
                <Header />
                <section className='mySwiper banner'>
                    <div className="container h-100">
                        <div className=" h-100"> 
                            <div className="row h-100 ">
                                <div className="col-lg-6 col-12 d-flex">
                                    <div className="banner-text">
                                        <h1>Fully Managed Cloud &nbsp; Web Hosting</h1>
                                        <p>Deidcated resources, full root access, & easy scaling. It’s the virtual private server you’ve been cravin</p>
                                        <Link to="/ova-store/hosting" className="btn btn-primary">Read more</Link>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 d-flex">
                                    <div className="banner-image">
                                        <img src="/assets/img/hosting-image.png" alt="Ova consulting hosting" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Search Your Domain Now */}
                <section className="search-domain">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="">
                                    <h3>Search Your Domain Now</h3>
                                    <form className="form-inline">
                                        <div className="form-group mb-2">
                                            <label htmlFor="inputPassword2" className="sr-only">Search your domain...</label>
                                            <input type="password" className="form-control" id="inputPassword2" placeholder="Search your domain..."/>
                                        </div>
                                        <button type="submit" className="btn btn-primary mb-2">Find now</button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="domains">
                                    <div className="row">
                                        <div className="col-lg-4 col-4">
                                            <div>
                                                <p className="domain-text">.com</p>
                                                <p className="domain-price">$10/month</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <div>
                                                <p className="domain-text">.co.uk</p>
                                                <p className="domain-price">$30/month</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <div>
                                                <p className="domain-text">.shop</p>
                                                <p className="domain-price">$10/month</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <div>
                                                <p className="domain-text">.com</p>
                                                <p className="domain-price">$10/month</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <div>
                                                <p className="domain-text">.com</p>
                                                <p className="domain-price">$10/month</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <div>
                                                <p className="domain-text">.com</p>
                                                <p className="domain-price">$10/month</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                {/* Our services */}
                <section className="hosting-services">
                    <div className="container">
                        <div>
                            <h3>Our hosting services</h3>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-lg-4 col-12 mb-3">
                                    <div>
                                        <div className="card">
                                            <div className="avatar mb-3" style={{backgroundImage: 'url(/assets/img/hosting/web-hosting.svg)'}}>
                                            </div>
                                            <p>
                                                <strong>Shared Web Hosting</strong>
                                            </p>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem temporibus eaque earum. Voluptatum in assumenda error sunt! Sint nisi ipsum laudantium suscipit quis rerum cum repudiandae, porro commodi asperiores consequuntur.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 mb-3">
                                    <div>
                                        <div className="card">
                                            <div className="avatar mb-3" style={{backgroundImage: 'url(/assets/img/hosting/vps-hosting.svg)'}}>
                                            </div>
                                            <p>
                                                <strong>VPS Hosting</strong>
                                            </p>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem temporibus eaque earum. Voluptatum in assumenda error sunt! Sint nisi ipsum laudantium suscipit quis rerum cum repudiandae, porro commodi asperiores consequuntur.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 mb-3">
                                    <div>
                                        <div className="card">
                                            <div className="avatar mb-3" style={{backgroundImage: 'url(/assets/img/hosting/dedicated-servers.svg)'}}>
                                            </div>
                                            <p>
                                                <strong>Dedicated Server</strong>
                                            </p>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem temporibus eaque earum. Voluptatum in assumenda error sunt! Sint nisi ipsum laudantium suscipit quis rerum cum repudiandae, porro commodi asperiores consequuntur.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 mb-3">
                                    <div>
                                        <div className="card">
                                            <div className="avatar mb-3" style={{backgroundImage: 'url(/assets/img/hosting/cloud-hosting.svg)'}}>
                                            </div>
                                            <p>
                                                <strong>Cloud Hosting</strong>
                                            </p>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem temporibus eaque earum. Voluptatum in assumenda error sunt! Sint nisi ipsum laudantium suscipit quis rerum cum repudiandae, porro commodi asperiores consequuntur.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 mb-3">
                                    <div>
                                        <div className="card">
                                            <div className="avatar mb-3" style={{backgroundImage: 'url(/assets/img/hosting/domain-names.svg)'}}>
                                            </div>
                                            <p>
                                                <strong>Domain Names</strong>
                                            </p>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem temporibus eaque earum. Voluptatum in assumenda error sunt! Sint nisi ipsum laudantium suscipit quis rerum cum repudiandae, porro commodi asperiores consequuntur.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 mb-3">
                                    <div>
                                        <div className="card">
                                            <div className="avatar mb-3" style={{backgroundImage: 'url(/assets/img/hosting/license-key.svg)'}}>
                                            </div>
                                            <p>
                                                <strong>License Keys</strong>
                                            </p>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem temporibus eaque earum. Voluptatum in assumenda error sunt! Sint nisi ipsum laudantium suscipit quis rerum cum repudiandae, porro commodi asperiores consequuntur.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Hosting statistics */}
                <section className="hosting-statistics">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-12">
                                <div className="stat">
                                    <p className="value">70K</p>
                                    <p className="label">Shared Web Hosting</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-12">
                                <div className="stat">
                                    <p className="value">100M</p>
                                    <p className="label">VPS Hosting</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-12">
                                <div className="stat">
                                    <p className="value">11K</p>
                                    <p className="label">Dedicated Server</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-12">
                                <div className="stat">
                                    <p className="value">199+</p>
                                    <p className="label">Cloud Hosting</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Word From Our Clients */}
                <section className="hosting-stats">
                    <div className="container py-4">
                        <div className="">
                            <h3>Word From Our Clients</h3>
                        </div>
                        <div>
                            <OwlCarousel 
                                className='owl-theme' 
                                loop dots={false} 
                                margin={20} 
                                responsive={clientsResponsive}
                                nav={false}>
                                <div className='item'>
                                    <div>
                                        <div className="card">
                                            <div className="avatar" style={{backgroundImage: 'url(/assets/img/eric.jpg)'}}>
                                            </div>
                                            <p>
                                                <strong>Eric Neossi</strong>
                                            </p>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem temporibus eaque earum. Voluptatum in assumenda error sunt! Sint nisi ipsum laudantium suscipit quis rerum cum repudiandae, porro commodi asperiores consequuntur.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div>
                                        <div className="card">
                                            <div className="avatar" style={{backgroundImage: 'url(/assets/img/eric.jpg)'}}>
                                            </div>
                                            <p>
                                                <strong>Eric Neossi</strong>
                                            </p>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem temporibus eaque earum. Voluptatum in assumenda error sunt! Sint nisi ipsum laudantium suscipit quis rerum cum repudiandae, porro commodi asperiores consequuntur.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div>
                                        <div className="card">
                                            <div className="avatar" style={{backgroundImage: 'url(/assets/img/eric.jpg)'}}>
                                            </div>
                                            <p>
                                                <strong>Eric Neossi</strong>
                                            </p>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem temporibus eaque earum. Voluptatum in assumenda error sunt! Sint nisi ipsum laudantium suscipit quis rerum cum repudiandae, porro commodi asperiores consequuntur.</p>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                        <div className='mt-4'>
                            <OwlCarousel 
                                className='owl-theme owl-partners' 
                                loop 
                                dots={false} 
                                margin={20} 
                                nav={false} 
                                items={6}
                                responsive={partnersResponsive}
                            >
                                <div className='item'>
                                    <div>
                                        <div className="card">
                                            <div className="avatar" style={{backgroundImage: 'url(/favicon.svg)'}}>
                                            </div>
                                            <p>
                                                OVA consulting
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div>
                                        <div className="card">
                                            <div className="avatar" style={{backgroundImage: 'url(/favicon.svg)'}}>
                                            </div>
                                            <p>
                                                OVA consulting
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div>
                                        <div className="card">
                                            <div className="avatar" style={{backgroundImage: 'url(/favicon.svg)'}}>
                                            </div>
                                            <p>
                                                OVA consulting
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div>
                                        <div className="card">
                                            <div className="avatar" style={{backgroundImage: 'url(/favicon.svg)'}}>
                                            </div>
                                            <p>
                                                OVA consulting
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div>
                                        <div className="card">
                                            <div className="avatar" style={{backgroundImage: 'url(/favicon.svg)'}}>
                                            </div>
                                            
                                            <p>
                                                OVA consulting
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div>
                                        <div className="card">
                                            <div className="avatar" style={{backgroundImage: 'url(/favicon.svg)'}}>
                                            </div>
                                            <p>
                                                OVA consulting
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </section>
                {/* Have you ever experienced problems in managing a website? */}
                <section className="hosting-problems">
                    <div className="container">
                        <div>
                            <div className="row">
                                <div className="col-lg-3 col-12"></div>
                                <div className="col-lg-6 col-12">
                                    <div>
                                        <h3>Have you ever experienced problems in managing a website?</h3>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis eius totam culpa voluptas reprehenderit nihil ducimus porro ex? Nostrum facilis qui esse nisi rerum, doloribus aut distinctio nam sint rem.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <OwlCarousel 
                                className='owl-theme' 
                                loop dots={false} 
                                margin={20} 
                                responsive={clientsResponsive}
                                nav={false}>
                                <div className='item'>
                                    <div>
                                        <div className="card">
                                            <div className="avatar" style={{backgroundImage: 'url(/assets/img/hosting/snail-icon.svg)'}}>
                                            </div>
                                            <p>
                                                <strong>Late Response</strong>
                                            </p>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem temporibus eaque earum. Voluptatum in assumenda error sunt! Sint nisi ipsum laudantium suscipit quis rerum cum repudiandae, porro commodi asperiores consequuntur.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div>
                                        <div className="card">
                                            <div className="avatar" style={{backgroundImage: 'url(/assets/img/hosting/computer.svg)'}}>
                                            </div>
                                            <p>
                                                <strong>Using Old Technologies</strong>
                                            </p>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem temporibus eaque earum. Voluptatum in assumenda error sunt! Sint nisi ipsum laudantium suscipit quis rerum cum repudiandae, porro commodi asperiores consequuntur.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div>
                                        <div className="card">
                                            <div className="avatar" style={{backgroundImage: 'url(/assets/img/hosting/price.svg)'}}>
                                            </div>
                                            <p>
                                                <strong>High Price</strong>
                                            </p>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem temporibus eaque earum. Voluptatum in assumenda error sunt! Sint nisi ipsum laudantium suscipit quis rerum cum repudiandae, porro commodi asperiores consequuntur.</p>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </section>
                {/* Hostingor From Another World */}
                <section className="hosting-another-world hosting-stats">
                    <div className="container">
                        <div>
                            <h3>Hostingor From Another World</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique molestias quaerat obcaecati ratione quia voluptatem incidunt, fuga, reprehenderit nesciunt atque facere natus quo rerum aspernatur distinctio facilis ea eveniet non?</p>
                        </div>
                        <div className="image">
                            <img width={"100%"} src="/assets/img/hosting/servers.png" alt="Servers" />
                        </div>
                        <div className='mt-4'>
                            <div className="row">
                                <div className="col-lg-3"></div>
                                <div className="col-lg-6 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-lg-6 col-6">
                                                <div className="list">
                                                    <ul>
                                                        <li>Website Builder</li>
                                                        <li>WP Accelerator</li>
                                                        <li>DDoS and Malware</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-6">
                                                <div>
                                                    <div className="list">
                                                        <ul>
                                                            <li>Free Domain and SSL</li>
                                                            <li>Auto Installer</li>
                                                            <li>24/7 Support</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* One-click installs so you can build happy. */}
                <section className='hosting-one-click'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-12">
                                <div>
                                    <img src="/assets/img/hosting/illustration-Hosting.svg" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-7 col-12 d-flex">
                                <div className='text-block'>
                                    <h3>One-click installs so you can build happy.</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec sagittis dolor. Maecenas quam nunc, tincidunt.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Level Up your web hosting with these must-have. */}
                <section className="hosting-level-up hosting-stats">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div>
                                    <h3>Level Up your web hosting with these must-have.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div>
                                    <div className="level-up-card">
                                        {/* <div className="image"> */}
                                            <div>
                                                <div style={{backgroundImage: 'url(/assets/img/hosting/data-safety.svg)'}}></div>
                                            </div>
                                        {/* </div> */}
                                        <div>
                                            <h5>Data Safety</h5>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum dignissimos nesciunt vel harum, voluptas pariatur </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div>
                                    <div className="level-up-card">
                                        {/* <div className="image"> */}
                                            <div>
                                                <div style={{backgroundImage: 'url(/assets/img/hosting/added-security.svg)'}}></div>
                                            </div>
                                        {/* </div> */}
                                        <div>
                                            <h5>Added Security</h5>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum dignissimos nesciunt vel harum, voluptas pariatur </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div>
                                    <div className="level-up-card">
                                        {/* <div className="image"> */}
                                            <div>
                                                <div style={{backgroundImage: 'url(/assets/img/hosting/seo-tool.svg)'}}></div>
                                            </div>
                                        {/* </div> */}
                                        <div>
                                            <h5>Data Safety</h5>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum dignissimos nesciunt vel harum, voluptas pariatur </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* So what's in Shared Hosting? A whole lot. */}
                <section className="hosting-shared-hosting">
                    <div className="container">
                        <div className="cards">
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-12 mb-3">
                                    <div>
                                        <div className="card">
                                            <p className='offer'>Starter Package</p>
                                            <p>Blogging Website</p>
                                            <p className="price">$3/month</p>
                                            <button className="btn btn-primary-outlined">BUY NOW</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-12 mb-3">
                                    <div>
                                        <div className="card recomended">
                                            <p className='offer'>Starter Package</p>
                                            <p>Blogging Website</p>
                                            <p className="price">$3/month</p>
                                            <button className="btn btn-primary-outlined">BUY NOW</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-12 mb-3">
                                    <div>
                                        <div className="card">
                                            <p className='offer'>Starter Package</p>
                                            <p>Blogging Website</p>
                                            <p className="price">$3/month</p>
                                            <button className="btn btn-primary-outlined">BUY NOW</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Frequently asked questions */}
                <section className="hosting-faq">
                    <div className="container">
                        <div>
                            <h3>Frequently asked questions</h3>
                        </div>
                        <div>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>Accordion 1</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                >
                                <Typography>Accordion 2</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </section>
                {/* 30 Days Money-Back Guarantee */}
                <section className="hosting-money-back">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className='block-image'>
                                    <img src="/assets/img/hosting/guaranty.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 d-flex">
                                <div className='block-text'>
                                    <h3>30 Days Money-Back Guarantee</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum fugit illum id vero dicta, tempore necessitatibus velit recusandae fuga neque asperiores corrupti quam consequatur natus rerum molestiae quod consectetur quo!</p>
                                    <br />
                                    <br />
                                    <br />
                                    <button className="btn btn-primary">BUY NOW</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Payment Options */}
                <section className="hosting-payment-options">
                    <div className="container">
                        <div>
                            <h3>Payment Options</h3>
                        </div>
                        <div className="images">
                            <img className='m-2' src="/assets/img/hosting/master-card.png" alt="" />
                            <img className='m-2' src="/assets/img/hosting/jcb.png" alt="" />
                            <img className='m-2' src="/assets/img/hosting/visa.png" alt="" />
                            <img className='m-2' src="/assets/img/hosting/master-card.png" alt="" />
                            <img className='m-2' src="/assets/img/hosting/master-card.png" alt="" />
                            <img className='m-2' src="/assets/img/hosting/master-card.png" alt="" />
                            <img className='m-2' src="/assets/img/hosting/master-card.png" alt="" />
                            <img className='m-2' src="/assets/img/hosting/master-card.png" alt="" />
                            <img className='m-2' src="/assets/img/hosting/master-card.png" alt="" />
                        </div>
                    </div>
                </section>
                {/* Start your business with Hostingor! */}
                <section className="hosting-shared-hosting">
                    <div className="container">
                        <div className="cards">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div>
                                        <h3 className="text-white">Start your business with Hostingor!</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div>
                                        <button className="btn btn-primary">BUY NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </main>
        </Page>
    )
}


export default Hosting;