import { GroupOutlined, ModeEditOutline, PlaylistAddCheckOutlined, SchoolOutlined, StartOutlined, TextSnippetOutlined, WorkOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';


interface Methodology {
  title: string, 
  text: string,
  icon: any
}

const ourMethodologies: Methodology[] = [
  {
    title: "expertise_methodology_poc",
    text: "expertise_methodology_poc_text",
    icon: TextSnippetOutlined,
  },
  {
    title: "expertise_methodology_team",
    text: "expertise_methodology_team_text",
    icon: GroupOutlined,
  },
  {
    title: "expertise_methodology_deliverables",
    text: "expertise_methodology_deliverables_text",
    icon: PlaylistAddCheckOutlined,
  },
  {
    title: "expertise_methodology_industrialisation",
    text: "expertise_methodology_industrialisation_text",
    icon: ModeEditOutline,
  }
]

interface Props {
  timeLines: Methodology[],
}

function Timeline (props:Props) {

  const { t } = useTranslation();
  const { timeLines } = props;

    return (
        <VerticalTimeline
          lineColor={ "#151d41" }
        >
          {
            timeLines.map((item: Methodology, index: number) => {

              return(
                <VerticalTimelineElement
                  key={"key-"+index}
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: '#008D9ACF', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid  #008D9ACF' }}
                  date=""
                  iconStyle={{ background: '#151d41', color: '#fff' }}
                  icon={<item.icon />}
                >
                  <h3 className="vertical-timeline-element-title">{t(item.title)}</h3>
                  <p>
                    {t(item.text)}
                  </p>
                </VerticalTimelineElement>
              )
            })
          }
        </VerticalTimeline>
    )
}


export default Timeline