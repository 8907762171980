import React from "react";
import { HashScroll } from "react-hash-scroll";
import { useTranslation } from "react-i18next";
import Footer from "../../components/footer/Footer";
import Header3 from "../../components/header/Header3";
import Page from "../../page/Page";
import { servicesAnimation } from "./components/servicesAnimations";


// Services and products.


export default function () {

    const { t } = useTranslation();

    const services = [
        {
            id: 1,
            title: t('home_services_box_title_1'),
            description: t('home_services_box_text_1'),
            image: '/assets/img/services/conseils.png',
            tag: "advice",
            bgCover: false
        },
        {
            id: 2,
            title: t('home_services_box_title_2'),
            description: t('home_services_box_text_2'),
            image: '/assets/img/services/project-management.png',
            tag: "projects-drive",
            bgCover: false
        },
        {
            id: 3,
            title: t('home_services_box_title_3'),
            description: t('home_services_box_text_3'),
            image: '/assets/img/services/bussiness-intelligence.png',
            tag: "business",
            bgCover: false
        },
        {
            id: 4,
            title: t('home_services_box_title_4'),
            description: t('home_services_box_text_4'),
            image: '/assets/services/ingenierie.png',
            tag: "engineering",
            bgCover: true
        },
        {
            id: 5,
            title: t('home_services_box_title_5'),
            description: t('home_services_box_text_5'),
            image: '/assets/services/api.png',
            tag: "api-management",
            bgCover: true
        },
        {
            id: 6,
            title: t('home_services_box_title_6'),
            description: t('home_services_box_text_6'),
            image: '/assets/services/cloud.png',
            tag: "cloud",
            bgCover: true
        },
        {
            id: 7,
            title: t('home_services_box_title_7'),
            description: t('home_services_box_text_7'),
            image: '/assets/img/services/training.png',
            tag: "training",
            bgCover: false
        },
        {
            id: 8,
            title: t('home_services_box_title_8'),
            description: t('home_services_box_text_8'),
            image: '/assets/img/services/assistance.png',
            tag: "support",
            bgCover: false
        }
    ]

    const products = [
        {
            id: 1,
            title: 'OVASSURE',
            description: t('home_services_box_text_1'),
            image: '/assets/img/products/ovassure-home.png',
            tag: "ovassure",
            bgCover: false
        },
        {
            id: 2,
            title: 'OVAPAY',
            description: t('home_services_box_text_2'),
            image: '/assets/img/products/tchoko.jpeg',
            tag: "tchoko",
            bgCover: false
        },
        {
            id: 3,
            title: 'OVACOM',
            description: t('home_product_ovacom_descr'),
            image: '/assets/img/products/ovacom-home.png',
            tag: "ovacom",
            bgCover: true
        },
        {
            id: 4,
            title: 'DEMATE',
            description: t('home_product_ovaged_descr'),
            image: '/assets/img/products/ovaged-home.png',
            tag: "ovaged",
            bgCover: true
        }
    ]


    React.useEffect(() => {
        servicesAnimation()
    }, [])


    return (
        <Page
            title={"OVA - Consulting | " + t("home_our_services")}
            description={t("home_our_services") + " - " + t("home_our_products")}
        >
            <div>
                {/* <!-- SCROLLER START --> */}
                <div className="main-scroller">
                    <div className="link active" data-scroller="description">
                        <a className="link-text-no-effect" href="#description">
                            Description
                        </a>
                    </div>
                    <div className="link" data-scroller="services">
                        <a className="link-text-no-effect" href="#services">
                            {t('home_our_services')}
                        </a>
                    </div>
                    <div className="link" data-scroller="products">
                        <a className="link-text-no-effect" href="#products">
                            {t('home_our_products')}
                        </a>
                    </div>
                    <div className="scrolltop"></div>
                </div>
                {/* <!-- SCROLLER END --> */}
                <Header3/>
                <section style={{marginTop: "150px"}} id="description" >
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-3 col-12"></div>
                            <div className="col-lg-6 col-sm-12 col-12 mb-3">
                                <h2 className="template-page-title strong" >
                                    {t("home_our_services_products")}
                                </h2>
                                {/* <h2 style={{fontFamily: 'Hurme'}} >
                                    <strong>
                                        Sed ut perspiciatis unde omnis iste 
                                    </strong>
                                </h2> */}
                                <p className="about-us-text mt-3">
                                    {t("about_text_p_2")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '50px'}} >
                        <div className="col-lg-4 col-sm-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad.png')", backgroundSize: 'cover'}}>
                            <div className="temp-h-500">
                            </div>
                        </div>
                        {/* <div className="col-lg-0 col-sm-0 col-6">
                            
                        </div> */}
                        <div className="col-lg-4 col-sm-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad2.jpg')", backgroundSize: 'cover'}}>
                            <div className="temp-h-500">
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad3.jpg')", backgroundSize: 'cover'}}>
                            <div className="temp-h-500">
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{marginTop: '50px'}} id="services">
                    <div className="container">
                        {
                            services.map((service, index) => {

                                if(index % 2 === 0) {

                                    return(
                                        
                                        <HashScroll hash={`#${service.tag}`} position="center">
                                            <div key={'service' + index} className="row  reverse-on-mobile">
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <div className="m-t-3">
                                                        <p className="template-page-title-text" id={service.tag} >
                                                        {service.title}
                                                        </p>
                                                        <p className="about-us-text mt-3">
                                                            {
                                                                service.description
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12">
                                                    <div>
                                                        <div className="text-template-img-rounded bg-primary" style={{backgroundImage: `url(${service.image})`, backgroundSize:  service.bgCover ? 'cover' : 'contain', backgroundPosition: 'center'}}>
                                                            {/* <img src="assets/img/man-work-pad3.jpg" height={'100%'} alt="" /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </HashScroll>
                                    )
                                } else {

                                    return (
                                        <HashScroll hash={`#${service.tag}`} position="center">
                                        <div key={'service' + index} className="row m-t-3 m-b-3">
                                            <div className="col-lg-6 col-sm-6 col-12">
                                                <div>
                                                    <div className="text-template-img-rounded bg-primary" style={{backgroundImage:`url(${service.image})`, backgroundSize: service.bgCover ? 'cover' : 'contain', backgroundPosition: 'center'}}>
                                                        {/* <img src="assets/img/man-work-pad3.jpg" height={'100%'} alt="" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                <div className="m-t-3">
                                                    <p className="template-page-title-text" id={service.tag}>
                                                        {service.title}
                                                    </p>
                                                    <p className="about-us-text mt-3">
                                                        {
                                                            service.description
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        </HashScroll>
                                    )
                                }
                            })
                        }
                    </div>
                </section>
                <section id="products" className="text-align-left bg-green" style={{marginTop: "50px", paddingTop: '100px', paddingBottom: '100px'}} >
                    <div className="container">
                        <p className="template-page-title-text text-center" >
                            {t('products')}
                        </p>
                        <p className="about-us-text mt-3">
                            {t("products_text_descr")}
                        </p>
                    </div>
                    <div className="container">
                        {
                            products.map((service, index) => {

                                if(index % 2 === 0) {

                                    return(

                                        <HashScroll hash={`#${service.tag}`} position="center">
                                        <div key={'service' + index} className="row reverse-on-mobile">
                                            <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                <div className="m-t-3">
                                                    <p className="template-page-title-text" id={service.tag} >
                                                    {service.title}
                                                    </p>
                                                    <p className="about-us-text mt-3">
                                                        {
                                                            service.description
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 col-12">
                                                <div>
                                                    <div className="text-template-img-rounded" style={{backgroundImage: `url(${service.image})`, backgroundSize: service.bgCover ? 'cover' : 'contain', backgroundPosition: 'center'}}>
                                                        {/* <img src="assets/img/man-work-pad3.jpg" height={'100%'} alt="" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </HashScroll>
                                    )
                                } else {

                                    return (
                                        
                                        <HashScroll hash={`#${service.tag}`} position="center">
                                        <div key={'service' + index} className="row m-t-3 m-b-3">
                                            <div className="col-lg-6 col-sm-6 col-12">
                                                <div>
                                                    <div className="text-template-img-rounded" style={{backgroundImage:`url(${service.image})`, backgroundSize:  service.bgCover ? 'cover' : 'contain', backgroundPosition: 'bottom'}}>
                                                        {/* <img src="assets/img/man-work-pad3.jpg" height={'100%'} alt="" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                <div className="m-t-3">
                                                    <p className="template-page-title-text" id={service.tag}>
                                                        {service.title}
                                                    </p>
                                                    <p className="about-us-text mt-3">
                                                        {
                                                            service.description
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        </HashScroll>
                                    )
                                }
                            })
                        }
                    </div>
                </section>
                <Footer/>
            </div>
        </Page>
    )
}