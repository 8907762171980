import './App.css';
import { ThemeProvider } from '@emotion/react';
import theme from './components/components/theme/theme';

import { Provider } from 'react-redux';

import {
  BrowserRouter as Router,
} from "react-router-dom";
import Routes from './components/components/routes/Routes';
import { AnyAction, Store } from 'redux';
import { ApplicationState } from './stores/types';
import Lang from './components/components/lang/Lang';
import { Cookies, withCookies } from 'react-cookie';
import CookiesConsent from './components/components/cookiesConsent/CookiesConsent';
import { Suspense } from 'react';
import SlashScreen from './components/components/slashSceen/SlashScreen';
import { SnackbarProvider } from "notistack";
import { ToastContainer } from 'react-toastify';

interface Props {
  store: Store<ApplicationState, AnyAction>,
  cookies?: Cookies
}

function App(props: Props) {
  const { store, cookies } = props;
  return (
    <Router >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <Suspense fallback={<SlashScreen/>}>

            <ToastContainer 
              autoClose={8000}
              position="top-right"
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              draggable
              pauseOnHover
              theme='colored'
              className={"custom-toast"}
            />
              <div className="App">
                {/* <header className="App-header">
                  <img src={logo} className="App-logo" alt="logo" />
                  <p>
                    Edit <code>src/App.tsx</code> and save to reload.
                  </p>
                  <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn React
                  </a>
                </header> */}
                <Lang>
                  <Routes/>
                </Lang>
              </div>
            </Suspense>
            </SnackbarProvider>
          <CookiesConsent/>
        </ThemeProvider>
      </Provider>
    </Router>
  );
}

export default withCookies(App);
