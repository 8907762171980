import { Client, clients } from "../const/clients";

export const getBgImage = (expertise) => {


    let bg = "url(/assets/expertises/software.png)"
    switch(expertise){
        case "software-engineering":
            bg = "url(/assets/expertises/software.png)"
            return bg;

        case "design":
            bg = "url(/assets/expertises/ui-ux-design.png)"
            return bg;

        case "infrastructure":
            bg = "url(/assets/expertises/security.png)"
            return bg;

        case "training":
            bg = "url(/assets/expertises/training.png)"
            return bg;
        case "data-science":
            bg = "url(/assets/expertises/datascience.png)"
            return bg;

        default:
            return bg;
    }
}

export const getClients = (expertise) => {
    // Get clients according to the expertise.

    let clientsArray:Client[] = [];

    clients.map(client => {
        
        if(expertise && client.category.includes(expertise)){
            clientsArray = clientsArray.concat(client);
        }
    })
    // //console.log(clientsArray);
    return clientsArray;
}
