import { useTranslation } from "react-i18next";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { clients } from "../../../const/clients";


function OurClients () {


    const { t } = useTranslation();

    return (
        <section id="clients" className="section-clients section" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-12 h-100">
                            <div className="video-player-container">
                                <div className="many-dots">
                                    <img src="/assets/svg/many-dots.svg" alt="" />
                                </div>
                                <div className="video image">
                                    <div className="iframe">
                                        <div className="h-100" style={{backgroundImage: "url(/assets/img/about/office.jpeg)"}}>
                                            {/* <img src="/assets/img/man-work-pad-digit.jpg" alt="" /> */}
                                        </div>
                                    {/* <iframe width="100%" height="315" src="/assets/img/man-work-pad-digit.jpg" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                                    {/* <iframe width="100%" height="315" src="https://www.youtube.com/embed/ScMzIvxBSi4?controls=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                                    </div>
                                    <div className="dots"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-12"></div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="h-100">
                                <div className="d-flex h-100">
                                    <div className="m-auto w-100">
                                        <div className="our-clients-bloc">
                                            <div>
                                                <p className="about-our-clients-bloc">
                                                    {t("home_our_clients")}
                                                </p>
                                                <p className="about-our-clients-bloc-text">
                                                    {t("our_clients_text")}
                                                </p>
                                                    <section style={{padding: "0px", backgroundColor: "#F2F2F2"}} className="references" id="references">
                                                        <OwlCarousel className='owl-theme references' 
                                                            loop 
                                                            margin={10} 
                                                            autoplayHoverPause
                                                            dots={false} 
                                                            autoplay 
                                                            smartSpeed={2000}
                                                            autoplayTimeout={3000}
                                                            responsive= {{
                                                                0:{
                                                                items:1,
                                                                loop:true
                                                                },
                                                                600:{
                                                                items:2,
                                                                loop:true
                                                                },
                                                                1000:{
                                                                items:3,
                                                                loop:true
                                                                }
                                                            }} >
                                                                {
                                                                    clients.map((item, index) => {

                                                                        return (
                                                                        <div className='item' key={index} >
                                                                            <div className="reference" style={{ backgroundImage: `url(${item.logo})`, height: '100px', backgroundPosition: 'center', backgroundSize: item.logo.includes("nourishka") ? '50%' : 'contain' }}></div>
                                                                        </div>
                                                                        )
                                                                    })
                                                                }
                                                            {/* <div className='item'>
                                                                <div className="reference" style={{backgroundImage: "url(assets/clients/Logo_SNCF_2011.svg.png)"}}></div>
                                                            </div>
                                                            <div className='item'>
                                                                <div className="reference" style={{backgroundImage: "url(assets/clients/ess-logo.svg)"}}></div>
                                                            </div>
                                                            <div className='item'>
                                                                <div className="reference" style={{backgroundImage: "url(assets/clients/bunec.png)"}}></div>
                                                            </div>
                                                            <div className='item'>
                                                                <div className="reference snail" style={{backgroundImage: "url(assets/clients/snail.png)", backgroundSize: "150%"}}></div>
                                                            </div>
                                                            <div className="item">
                                                                <div className="reference" style={{backgroundImage: "url(assets/clients/Logo_TotalEnergies.png)"}}></div>
                                                            </div>
                                                            <div className="item">
                                                                <div className="reference" style={{backgroundImage: "url(assets/clients/psl.png)", backgroundSize:"contain"}}></div>
                                                            </div>
                                                            <div className="item">
                                                                <div className="reference" style={{backgroundImage: "url(assets/clients/nourishka.png)"}}></div>
                                                            </div>
                                                            <div className="item">
                                                                <div className="reference" style={{backgroundImage: "url(assets/clients/ellios.png)"}}></div>
                                                            </div>
                                                            <div className="item">
                                                                <div className="reference" style={{backgroundImage: "url(assets/clients/korione.png)"}}></div>
                                                            </div>
                                                            <div className="item">
                                                                <div className="reference" style={{backgroundImage: "url(assets/clients/lbs.png)"}}></div>
                                                            </div>
                                                            <div className="item">
                                                                <div className="reference sabc" style={{backgroundImage: "url(assets/clients/nouveau_logo_groupe_sabc-01.png)", backgroundSize: '50%'}}></div>
                                                            </div>
                                                            <div className="item">
                                                                <div className="reference cud" style={{backgroundImage: "url(assets/clients/logo_cud_tranparent.png)", backgroundSize: '45%'}}></div>
                                                            </div>
                                                            <div className="item">
                                                                <div className="reference" style={{backgroundImage: "url(assets/clients/afrexim-bank.png)", backgroundSize: '45%'}}></div>
                                                            </div>
                                                            <div className="item">
                                                                <div className="reference" style={{backgroundImage: "url(assets/clients/cnps.jpg)", backgroundSize: 'contain'}}></div>
                                                            </div>
                                                            <div className="item">
                                                                <div className="reference" style={{backgroundImage: "url(assets/clients/logo-mbeng-au-pays.png)", backgroundSize: '110%'}}></div>
                                                            </div>
                                                            <div className='item'>
                                                                <div className="reference" style={{backgroundImage: "url(assets/clients/cadyst.png)"}}></div>
                                                            </div>
                                                            <div className='item'>
                                                                <div className="reference" style={{backgroundImage: "url(assets/clients/solar-hut.svg)"}}></div>
                                                            </div>
                                                            <div className='item'>
                                                                <div className="reference" style={{backgroundImage: "url(assets/clients/ess-logo.svg)"}}></div>
                                                            </div> */}
                                                            
                                                        </OwlCarousel>
                                                        {/* <div className="references">
                                                        </div> */}
                                                        <div className="scroll-up"></div>
                                                    </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}


export default OurClients