import { Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { openVkModal } from "../../components/openVkModal/openVkModal";
import { redirect } from "../../components/utils/utils";
import OwlCarousel from 'react-owl-carousel';
import Announces from "./components/Announce";
import Trainings from "./components/Trainings";
import { disableButon } from "../../components/button/disable/disable";
import { CONTACTAPI } from "../../api/contact";
import { unDisableButon } from "../../components/button/undisable/undisable";
import Page from "../../page/Page";
import ServicesProducts from "./components/ServicesProducts";
import { Link, useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { Autoplay, Navigation, Swiper as SwiperTypes } from "swiper";
import Scroller, { ScrollerItem } from "../../components/scroller/Scroller";
import BlockPhotos from "./components/BlockPhotos";
import OurClients from "../about/components/OurClients";
import StoreBlock from "./components/StoreBlock";
import { getClients } from "../../utils/functions";
import { clients } from "../../const/clients";

interface Alert {
  type: string;
  message: string
}

const autoplay = 3000

export default function Home () {

  const { t } = useTranslation();

  const [alert, setAlert] = useState<Alert | null>(null)

  const initialValues = {
    "name": "",
    "email": "",
    "phone": "",
    "subject": "",
    "message": "",
  }

  // Section references 
  const agencyRef = useRef(null);
  const servicesRef = useRef(null);
  const methodsRef = useRef(null);
  const trainingsRef = useRef(null);
  const newsRef = useRef(null);
  const partnersRef = useRef(null);
  const contactRef = useRef(null);
  const clientsRef = useRef(null);


  const pageScrollerItems: ScrollerItem[] = [
    {
      dataScroller: "agency",
      title: t('home_agency'),
      ref: agencyRef,
      bagTheme: "dark"
    },
    {
      dataScroller: "services",
      title: t('home_our_services'),
      ref: servicesRef,
      bagTheme: "white"
    },
    {
      dataScroller: "methods",
      title: t('home_our_methods'),
      ref: methodsRef,
      bagTheme: "white"
    },
    {
      dataScroller: "trainings",
      title: t('home_our_trainings'),
      ref: trainingsRef,
      bagTheme: "white"
    },
    {
      dataScroller: "clients",
      title: t("home_our_clients"),
      ref: clientsRef,
      bagTheme: "white"
    },
    {
      dataScroller: "news",
      title: t('Success stories'),
      ref: newsRef,
      bagTheme: "white"
    },
    {
      dataScroller: "partners",
      title: t('home_our_partners'),
      ref: partnersRef,
      bagTheme: "dark"
    },
    {
      dataScroller: "contact",
      title: "Contact",
      ref: contactRef,
      bagTheme: "white"
    }
  ]

  const navigate = useNavigate();

  const handleOpenContact = () => {

    openVkModal();
  }

  const handleSwiperChange = (swiper: SwiperTypes) => {
    // @ts-ignore
    window.$('.swiper-counter').text(swiper.activeIndex + 1);
  }

  const handleSwiper2Change = (swiper: SwiperTypes) => {
    // @ts-ignore
    // var elem = document.querySelector(".progress-bar.run-animation"); 
    // // @ts-ignore
    // elem.style.width = 1 + '%';

  }

  const handleProgress = () => {
    // var elem = document.querySelector(".progress-bar.run-animation"); 
    // var width = 1;
    // var autoplayTime = autoplay / 100;
    // var id = setInterval(frame, autoplayTime);
    // function frame() {
    //   if (width >= 100) {
    //     clearInterval(id);
    //   } else {
    //     width++; 
    //     // @ts-ignore
    //     elem.style.width = width + '%';
    //   }
    // }
  }

  const handleSubmit = (values) => {
    disableButon("submitNewsLeterDetails");
    CONTACTAPI.post("newsletter/", values).then((resp) => {
      setAlert({
        type: 'success',
        message: t("news_letter_submit_success")
      })
      disableButon("submitNewsLeterDetails");
    }).catch(err => {
      setAlert({
        type: 'danger',
        message: t("news_letter_submit_error")
      })
      unDisableButon("submitNewsLeterDetails");
    })
    // //console.log(values)
  }

  React.useEffect(() => {
    // Load animations effects

    // homeAnimationEffects()
  }, [])


  return (
    <Page
      title={"OVA - consulting | Home"}
      description={t('text_footer_description')}
    >
      <div className="main homepage">
        {/* <!-- SCROLLER START --> */}
        {/* <div className="main-scroller dark-bg">
            <div className="link active" data-scroller="agency">{t('home_agency')}</div>
            <div className="link" data-scroller="services">{t('home_our_services')}</div>
            <div className="link" data-scroller="methods">{t('home_our_methods')}</div>
            <div className="link" data-scroller="trainings">{t('home_our_trainings')}</div>
            <div className="link" data-scroller="news">{t("Success stories")}</div>
            <div className="link" data-scroller="partners">{t('home_our_partners')}</div>
            <div className="link" data-scroller="contact">Contact</div>
            <div className="scrolltop"></div>
          </div> */}
        <Scroller items={pageScrollerItems} />
        {/* <!-- SCROLLER END --> */}

        <Header />
        {/* <!-- PAGE START --> */}
        <div className="main-content">
          {/* <!-- SECTION START --> */}
          {/* <section className="agency" id="agency" style={{backgroundImage: "url(assets/img/agency-bg.png)"}}> */}
          <section className="agency" id="agency" ref={agencyRef} style={{ backgroundImage: "url(assets/img/ova-office-acceuil.jpg)" }}>
            <div>
              <div className="highlight swiper-container">
                <Swiper
                  spaceBetween={50}
                  slidesPerView={1}
                  onSlideChange={handleSwiperChange}
                  onSwiper={(swiper) => console.log(swiper)}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  navigation={
                    {
                      nextEl: '.swiper-next',
                      prevEl: '.swiper-previous',
                    }
                  }
                  modules={[Autoplay, Navigation]}
                >
                  <SwiperSlide>
                    <div className="title home-swiper-title">
                      {t("swiper_slide_title_1")}
                    </div>
                    <div className="excerpt home-swiper-description">
                      {t("swiper_slide_text_1")}
                    </div>
                    <Link to="/anounces#journey" className="home-button-try" >
                      <span>{t('home_button_try_free')}</span>
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="title home-swiper-title">
                      {t("swiper_slide_title_2")}
                    </div>
                    <div className="excerpt home-swiper-description">
                      {t("swiper_slide_text_2")}
                    </div>
                    <Link to="/anounces#agility-at-scale" className="home-button-try">
                      <span>{t('home_button_try_free')}</span>
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="title home-swiper-title">
                      {t("swiper_slide_title_3")}
                    </div>
                    <div className="excerpt home-swiper-description">
                      {t("swiper_slide_text_3")}
                    </div>
                    <Link to="/anounces#beyong-the-limits" className="home-button-try">
                      <span>{t('home_button_try_free')}</span>
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="title home-swiper-title">
                      {t("swiper_slide_title_4")}
                    </div>
                    <div className="excerpt home-swiper-description">
                      {t("swiper_slide_text_4")}
                    </div>
                    <Link to="/anounces#digital-work-place" className="home-button-try">
                      <span>{t('home_button_try_free')}</span>
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="title home-swiper-title">
                      {t("swiper_slide_title_5")}
                    </div>
                    <div className="excerpt home-swiper-description">
                      {t("swiper_slide_text_5")}
                    </div>
                    <Link to="/anounces#bitrix24" className="home-button-try">
                      <span>{t('home_button_try_free')}</span>
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="title home-swiper-title">
                      {t("swiper_slide_title_6")}
                    </div>
                    <div className="excerpt home-swiper-description">
                      {t("swiper_slide_text_6")}
                    </div>
                    <Link to="/anounces#fortinet" className="home-button-try">
                      <span>{t('home_button_try_free')}</span>
                    </Link>
                  </SwiperSlide>
                </Swiper>
                <div className="swiper-controls" style={{ position: 'relative', zIndex: 1 }}>
                  <div className="swiper-previous"></div>
                  <div className="swiper-counter">1</div>
                  <div className="swiper-next"></div>
                </div>
              </div>
              <div className="bottom-branding">
                <div className="socials">
                  <div className="linkedin">
                    <Link to="#">
                    </Link>
                  </div>
                  <div className="instagram">
                    <Link to="#"></Link>
                  </div>
                  <div className="twitter">
                    <Link to="#"></Link>
                  </div>
                  <div className="facebook">
                    <Link to="https://www.facebook.com/Ova-consulting-sarl-102490568946434"></Link>
                  </div>
                  <div className="behance">
                    <Link to="#"></Link>
                  </div>
                </div>
                <div className="corporate">
                  Ovacorporate
                </div>
              </div>
              <div className="bottom-cards">
                <div className="static-card">
                  <div className="scrolldown">Menu</div>
                  <div className="menu">
                    <div className="link" onClick={() => redirect("/", navigate)} data-scroller="agency">{t('home')}</div>
                    <div className="link" onClick={() => redirect("/about", navigate)} data-scroller="services">{t('home_about')}</div>
                    <div className="link" onClick={() => redirect("/expertises", navigate)} data-scroller="methods">{t('our_experties')}</div>
                    <div className="link" onClick={() => redirect("/recruitment", navigate)} data-scroller="references">{t('header_recruitment')}</div>
                    <div className="link" onClick={() => redirect("/contact", navigate)} data-scroller="contact">Contact</div>
                  </div>
                </div>
                {/* <NewsCard/> */}
                <div className="dynamic-card home-banner-news-card swiper-container">
                  <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    onSlideChange={handleSwiper2Change}
                    // onSwiper={(swiper) => console.log(swiper)}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    // onProgress={handleProgress}
                    watchSlidesProgress={true}
                    onSlideChangeTransitionStart={() => {
                      $(".progress-bar").removeClass("run-animation");
                      $(".progress-bar").removeClass("active");
                    }}
                    onSlideChangeTransitionEnd={() => {
                      $(".progress-bar").eq(0).addClass("run-animation");
                      $(".progress-bar").addClass("active");
                    }}
                    onInit={() => {
                      $(".progress-bar").removeClass("run-animation");
                      $(".progress-bar").removeClass("active");
                      $(".progress-bar").eq(0).addClass("run-animation");
                      $(".progress-bar").eq(0).addClass("active");
                    }}
                  // on = {
                  //   {
                  //     init: function () {
                  //       $(".progress-bar").removeClass("run-animation");
                  //       $(".progress-bar").removeClass("active");
                  //       $(".progress-bar").eq(0).addClass("run-animation");
                  //       $(".progress-bar").eq(0).addClass("active");
                  //     },
                  //     slideChangeTransitionStart: function () {
                  //       $(".progress-bar").removeClass("run-animation");
                  //       $(".progress-bar").removeClass("active");
                  //       console.log("klkslkld");
                  //       // $(".progress-bar").eq(0).addClass("run-animation");
                  //     },
                  //     slideChangeTransitionEnd: function () {
                  //       $(".progress-bar").eq(0).addClass("run-animation");
                  //       $(".progress-bar").addClass("active");
                  //       console.log("klkslkld");
                  //     }
                  //   }
                  // }
                  >
                    <SwiperSlide>
                      <div className="image" style={{ backgroundImage: "url(http://www.ixxo.fr/wp-content/uploads/2017/02/ixxo-veille-concurrentielle-prospective.jpg)", backgroundSize: 'cover', backgroundColor: '#fff' }}></div>
                      <div className="content">
                        <div className="title">
                          {t("article_1_title")}
                        </div>
                        <div className="excerpt">
                          {t("article_1_text")}
                        </div>
                        <Link className="link" target={"_blank"} to="http://www.ixxo.fr/wp-content/uploads/2017/02/ixxo-veille-concurrentielle-prospective.jpg">
                          <span>{t('home_read_more')}</span>
                        </Link>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="image" style={{ backgroundImage: "url(https://e-vitalis.com/images/sampledata/asimage/services/planning/it-governance.png)", backgroundColor: '#fff' }}></div>
                      <div className="content">
                        <div className="title">
                          {t('article_2_title')}
                        </div>
                        <div className="excerpt">
                          {t("article_2_text")}
                        </div>
                        <Link className="link" target={"_blank"} to="https://www.itgovernance.eu/fr-fr/gouvernance-informatique-fr">
                          <span>{t('home_read_more')}</span>
                        </Link>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="image" style={{ backgroundImage: "url(https://img-0.journaldunet.com/Q2S6mBdoGWo3WRafgxJGuFu7RqM=/1500x/smart/d208a537739749b28f1f315d08bcf83b/ccmcms-jdn/27801979.jpg)", backgroundSize: 'cover', backgroundColor: '#fff' }}></div>
                      <div className="content">
                        <div className="title">
                          {t("article_3_title")}
                        </div>
                        <div className="excerpt">
                          {t("article_3_text")}
                        </div>
                        <Link className="link" target={"_blank"} to="https://www.redhat.com/fr/topics/api/what-is-api-management">
                          <span>{t('home_read_more')}</span>
                        </Link>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                  <div className="progress-bar"></div>
                </div>
              </div>

            </div>
          </section>
          {/* <!-- SECTION END --> */}

          {/* Services de ova */}

          {/* <!-- SECTION START --> */}
          <div ref={servicesRef}>
            <ServicesProducts />
          </div>
          {/* <!-- SECTION END --> */}

          {/* <!-- SECTION START --> */}
          <section className="methods mb-0" id="methods" ref={methodsRef}>
            <div className="methods-container">
              <div className="methods-highlight">
                <div className="title">
                  {t("home_our_methods")}
                </div>
                <div className="excerpt">
                  {t("home_our_methods_text")}
                </div>
              </div>
              <div className="methods-cards">
                {/* <div className="card text-align-left" onClick={() => redirect('/our-methods#it-audit')} >
                    <div className="icon"></div>
                    <div className="title">{t("home_our_methods_audit")}</div>
                    <div className="excerpt">{t("home_our_methods_audit_text")}</div>
                    <div className="image" style={{backgroundImage: "url(assets/img/audit.png)"}}>
                      <img  alt="pointer hand" className="card-hand-pointer" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E" />
                    </div>
                    <div className="eye"></div>
                  </div>
                  <div className="card text-align-left" onClick={() => redirect('/our-methods#needs-collection')} >
                    <div className="icon"></div>
                    <div className="title">{t("home_our_methods_rapp_recom")}</div>
                    <div className="excerpt">{t("home_our_methods_rapp_recom_text")}</div>
                    <div className="image" style={{backgroundImage: "url(assets/img/receuil.png)"}}>
                      <img  alt="pointer hand" className="card-hand-pointer" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E" />
                    </div>
                    <div className="eye"></div>
                  </div>
                  <div className="card text-align-left" onClick={() => redirect('/our-methods#road-map')} >
                    <div className="icon"></div>
                    <div className="title">{t("home_our_methods_roadmap_budg")}</div>
                    <div className="excerpt">{t("home_our_methods_roadmap_budg_text")}</div>
                    <div className="image" style={{backgroundImage: "url(assets/img/budget.jpg)"}}>
                      <img  alt="pointer hand" className="card-hand-pointer" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E" />
                    </div>
                    <div className="eye"></div>
                  </div>
                  <div className="card text-align-left" onClick={() => redirect('/our-methods#analysis-design')} >
                    <div className="icon"></div>
                    <div className="title">{t("home_our_methods_dev")}</div>
                    <div className="excerpt">{t("home_our_methods_dev_text")}</div>
                    <div className="image" style={{backgroundImage: "url(assets/img/analyse.jpg)"}}>
                      <img  alt="pointer hand" className="card-hand-pointer" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E" />
                    </div>
                    <div className="eye"></div>
                  </div>
                  <div className="card text-align-left" onClick={() => redirect('/our-methods#deployment-user-training')} >
                    <div className="icon"></div>
                    <div className="title">{t("home_our_methods_training")}</div>
                    <div className="excerpt">{t("home_our_methods_training_text")}</div>
                    <div className="image" style={{backgroundImage: "url(assets/img/Formation.jpg)"}}>
                      <img  alt="pointer hand" className="card-hand-pointer" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E" />
                    </div>
                    <div className="eye"></div>
                  </div>
                  <div className="card text-align-left" onClick={() => redirect('/our-methods#mce-tma')} >
                    <div className="icon"></div>
                    <div className="title">{t("home_our_methods_evolution")}</div>
                    <div className="excerpt">{t("home_our_methods_evolution_text")}</div>
                    <div className="image" style={{backgroundImage: "url(assets/img/maintanance.jpeg)"}}>
                      <img alt="pointer hand" className="card-hand-pointer" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E" />
                    </div>
                    <div className="eye"></div>
                  </div> */}
                <div className="card text-align-left" onClick={() => redirect('/our-methods#cadrage', navigate)} >
                  <div className="icon"></div>
                  <div className="title">{t("expertise_software_method_farming")}</div>
                  <div className="excerpt">{t("expertise_software_method_farming_text")}</div>
                  <div className="image" style={{ backgroundImage: "url(assets/img/methods/cadrage.jpeg)" }}>
                    <img alt="pointer hand" className="card-hand-pointer" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E" />
                  </div>
                  <div className="eye"></div>
                </div>
                <div className="card text-align-left" onClick={() => redirect('/our-methods#budgetisation', navigate)} >
                  <div className="icon"></div>
                  <div className="title">{t("expertise_software_method_planing")}</div>
                  <div className="excerpt">{t("expertise_software_method_planing_text")}</div>
                  <div className="image bg-primary" style={{ backgroundImage: "url(assets/img/methods/planing.jpeg)" }}>
                    <img alt="pointer hand" className="card-hand-pointer" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E" />
                  </div>
                  <div className="eye"></div>
                </div>
                <div className="card text-align-left" onClick={() => redirect('/our-methods#slot-realisation', navigate)} >
                  <div className="icon"></div>
                  <div className="title">{t("expertise_software_lot_realisation")}</div>
                  <div className="excerpt">{t("expertise_software_lot_realisation_text")}</div>
                  <div className="image" style={{ backgroundImage: "url(assets/img/methods/todo.jpeg)" }}>
                    <img alt="pointer hand" className="card-hand-pointer" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E" />
                  </div>
                  <div className="eye"></div>
                </div>
                <div className="card text-align-left" onClick={() => redirect('/our-methods#countinious-delivery', navigate)} >
                  <div className="icon"></div>
                  <div className="title">{t("expertise_software_method_countinous_delivery")}</div>
                  <div className="excerpt">{t("expertise_software_method_countinous_delivery_text")}</div>
                  <div className="image" style={{ backgroundImage: "url(assets/img/methods/delivery.jpg)" }}>
                    <img alt="pointer hand" className="card-hand-pointer" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E" />
                  </div>
                  <div className="eye"></div>
                </div>
                <div className="card text-align-left" onClick={() => redirect('/our-methods#adjustement', navigate)} >
                  <div className="icon"></div>
                  <div className="title">{t("expertise_software_modifications")}</div>
                  <div className="excerpt">{t("expertise_software_modifications_text")}</div>
                  <div className="image" style={{ backgroundImage: "url(assets/img/methods/adjust.jpeg)" }}>
                    <img alt="pointer hand" className="card-hand-pointer" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E" />
                  </div>
                  <div className="eye"></div>
                </div>
                <div className="card text-align-left" onClick={() => redirect('/our-methods#launching', navigate)} >
                  <div className="icon"></div>
                  <div className="title">{t("expertise_software_final_delivery")}</div>
                  <div className="excerpt">{t("expertise_software_final_delivery_text")}</div>
                  <div className="image" style={{ backgroundImage: "url(assets/img/methods/launching.webp)" }}>
                    <img alt="pointer hand" className="card-hand-pointer" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E" />
                  </div>
                  <div className="eye"></div>
                </div>
                <div className="card text-align-left" onClick={() => redirect('/our-methods#training', navigate)} >
                  <div className="icon"></div>
                  <div className="title">{t("expertise_software_user_training")}</div>
                  <div className="excerpt">{t("expertise_software_user_training_text")}</div>
                  <div className="image bg-primary" style={{ backgroundImage: "url(assets/img/methods/training.jpeg)" }}>
                    <img alt="pointer hand" className="card-hand-pointer" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E" />
                  </div>
                  <div className="eye"></div>
                </div>
                <div className="card text-align-left" onClick={() => redirect('/our-methods#support', navigate)} >
                  <div className="icon"></div>
                  <div className="title">{t("expertise_software_support_maintainance")}</div>
                  <div className="excerpt">{t("expertise_software_support_maintainance_text")}</div>
                  <div className="image" style={{ backgroundImage: "url(assets/img/methods/support.jpeg)" }}>
                    <img alt="pointer hand" className="card-hand-pointer" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E" />
                  </div>
                  <div className="eye"></div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- SECTION END --> */}

          {/* BLOCK PHOTOS START */}
          <BlockPhotos />
          {/* BLOCK PHOTOS END */}
          <div ref={trainingsRef}>
            <Trainings />
          </div>

          {/* BLOCK STORE START */}
          {/* <BlockPhotos /> */}
          {/* BLOCK STORE END */}

          {/* BLOCK PHOTOS START */}
          {/* <StoreBlock /> */}
          {/* BLOCK PHOTOS END */}

          {/* CLIENTS BLOCK START */}
          {/* <OurClients/> */}
          <section className="py-4">
            <div className="banner-2">
              <OwlCarousel className='owl-theme our-clients-carousel'
                loop
                margin={10}
                autoplayHoverPause
                dots={false}
                autoplay
                smartSpeed={2000}
                autoplayTimeout={3000}
                responsive={{
                  0: {
                    items: 2,
                    loop: true
                  },
                  600: {
                    items: 4,
                    loop: true
                  },
                  1000: {
                    items: 5,
                    loop: true
                  }
                }}
              >
                {
                  clients.map((item, index) => {

                    return (
                      <div className='item zuk' key={index} >
                        <div className="reference" style={{ backgroundImage: `url(${item.logo})`, height: '100px', backgroundPosition: 'center' }}></div>
                      </div>
                    )
                  })
                }
              </OwlCarousel>
            </div>
          </section>
          {/* CLIENTS BLOCK END */}

          {/* Success stories  */}
          <div ref={newsRef}>
            <Announces />
          </div>

          {/* <!-- PARTNERS SECTION START --> */}
          <section className="references" id="partners" ref={partnersRef}>
            <div className="highlight">
              <div className="title">
                {t("home_our_partners")}
              </div>
              {/* <div className="excerpt"> */}
              {/* {t("home_our_clients_text")} */}
              {/* my nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper  */}
              {/* </div> */}
            </div>
            <div className="references">
              <div className="reference" style={{ backgroundImage: "url(assets/partners/fortinet.png)" }}></div>
              {/* <div className="reference" style={{backgroundImage: "url(assets/partners/aws.png)"}}></div> */}
              <div className="reference" style={{ backgroundImage: "url(assets/partners/laravel.png)" }}></div>
              <div className="reference" style={{ backgroundImage: "url(assets/partners/microsoft.png)" }}></div>
              <div className="reference" style={{ backgroundImage: "url(assets/partners/paloalto.png)", filter: 'brightness(0) invert(1)', backgroundSize: 'cover' }}></div>
              <div className="reference" style={{ backgroundImage: "url(assets/partners/auditix.png)", filter: 'brightness(0) invert(1)' }}></div>
              <div className="reference" style={{ backgroundImage: "url(assets/partners/odoo_logo.png)" }}></div>
              <div className="reference" style={{ backgroundImage: "url(assets/partners/openclassrooms.png)" }}></div>
              {/* <div className="reference" style={{backgroundImage: "url(assets/partners/python.png)"}}></div> */}
              {/* <div className="reference" style={{backgroundImage: "url(assets/partners/snail.png)"}}></div> */}
              <div className="reference" style={{ backgroundImage: "url(assets/partners/oracle.png)" }}></div>
              {/* <div className="reference mobile-bg-cover" style={{backgroundImage: "url(assets/partners/lenovo-1-logo.svg)", backgroundSize: 'auto'}}></div> */}
              <div className="reference" style={{ backgroundImage: "url(assets/partners/hp-logo.png)", filter: 'brightness(0) invert(1)' }}></div>
              <div className="reference mobile-bg-contain" style={{ backgroundImage: "url(assets/partners/macrotech.png)", backgroundSize: 'cover', filter: 'brightness(0) invert(1)' }}></div>
              <div className="reference" style={{ backgroundImage: "url(assets/partners/bitrix24.png)", filter: 'brightness(0) invert(1)' }}></div>
              {/* <div className="reference" style={{backgroundImage: "url(assets/partners/paprika.svg)", filter: 'brightness(0) invert(1)'}}></div> */}
              {/* <div className="reference" style={{backgroundImage: "url(assets/partners/xerox.png)", filter: 'brightness(0) invert(1)'}}></div> */}
            </div>
            <div className="scroll-up"></div>
          </section>
          {/* <!-- SECTION END --> */}
          {/* <!-- CONTACT SECTION START --> */}
          <section className="contact" id="contact" ref={contactRef}>
            <div className="contact-container" style={{ padding: '70px 0px' }}>
              <div className="contact-form">
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  <Form>
                    {alert && <div className={`alert alert-${alert?.type}`}>
                      {alert.message}
                    </div>}
                    <div className="contact-content">
                      <div className="title">{t('home_contact_news_letter')}</div>
                      <div className="excerpt h-fit-content">
                        {t('home_contact_news_letter_text')}
                      </div>
                      <div className="form-group email-element">
                        <Field type="text" name="name" id="name" placeholder={t('home_contact_name')} />
                      </div>
                      <div className="form-group email-element">
                        <Field type="email" name="email" id="email" placeholder={t('home_contact_email')} />
                      </div>
                      <div className="form-group submit-element">
                        <button type="submit" id="submitNewsLeterDetails" ><span>{t('SUBSCRIBE')}</span></button>
                      </div>
                    </div>
                    {/*<div className="form-group email-element">
                        <Field type="text" name="phone" id="phone" placeholder={t('home_contact_phone')}/>
                      </div>
                      <div className="form-group email-element">
                        <Field type="text" name="subject" id="subject" placeholder={t('home_contact_subject')}/>
                      </div>
                      <div className="form-group textarea-element">
                        <Field as="textarea" name="message" id="message" cols={30} rows={10} placeholder={t('home_contact_message')}/>
                    </div>*/}
                    {alert && <div className={`alert alert-${alert?.type}`}>
                      {alert.message}
                    </div>}
                  </Form>
                </Formik>
              </div>
              <div className="contact-content">
                <div className="title">{t('home_contact_write_us')}</div>
                <div className="excerpt h-fit-content">
                  {t('home_contact_write_us_text')}
                </div>
                <div className="email" style={{ position: 'relative', bottom: '0px' }}>
                  <Link to="mailto:contact@ova-consulting.fr">contact@ova-consulting.fr</Link> <br />
                  <Link to="mailto:support@ova-consulting.fr">support@ova-consulting.fr</Link>
                </div>
                <div className="phone mb-0" style={{ backgroundImage: "none", position: 'relative' }}>
                  <Link to="tel:+237696427703">+237 696427703</Link>
                </div>
                <div className="socials" style={{ position: 'relative', bottom: 0 }}>
                  <div className="linkedin">
                    <Link to="http://linkedin.com">
                    </Link>
                  </div>
                  <div className="instagram">
                    <Link to="http://instagram.com"></Link>
                  </div>
                  <div className="twitter">
                    <Link to="http://twitter.com"></Link>
                  </div>
                  <div className="facebook">
                    <Link to="https://www.facebook.com/Ova-consulting-sarl-102490568946434"></Link>
                  </div>
                  <div className="behance">
                    <Link to="https://www.facebook.com/Ova-consulting-sarl-102490568946434"></Link>
                  </div>
                </div>
              </div>
              {/* <div className="chat" hidden>
                  {/* <div className="modal-image"></div> *}
                  <div className="modal-message">
                    <ChatBull incoming={true} message={t("home_contact_greeting") + '<br/>' + t("home_contact_any_question")} />
                    <ChatBull incoming={false} message={t("home_contact_greeting") + '<br/>' + t("home_contact_any_question")} />
                    {/* {t("home_contact_greeting")}<br/>{t("home_contact_any_question")} *}
                  </div>
                  <form>
                    <input type="text" placeholder="Message" />
                    <button type="submit"></button>
                  </form>
                </div> */}
            </div>
            <div className="personas">
              <div className="persona">
                <div className="message">{t("home_contact_hello_any_question")}</div>
                <div className="picture"></div>
              </div>
              <div className="persona">
                <div className="message">{t("home_contact_hello_any_question")}</div>
                <div className="picture"></div>
              </div>
              <div className="persona">
                <div className="message">{t("home_contact_hello_any_question")}</div>
                <div className="picture"></div>
              </div>
            </div>
          </section>
          {/* <!-- SECTION END --> */}
          <button onClick={handleOpenContact} className=" fab-chat fab-button btn-primary">
          </button>

        </div>
        {/* <!-- PAGE END --> */}
        {/* <MessengerCustomerChat
            pageId="102490568946434"
            appId="1302765723569084"
            htmlRef="ref-102490568946434"
          /> */}
        <Footer />
      </div>
    </Page>
  )
}