import { ShoppingBasket, Star } from '@mui/icons-material';
import { Button, IconButton, Rating } from '@mui/material';
import { Product } from '../../views/home/components/StoreBlock';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useDispatch, useSelector } from 'react-redux';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { ApplicationState } from '../../../stores/types';
import _ from 'lodash';
import { StoreArticle } from '../types/storeCard';
import { currencyFormat } from '../utils/utils';
import { Link } from 'react-router-dom';

interface Props {
  product: StoreArticle;
  handleAddToCart: Function
  handleAddToWishlist: Function
}

export default function ProductCard(props: Props) {
  const {
    product: { title, category, main_image, price, ratings, store, id },
    handleAddToCart,
    handleAddToWishlist
  } = props;

  const { cart, whishlist } = useSelector((state: ApplicationState) => state.shop)

  return (
    <div className="product-card no-border">
      <div className="product-card-actions">
        {/* <IconButton onClick={() => handleAddToWishlist(props.product, _.filter(whishlist, {'id': id}).length > 0 ? true : false)} sx={{border: '1px solid rgb(0 0 0 / 30%)'}} >
          {
            _.filter(whishlist, {'id': id}).length > 0 ? <FavoriteIcon color={"primary"}/> :
          <FavoriteBorderIcon sx={{color: 'rgb(0 0 0 / 30%)'}}/>
          }
        </IconButton> */}
        {/* <IconButton sx={{border: '1px solid rgb(0 0 0 / 30%)'}} >
          <FavoriteBorderIcon sx={{color: 'rgb(0 0 0 / 30%)'}}/>
        </IconButton> */}
      </div>
      <div className="image" style={{backgroundImage: `url(${main_image})`, backgroundSize: "cover", backgroundPosition: "center"}}>
        {/* <img src={main_image} width="100%" alt="" /> */}
      </div>
      <div className="content">
        <p className="title">
          <Link to={`/ova-store/store/${store}/${id}`}>{title}</Link>
        </p>
        <p className="category">{category.title}</p>
        <p className="rate">
          <Rating
            name="rate"
            value={ratings}
            precision={0.1}
            icon={<Star fontSize="inherit" style={{ color: 'gold' }} />}
            readOnly
          />
        </p>
        <div className="prod-card-footer">
          <p className="price text-primary">{currencyFormat(price)}</p>
          {/* <div className="add-to-cart">
            {
              _.filter(cart, {'id': id}).length > 0 ?
              <Button
                // startIcon={<ShoppingBasket sx={{color: "#fff"}} />}
                variant="contained"
                disableElevation
                sx={{color: "#fff", width: "100%"}}
                onClick={() => handleAddToCart(props.product, true)}
                color={'primary'}>
                Remove from cart
              </Button>
              :
              <Button
              startIcon={<ShoppingBasket sx={{color: "#fff"}} />}
              variant="contained"
              disableElevation
              sx={{color: "#fff", width: "100%"}}
              onClick={() => handleAddToCart(props.product, false)}
              color={'primary'}>
              Add to cart
            </Button>
            }
          </div> */}
        </div>
      </div>
    </div>
  );
}
