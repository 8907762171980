import { GroupOutlined, ModeEditOutline, PlaylistAddCheckOutlined, SchoolOutlined, StartOutlined, TextSnippetOutlined, WorkOutline } from '@mui/icons-material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CodeIcon from '@mui/icons-material/Code';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import EngineeringIcon from '@mui/icons-material/Engineering';



export interface Methodology {
    title: string, 
    text: string,
    icon: any
  }
  
const datascienceMethodologies: Methodology[] = [
    {
      title: "expertise_methodology_poc",
      text: "expertise_methodology_poc_text",
      icon: TextSnippetOutlined,
    },
    {
      title: "expertise_methodology_team",
      text: "expertise_methodology_team_text",
      icon: GroupOutlined,
    },
    {
      title: "expertise_methodology_deliverables",
      text: "expertise_methodology_deliverables_text",
      icon: PlaylistAddCheckOutlined,
    },
    {
      title: "expertise_methodology_industrialisation",
      text: "expertise_methodology_industrialisation_text",
      icon: ModeEditOutline,
    }
];


  
const softwareMethodologies: Methodology[] = [
  {
    title: "expertise_software_method_farming",
    text: "expertise_software_method_farming_text",
    icon: AssignmentIcon,
  },
  {
    title: "expertise_software_method_planing",
    text: "expertise_software_method_planing_text",
    icon: AssignmentIcon,
  },
  {
    title: "expertise_software_lot_realisation",
    text: "expertise_software_lot_realisation_text",
    icon: AssignmentIcon,
  },
  {
    title: "expertise_software_method_countinous_delivery",
    text: "expertise_software_method_countinous_delivery_text",
    icon: AssignmentIcon,
  },
  {
    title: "expertise_software_preprod_delivery",
    text: "expertise_software_preprod_delivery_text",
    icon: AssignmentIcon,
  },
  {
    title: "expertise_software_modifications",
    text: "expertise_software_modifications_text",
    icon: AssignmentIcon,
  },
  {
    title: "expertise_software_final_delivery",
    text: "expertise_software_final_delivery_text",
    icon: AssignmentIcon,
  },
  {
    title: "expertise_software_support_maintainance",
    text: "expertise_software_support_maintainance_text",
    icon: AssignmentIcon,
  }
];

export { datascienceMethodologies, softwareMethodologies }