import { StoreArticle } from "../../components/components/types/storeCard"
import { AddToCartFailed, AddToCartRequest, AddToCartSuccess, AddToWishlistFailed, AddToWishlistRequest, AddToWishlistSuccess, ADD_TO_CART_FAILED, ADD_TO_CART_REQUEST, ADD_TO_CART_SUCCESS, ADD_TO_WISHLIST_FAILED, ADD_TO_WISHLIST_REQUEST, ADD_TO_WISHLIST_SUCCESS, LoadArticleFailed, LoadArticleRequest, LoadArticlesFailed, LoadArticlesRequest, LoadArticlesSuccess, LoadArticleSuccess, LOADING_ARTICLES_FAILED, LOADING_ARTICLES_REQUEST, LOADING_ARTICLES_SUCCESS, LOAD_ARTICLE_FAILED, LOAD_ARTICLE_REQUEST, LOAD_ARTICLE_SUCCESS, RemoveFromCartFailed, RemoveFromCartRequest, RemoveFromCartSuccess, RemoveFromWishlistFailed, RemoveFromWishlistRequest, RemoveFromWishlistSuccess, REMOVE_FROM_CART_FAILED, REMOVE_FROM_CART_REQUEST, REMOVE_FROM_CART_SUCCESS, REMOVE_FROM_WISHLIST_FAILED, REMOVE_FROM_WISHLIST_REQUEST, REMOVE_FROM_WISHLIST_SUCCESS, ArticleResuts } from "../types/StoreArticles"




export const loadArticlesRequest = (): LoadArticlesRequest => ({
    type: LOADING_ARTICLES_REQUEST
})

export const loadArticlesSuccess = (articles: ArticleResuts): LoadArticlesSuccess => ({
    type: LOADING_ARTICLES_SUCCESS,
    articles
})

export const loadArticlesFailed = (): LoadArticlesFailed => ({
    type: LOADING_ARTICLES_FAILED,
})

// Cart actions
export const addToCartRequest = (): AddToCartRequest => ({
    type: ADD_TO_CART_REQUEST
})

export const addToCartSuccess = (article: StoreArticle): AddToCartSuccess => ({
    type: ADD_TO_CART_SUCCESS,
    article
})

export const addToCartFailed = (): AddToCartFailed => ({
    type: ADD_TO_CART_FAILED
})

export const addToWishlistRequest = (): AddToWishlistRequest => ({
    type: ADD_TO_WISHLIST_REQUEST
})

export const addToWishlistSuccess = (article: StoreArticle): AddToWishlistSuccess => ({
    type: ADD_TO_WISHLIST_SUCCESS,
    article
})

export const addToWishlistFailed = (): AddToWishlistFailed => ({
    type: ADD_TO_WISHLIST_FAILED
})

export const removeFromWishlistRequest = (): RemoveFromWishlistRequest => ({
    type: REMOVE_FROM_WISHLIST_REQUEST
})

export const removeFromWishlistSuccess = (article: StoreArticle): RemoveFromWishlistSuccess => ({
    type: REMOVE_FROM_WISHLIST_SUCCESS,
    article
})

export const removeFromWishlistFailed = (): RemoveFromWishlistFailed => ({
    type: REMOVE_FROM_WISHLIST_FAILED
})

export const removeFromCartRequest = (): RemoveFromCartRequest => ({
    type: REMOVE_FROM_CART_REQUEST
})

export const removeFromCartSuccess = (article: StoreArticle): RemoveFromCartSuccess => ({
    type: REMOVE_FROM_CART_SUCCESS,
    article
})

export const removeFromCartFailed = (): RemoveFromCartFailed => ({
    type: REMOVE_FROM_CART_FAILED
})

export const loadArticleRequest = (): LoadArticleRequest => ({
    type: LOAD_ARTICLE_REQUEST
})

export const loadArticleSuccess = (article: StoreArticle): LoadArticleSuccess => ({
    type: LOAD_ARTICLE_SUCCESS,
    article
})

export const loadArticleFailed = (): LoadArticleFailed => ({
    type: LOAD_ARTICLE_FAILED
})