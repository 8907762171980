import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { purple } from '@mui/material/colors';




const DefaultButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#EDEDED",
    '&:hover': {
      backgroundColor: "#EDEDED",
    },
  }));


export {DefaultButton}