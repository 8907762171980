import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/footer/Footer";
import Header3 from "../../components/header/Header3";
import { servicesAnimation } from "../services/components/servicesAnimations";


// Services and products.


export default function () {

    const { t } = useTranslation();

    const strategy = [
        {
            id: 1,
            title: t('how_it_work_1'),
            description: t('how_it_work_1_text'),
            image: '/assets/img/maintenance.png',
            tag: "maintenance"
        },
        {
            id: 2,
            title: t('how_it_work_2'),
            description: t('how_it_work_2_text'),
            image: '/assets/img/architect.png',
            tag: "architect"
        },
        {
            id: 3,
            title: t('how_it_work_3'),
            description: t('how_it_work_3_text'),
            image: '/assets/img/devops.png',
            tag: "devops"
        },
        {
            id: 4,
            title: t('how_it_work_4'),
            description: t('how_it_work_4_text'),
            image: '/assets/img/cloud.png',
            tag: "cloud"
        },
        {
            id: 5,
            title: t('how_it_work_5'),
            description: t('how_it_work_5_text'),
            image: '/assets/img/methods.png',
            tag: "methods"
        },
        {
            id: 6,
            title: t('how_it_work_6'),
            description: t('how_it_work_6_text'),
            image: '/assets/svg/saas-big.svg',
            tag: "saas-big"
        },
        {
            id: 7,
            title: t('how_it_work_7'),
            description: t('how_it_work_7_text'),
            image: '/assets/svg/dev.svg',
            tag: "dev"
        },
        {
            id: 8,
            title: t('how_it_work_8'),
            description: t('how_it_work_8_text'),
            image: '/assets/svg/tools-big.svg',
            tag: "tools-big"
        },
        {
            id: 9,
            title: t('how_it_work_9'),
            description: t('how_it_work_9_text'),
            image: '/assets/svg/marketing-big.svg',
            tag: "marketing"
        },
        {
            id: 10,
            title: t('how_it_work_10'),
            description: t('how_it_work_10_text'),
            image: '/assets/pwa-big.svg',
            tag: "pwa"
        }
    ]


    React.useEffect(() => {
        servicesAnimation()
    }, [])


    return (
        <div>
            {/* <!-- SCROLLER START --> */}
            <div className="main-scroller">
                <div className="link active" data-scroller="description">
                    <a className="link-text-no-effect" href="#description">
                        Description
                    </a>
                </div>
                <div className="link" data-scroller="our-strategy">
                    <a className="link-text-no-effect" href="#our-strategy">
                        {t('our_strategy')}
                    </a>
                </div>
                <div className="scrolltop"></div>
            </div>
            {/* <!-- SCROLLER END --> */}
            <Header3/>
            <section style={{marginTop: "150px"}} id="description" >
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-12"></div>
                        <div className="col-lg-6 col-12">
                            <p className="template-page-title" >
                                {t("our_strategy")}
                            </p>
                            <h2 style={{fontFamily: 'Hurme'}} >
                                <strong>
                                    {/* Sed ut perspiciatis unde omnis iste  */}
                                </strong>
                            </h2>
                            <p className="about-us-text mt-3">
                                {t("about_text_p_2")}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop: '50px'}} >
                    <div className="col-lg-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad.png')", backgroundSize: 'cover'}}>
                        <div className="temp-h-500">
                        </div>
                    </div>
                    <div className="col-lg-0 col-6">
                        
                    </div>
                    <div className="col-lg-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad2.jpg')", backgroundSize: 'cover'}}>
                        <div className="temp-h-500">
                        </div>
                    </div>
                    <div className="col-lg-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad3.jpg')", backgroundSize: 'cover'}}>
                        <div className="temp-h-500">
                        </div>
                    </div>
                </div>
            </section>
            <section style={{marginTop: '50px'}} id="our-strategy">
                <div className="container">
                    {
                        strategy.map((service, index) => {

                            if(index % 2 === 0) {

                                return(

                                    <div key={'service' + index} className="row">
                                        <div className="col-lg-6 col-12">
                                            <div className="m-t-3">
                                                <p className="template-page-title-text" id={service.tag} >
                                                {service.title}
                                                </p>
                                                <p className="about-us-text mt-3">
                                                    {
                                                        service.description
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div>
                                                <div className="text-template-img-rounded bg-no-repeat" style={{backgroundImage: `url(${service.image})`, backgroundSize: 'contain', backgroundPosition: 'center'}}>
                                                    {/* <img src="assets/img/man-work-pad3.jpg" height={'100%'} alt="" /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {

                                return (

                                    <div key={'service' + index} className="row m-t-3 m-b-3">
                                        <div className="col-lg-6 col-12">
                                            <div>
                                                <div className="text-template-img-rounded bg-no-repeat" style={{backgroundImage:`url(${service.image})`, backgroundSize: 'contain', backgroundPosition: 'bottom'}}>
                                                    {/* <img src="assets/img/man-work-pad3.jpg" height={'100%'} alt="" /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="m-t-3">
                                                <p className="template-page-title-text" id={service.tag}>
                                                    {service.title}
                                                </p>
                                                <p className="about-us-text mt-3">
                                                    {
                                                        service.description
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </section>
            <Footer/>
        </div>
    )
}