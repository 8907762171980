 
 
export default function(){
    let pathname = window.location.pathname;
    // pathname.replace('/', '');
    let menuLinks = document.querySelectorAll('.menu-link');

    // //console.log("menuLinks ====>", menuLinks)

    menuLinks.forEach((element) => {

        let a_href = element.getAttribute('href');
        // a_href = a_href.replace('/', "")
        if(a_href == pathname) {
            
            element.classList.add('active');
        }
        // //console.log("dpfpdropeoitoriotr=======>", a_href);
    })

    // alert("Okay!! Well loaded " + window.location.pathname +' ' + menuLinks)
}