import { Action } from "redux";



export const LOADING_RECRUITMENT_REQUEST = 'LOADING_RECRUITMENT_REQUEST';
export const LOADING_RECRUITMENT_SUCCESS = 'LOADING_RECRUITMENT_SUCCESS';
export const LOADING_RECRUITMENT_FAILED = 'LOADING_RECRUITMENT_FAILED';

export interface Recruitment {
    count: number
}


export interface LoadingRecruitmentsState {
    loading_recruitment_request: boolean;
    loading_recruitment_success: boolean;
    loading_recruitment_failed: boolean;
}


export interface LoadRecruitmentRequest extends Action {
    type: typeof LOADING_RECRUITMENT_REQUEST
}

export interface LoadRecruitmentSuccess extends Action {
    type: typeof LOADING_RECRUITMENT_SUCCESS,
    recruitments: Recruitment
}

export interface LoadRecruitmentFailed extends Action {
    type: typeof LOADING_RECRUITMENT_FAILED
}



export interface RecruitmentState {
    loading: LoadingRecruitmentsState,
    recruitments: Recruitment
}