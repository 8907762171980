import { Rating } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Header from "../../../../components/header/Header";
import { useForm } from "react-hook-form";
import { Training, trainings, trainingsCategories } from "../../../../const/training";
import Page from "../../../../page/Page";
import Footer from "../../../../components/footer/Footer";
import Swal from 'sweetalert2';
import { CONTACTAPI } from "../../../../api/contact";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import _ from "lodash";


interface FiltersObj {
    category?: string,
    fne?: string,
    level?: string,
    type?: string,
    modalities?: string
}

const StyledRating = withStyles({
    iconFilled: {
      color: '#FFD700',
    }
})(Rating);  

interface Message {
    type: boolean,
    message: string
}
function TrainingsPage () {

    const [allTrainings, setAllTrainings] = useState<Training[]>([])


    const { register, handleSubmit, reset } = useForm();

    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState('');
    let [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();



    const { t } = useTranslation();

    const handleFilter = (filters: FiltersObj) => {

        let initialTrainings = trainings;

        // Filter per category:

        if(filters.category){
            initialTrainings = _.filter(initialTrainings, (tCategory) => {
                // @ts-ignore
                return tCategory.category.includes(filters.category)
            });
        } else if (filters?.level){
            initialTrainings = _.filter(initialTrainings, (tCategory) => {
                // @ts-ignore
                return tCategory.category.includes(filters.level)
            });
        } else if (filters.modalities){
            initialTrainings = _.filter(initialTrainings, (tCategory) => {
                // @ts-ignore
                return tCategory.trainingPlaces.includes(filters.modalities)
            });
        }
        // //console.log("initialTrainings ====>", initialTrainings)
        setAllTrainings(initialTrainings);

        // level : initiations ans fundamentals
        // type : certifying
    }

    const handleFilterByCategory = (cat: string) => {
        setCategory(cat);
        
        const filteredTrainings = _.filter(trainings, (tCategory) => {
            return tCategory.category.includes(cat)
        });
        // //console.log("catttt ======>", filteredTrainings, allTrainings)
        setAllTrainings(filteredTrainings as Training[]);

    };

    const handleSubmitForm = (data) => {
        setLoading(true);
        const toSend = {
            "first_name": data.firstName,
            "last_name": data.lastName,
            "society": data.society,
            "role": data.role,
            "phone": data.phone,
            "postal_code": data.postalCode,
            "training": data.training,
            "email": data.email,
            "number_of_inscriptions": data.number,
            "oher_informations": data.complement,
            "interested_by_the_course": data.form_interested,
            "accepted": data.form_accept,
            "is_custom": true,
            "starting_date": data.starting_date,
            "ending_date": data.ending_date,
        }

        // //console.log("Data to send ", toSend)
        
        CONTACTAPI.post("training", toSend).then(resp => {
            setLoading(false);
            reset();
            toast(t("obtain_estimation_success"), {
                position: toast.POSITION.TOP_RIGHT
            });
            // @ts-ignore
            window.$('#exampleModalLong').modal('hide')
        }).catch(err => {
            setLoading(false);
            toast.error(t("obtain_estimation_error"), {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    };

    // const handleSFilter = (data: any) => {
    //     alert("ldkfldkfl")
    //     //console.log(data);
    //     navigate({
    //         pathname: '/dresses',
    //         search: '?color=blue'
    //     })
    // };

    const serializeFormQuery = (target: any) => {

        // //console.log("target ===> ", target.values());
        
        const data = new FormData(target);
        let object = {};

        for (const [name,value] of data) {
            // //console.log(name, ":", value)
            object[name] = value;
        }

        handleFilter(object);

        return object;
    };


    function handleSFilter(event) {
        event.preventDefault();
        // The serialize function here would be responsible for
        // creating an object of { key: value } pairs from the
        // fields in the form that make up the query.
        let params = serializeFormQuery(event.target);
        setSearchParams(params);
    }

    const handleAskTraining = () => {

        Swal.fire({
            title: t("are_you_entreprise"),
            // text: t("only_entr_can_subscribe"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#151d41',
            cancelButtonColor: '#d33',
            confirmButtonText: t("yes"),
            cancelButtonText: t("no"),
          }).then((result) => {
            if (result.isConfirmed) {
                // @ts-ignore
                window.$('#exampleModalLong').modal('show')
            } else {
                Swal.fire({
                    title: "",
                    text: t("subscription_not_entreprise"),
                    icon: 'warning',
                    confirmButtonColor: '#151d41',
                    cancelButtonColor: '#d33',
                    confirmButtonText: "Ok",
                })
                // toast.success(t("obtain_estimation_success"), {
                //     position: toast.POSITION.TOP_RIGHT
                // });
            }
          })
    }

    useEffect(() => {
        // @ts-ignore
        setAllTrainings(trainings);
    }, [])

    useEffect(() => {

        // //console.log("search ======> ", params);
    }, [params]);

    return (
        <Page
            title={"OVA - Consulting | " + t('trainings')}
        >
            <Header/>
            <section className="training-page main homepage">
                <section className="banner" style={{backgroundImage: "url(/assets/expertises/training-1.png)"}}>
                    <div className="banner-content">
                        <div className="container">
                            <p className="title">{t('training_title')}</p>
                            <p className="text">{t('training_text')}</p>
                        </div>
                    </div>
                </section>
                <section className="categories">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-9">
                                <div>
                                    {
                                        trainingsCategories.map((cat, index) => {
                                            return(
                                                <span onClick={() => handleFilterByCategory(cat)} key={index} className={`cat ${category === cat ? 'active' : ''}`}>
                                                    {cat}
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-3">
                                <div className="d-flex">
                                    <div className="ml-auto">
                                        <Link to={"#"} onClick={handleAskTraining} className="btn btn-primary">
                                            {t("ask_for_a_training")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="trainings-content trainings">
                    <div className="container">
                        <div>
                            <p className="h2">
                                {t("let_know_our_trainings")}
                            </p>
                        </div>
                        <div className="row trainings-list">
                            <div className="col-lg-8 col-md-7 col-sm-7 col-12">
                                <div>
                                    <div className="row">
                                        {/* {
                                            trainings.map((train, index) => {

                                                return(

                                                    <div key={"training" + index} className="col-lg-6 col-sm-6 col-12">
                                                            <div className={`training-card ${window.location.href.includes(train.slug) ? "active" : ""}`}>
                                                                <table className="w-100">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="training-title">
                                                                                {
                                                                                    train.slug
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    t(train.duration)
                                                                                }
                                                                            </td>
                                                                            <td className="training-place">
                                                                                {
                                                                                    train.trainingPlaces.map(item => {
                                                                                        return (
                                                                                            <>
                                                                                                <span>
                                                                                                    {t(item)}
                                                                                                </span> <br />
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="training-category">
                                                                                {
                                                                                    train.category.map((cat, index) => {
                                                                                        return(
                                                                                            <span> {t(cat)} {index + 1 ==  train.category.length ? "" : " / "} </span>
                                                                                        )
                                                                                    })  
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                
                                                                            </td>
                                                                            <td className="training-price">
                                                                                
                                                                                <span>
                                                                                    {t("price-on-demand")}
                                                                                </span>
                                                                                
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="rating">
                                                                                    <StyledRating
                                                                                        color="#d3d3"
                                                                                        name="read-only"
                                                                                        value={4}
                                                                                        readOnly
                                                                                    />
                                                                                    <span> 4/5 </span>
                                                                                </div>
                                                                            </td>
                                                                            <td></td>
                                                                            <td className="text-right">
                                                                                <Link to={"/trainings/" + train.slug} className="btn btn-primary border-radius-0">
                                                                                    {t("read_more")}
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div className="training-bottom-branding">
                                                                    <div className="socials">
                                                                        <div className="linkedin">
                                                                            <Link to={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.protocol + "//" +window.location.host + "/trainings/" + train.slug}`}>
                                                                                <i className="fa fa-linkedin" aria-hidden="true"></i>
                                                                            </Link>
                                                                        </div>
                                                                        <div className="instagram">
                                                                            <Link to={`https://www.instagram.com/?url=${window.location.protocol + "//" +window.location.host + "/trainings/" + train.slug}`}>
                                                                                <i className="fa fa-instagram" aria-hidden="true"></i>
                                                                            </Link>
                                                                        </div>
                                                                        <div className="twitter">
                                                                            <Link to={`https://twitter.com/intent/tweet?url=${window.location.protocol + "//" +window.location.host + "/trainings/" + train.slug}`}>
                                                                                <i className="fa fa-twitter" aria-hidden="true"></i>
                                                                            </Link>
                                                                        </div>
                                                                        <div className="facebook">
                                                                            <a target={"_blank"} href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.protocol + "//" +window.location.host + "/trainings/" + train.slug}`}>
                                                                                <i className="fa fa-facebook" aria-hidden="true"></i>
                                                                            </Link>
                                                                        </div>
                                                                        <div className="behance">
                                                                            <Link to="#">
                                                                                <i className="fa fa-behance" aria-hidden="true"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </div>
                                                )
                                            })
                                        } */}
                                        {
                                            allTrainings.map(train => {

                                                return(
                                                    <div id={train.slug} key={train.slug} className="col-12">
                                                        <div style={{textDecoration: 'none'}}>

                                                            <div className={`training-card ${window.location.href.includes(train.slug) ? "active" : ""}`}>
                                                                
                                                                <table className="w-100">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td title={t(train.slug)} className="training-title">
                                                                                {
                                                                                    t(train.slug)
                                                                                }
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {
                                                                                    t(train.duration) 
                                                                                }
                                                                            </td>
                                                                            <td className="training-place">
                                                                                {
                                                                                    train.trainingPlaces.map(item => {
                                                                                        return (
                                                                                            <>
                                                                                                <span>
                                                                                                    {t(item)}
                                                                                                </span> <br />
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="training-category">
                                                                                {
                                                                                    train.category.map((cat, index) => {
                                                                                        return(
                                                                                            <span> {t(cat)} {index + 1 ===  train.category.length ? "" : " / "} </span>
                                                                                        )
                                                                                    })  
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                
                                                                            </td>
                                                                            <td className="training-price">
                                                                                <span>
                                                                                    {t("price-on-demand")}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{paddingTop: '1rem'}}>
                                                                                <div className="rating">
                                                                                    <StyledRating
                                                                                        color="#d3d3"
                                                                                        name="read-only"
                                                                                        value={4}
                                                                                        readOnly
                                                                                    />
                                                                                    <span> 4/5 </span>
                                                                                </div>
                                                                            </td>
                                                                            <td></td>
                                                                            <td  style={{paddingTop: '1rem'}} className="text-right">
                                                                                <Link to={"/trainings/" + train.slug} className="btn btn-primary">
                                                                                    {t("read_more")}
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        } 

                                        <div  className="col-12">
                                            <div onClick={handleAskTraining} style={{textDecoration: 'none'}}>

                                                <div className={`training-card bg-primary`}>
                                                    <p className="text-center mb-0 text-white"> {t("ask_for_a_training")} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5 col-sm-5 col-12">
                                <div>
                                    <div className="training-filters">
                                        <div>
                                            {/* <form action="?sear"> */}
                                            <form onSubmit={handleSFilter}>
                                                
                                                <p className="filter-label">
                                                    {t("categories")}
                                                </p>
                                                <select id="category" {...register("category")}>
                                                    {
                                                        trainingsCategories.map((cat, index) => {

                                                            return (
                                                                <option key={"trainingFilterCat" + index} value={cat}> {cat} </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <p> </p>
                                                <label htmlFor="fne" className="filter-label">
                                                    {t("FNE")}
                                                </label>
                                                <div className="form-check">
                                                    <input className="form-check-input" id="fne" value={"-125000"} type="radio" {...register("fne")} />
                                                    <label className="form-check-label" htmlFor="fne">
                                                        {'< 125.000 XAF HT'}
                                                    </label>
                                                </div>
                                                <p> </p>
                                                <label htmlFor="fne" className="filter-label">
                                                    {t("status")}
                                                </label>
                                                <div className="form-check">
                                                    <input className="form-check-input" id="status" value={"new"} type="radio" {...register("status")} />
                                                    <label className="form-check-label" htmlFor="status">
                                                        {'NEW'}
                                                    </label>
                                                </div>
                                                <p> </p>
                                                <label htmlFor="fne" className="filter-label">
                                                    {t("Level")}
                                                </label>
                                                <div className="form-check">
                                                    <input className="form-check-input" id="level_init" type="radio" value={"initiations ans fundamentals"} name="level" />
                                                    <label className="form-check-label" htmlFor="level_init">
                                                        {t("Initiations / Fundamentals")}
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" id="level_advanced" value="advanced" type="radio" name="level" />
                                                    <label className="form-check-label" htmlFor="level_advanced">
                                                        {t('Perfectionnement / Advanced')}
                                                    </label>
                                                </div>
                                                <p> </p>
                                                <label htmlFor="fne" className="filter-label">
                                                    {t("Modalities")}
                                                </label>
                                                <div className="form-check">
                                                    <input className="form-check-input" id="modalitie_online" name="modalitie_online" type="radio" value={"online"} />
                                                    <label className="form-check-label" htmlFor="modalities-online">
                                                        {t("online")}
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" id="modalitiesFaceToFace" name="modalitie_online" type="radio" value={"face-to-face"} />
                                                    <label className="form-check-label" htmlFor="modalitiesFaceToFace">
                                                        {t('face-to-face')}
                                                    </label>
                                                </div>
                                                <p> </p>
                                                <label htmlFor="fne" className="filter-label">
                                                    {t("Certifications")}
                                                </label>
                                                <div className="form-check">
                                                    <input className="form-check-input" id="certifying" type="radio" value={"certifying"} name="type" />
                                                    <label className="form-check-label" htmlFor="certifying">
                                                        {t("certif_trainings")}
                                                    </label>
                                                </div>

                                                <button type="submit" className="mt-2 btn btn-secondary ">
                                                    {t("filter")}
                                                </button>
                                                
                                            </form>
                                        </div>
                                        <Link to={"#"} onClick={handleAskTraining} className="btn btn-secondary mt-3">
                                            {t("ask_for_a_training")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModalLong" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form onSubmit={handleSubmit(handleSubmitForm)}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">{t("custom_training_enter_your_informations")}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="inscription-form text-align-left">
                                    <div>
                                            
                                        <div className="form-group">
                                            <label htmlFor="title">{t("home_contact_training_title") + " "}<span className="text-danger"> *</span></label>
                                            <input type="text" className="form-control border-radius-0" {...register('title', {required: true})} id="title" placeholder={t("home_contact_training_title") + " *"}/>
                                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="society">{t("form_society") + " "}<span className="text-danger"> *</span></label>
                                            <input type="text" className="form-control border-radius-0" {...register('society', {required: true})} id="society" placeholder={t("form_society") + " *"}/>
                                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="firstName">{t("home_contact_name") + " "}<span className="text-danger"> *</span></label>
                                            <input type="text" className="form-control border-radius-0" {...register('firstName', {required: true})} id="firstName" placeholder={t("home_contact_name") + " *"}/>
                                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="lastName">{t("form_last_name") + " "}<span className="text-danger"> *</span></label>
                                            <input type="text" className="form-control border-radius-0" {...register('lastName', {required: true})} id="lastName" placeholder={t("form_last_name") + " *"}/>
                                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="title">{t("starting_date") + " "}<span className="text-danger"> *</span></label>
                                            <input type="date" className="form-control border-radius-0" {...register('starting_date', {required: true})} id="startingDate" placeholder={t("starting_date") + " *"}/>
                                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="endingDate">{t("ending_date") + " "}<span className="text-danger"> *</span></label>
                                            <input type="date" className="form-control border-radius-0" {...register('ending_date', {required: true})} id="endingDate" placeholder={t("ending_date") + " *"}/>
                                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="role">{t("form_role") + " "}<span className="text-danger"> *</span></label>
                                            <input type="text" className="form-control border-radius-0" {...register('role', {required: true})} id="role" placeholder={t("form_role") + " *"}/>
                                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="phone">{t("phone") + " "}<span className="text-danger"> *</span></label>
                                            <input type="text" className="form-control border-radius-0" {...register('phone', {required: true})} id="phone" placeholder={t("home_contact_phone") + " *"}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="postalCode">{t("form_postal_code") + " "}</label>
                                            <input type="text" className="form-control border-radius-0" {...register('postalCode', {required: false})} id="postalCode" placeholder={t("form_postal_code")}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">{t("home_contact_email") + " "}<span className="text-danger"> *</span></label>
                                            <input type="email" className="form-control border-radius-0" {...register('email', {required: true})} id="email" placeholder={t("home_contact_email") + " *"}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="number">{t("form_inscriptions_number") + " "}<span className="text-danger"> *</span></label>
                                            <input type="number" className="form-control border-radius-0" {...register('number', {required: false})} id="number" placeholder={t("form_inscriptions_number")}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="complement">{t("form_compl_info") + " "}<span className="text-danger"> *</span></label>
                                            <textarea className="form-control border-radius-0" {...register('complement')} id="complement" placeholder={t("form_compl_info")}/>
                                        </div>
                                        {/* <div className="form-check text-left">
                                            <input type="checkbox" className="form-check-input border-radius-0" {...register('interested', {required: false})} id="interested" placeholder={t("form_interested")}/>
                                            <label htmlFor="interested" className="form-check-label">{t("form_interested")}</label>
                                        </div> */}
                                        <div className="form-check mb-2 text-left">
                                            <input type="checkbox" className="form-check-input border-radius-0" {...register('acceptMentions', {required: false})} id="acceptMentions" placeholder={t("form_accept")}/>
                                            <label htmlFor="acceptMentions" className="form-check-label">{t("form_accept")}</label>
                                        </div>

                                        {/* <div className="mb-3">
                                            {
                                                loading ?
                                                <button disabled className="btn btn-primary border-radius-0">
                                                    {t("please_wait")}
                                                </button>
                                                :
                                                <button className="btn btn-primary border-radius-0">
                                                    {t("i_subscribe")}
                                                </button>
                                            }
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="">

                                    <button type="button" className="btn btn-secondary border-radius-0 mt-0" data-dismiss="modal">{t("close")}</button>
                                </div>
                                <div className="">
                                    {
                                        loading ?
                                        <button disabled className="btn btn-primary border-radius-0">
                                            {t("obtain_estimation")}
                                        </button>
                                        :
                                        <button type="submit" className="btn btn-primary border-radius-0">
                                            {t("obtain_estimation")}
                                        </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer/>
        </Page>
    )
}


export default TrainingsPage;