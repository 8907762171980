import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RECRUITMENTAPI } from "../../api/recruitment";
import Footer from "../../components/footer/Footer";
import Header3 from "../../components/header/Header3";
import ModalText from "../../components/modalText/ModalText";
import { redirect } from "../../components/utils/utils";
import Ovaccordion from "../../components/accordion/Accordion";
import Page from "../../page/Page";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../stores/types";

interface Job {
    job_title: string,
    job_title_en: string,
    job_title_fr: string,
    job_type: string,
    job_description_en: string,
    job_description_fr: string,
    job_description: string,
    job_duration: string,
    id: string,
    file: string
}


export default function () {

    const { t } = useTranslation();

    const [ job, setJob ] = useState<Job | null>(null);
    const [ jobs, setJobs ] = useState<Job[]>([]);

    const [ loading, setLoading ] = useState<Boolean>(true)
    const [ alert, setAlert ] = useState<string | null>(null)
    const [ message, setMessage ] = useState<string | null>(null)

    const {locale} = useSelector((state: ApplicationState) => state.i18n);


    useEffect(() => {
        setLoading(true);
 
        RECRUITMENTAPI.get("jobs/").then((resp) => {
            setLoading(false);
            setJobs(resp.data.results);
        }).catch((err) => {
            setAlert(t("fetching_jobs_error"));
        })
    }, [])

    return(
        <Page
            title={"OVA - Consulting | " + t('recruitement_text')}
            description={t("recruitement_our_profiles")}
        >
            <div>
                <div className="main recruitment">
                    <Header3/>
                    {/* <!-- PAGE START /--> */}
                    <section className="bg-cover" style={{backgroundImage: "url(assets/img/background-recruitment.png)"}}>
                        <div className="main-content container">

                            {/* <!-- SECTION START /--> */}
                            <section className="recruitment">
                                <section className="rec bg-cover">
                                    <div className="container">
                                    <div className="title  text-align-left">
                                        {t("recruitement_text")}
                                    </div>
                                    <div className="subtitle  text-align-left">
                                        {t("recruitement_our_profiles")}
                                    </div>
                                    {jobs && <div className="profiles-container swiper-container" style={{overflow: 'visible'}}>
                                        <div className="profiles-wrapper swiper-wrapper">
                                            <div id="carouselExampleIndicators" style={{overflow: 'visible'}} className="carousel slide" data-ride="carousel">
                                                
                                                <div className="carousel-inner"  style={{overflow: 'visible'}}>
                                                    <div className="carousel-item active">
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                                    <div>
                                                                        <div className="profiles-slide swiper-slide">
                                                                            <div className="title text-align-left">CDI</div>
                                                                            
                                                                            <div className="profilues">
                                                                                {
                                                                                    jobs.map((job, index) => {

                                                                                        if(job.job_type === "CDI" || job.job_type === "CDD"){

                                                                                            return(
                                                                                                <Ovaccordion 
                                                                                                    key={job.id}
                                                                                                    id={"accordion" + job.id} 
                                                                                                    title={locale === "en" ? job.job_title_en : job.job_title_fr} 
                                                                                                    description={locale === "en" ? job.job_description_en : job.job_description_fr} 
                                                                                                    image={job.file ?? "assets/svg/graphic-design.svg"} 
                                                                                                    type={t(job.job_type)}
                                                                                                    detailLink={`/recruitment/${job.id}/details`}
                                                                                                    applyText={t("job_postulate_button")}
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                                    <div>
                                                                        <div className="profiles-slide swiper-slide">
                                                                            <div className="title  text-align-left">Stage</div>
                                                                                <div className="proficles">
                                                                                    {
                                                                                        jobs.map((job, index) => {
                                                                                            // @ts-ignore

                                                                                            if(job.job_type !== "CDI" && job.job_type !== "CDD"){

                                                                                                return(
                                                                                                    <Ovaccordion 
                                                                                                        key={job.id}
                                                                                                        id={"accordion" + job.id} 
                                                                                                        title={locale === "en" ? job.job_title_en : job.job_title_fr} 
                                                                                                        description={locale === "en" ? job.job_description_en : job.job_description_fr}
                                                                                                        image={job.file ?? "assets/svg/graphic-design.svg"} 
                                                                                                        type={t(job.job_type)}
                                                                                                        detailLink={`/recruitment/${job.id}/details`}
                                                                                                        applyText={t("job_postulate_button")}
                                                                                                    />
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="swiper-commands">
                                                    <div className="swiper-controls">
                                                        <Link className="carousel-control-prev" to="#carouselExampleIndicators" role="button" data-slide="prev">
                                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            <span className="sr-only">Previous</span>
                                                        </Link>
                                                        <div>1</div>
                                                        <Link className="carousel-control-next" to="#carouselExampleIndicators" role="button" data-slide="next">
                                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                            <span className="sr-only">Next</span>
                                                        </Link>
                                                        {/* <div className="swiper-previous"></div>
                                                        <div className="swiper-counter1">1</div>
                                                        <div className="swiper-next"></div> */}
                                                    </div>
                                                    <div className="c-controls">
                                                        <ol className="carousel-indicators">
                                                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                                            {/* <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> */}
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>}
                                    </div>
                                </section>
                            </section>
                            {/* <!-- SECTION END /--> */}
                            <ModalText 
                                id={"preview-text"}
                                title={t(job?.job_title || "") + ' ' + job?.job_duration}
                                description={t(job?.job_description || "")}
                                nextButtonText={t('send_canditature')}
                                callBack={redirect}
                            />
                        </div>
                    </section>
                </div>
                <Footer/>
            </div>
        </Page>
    )
}