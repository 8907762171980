import { useTranslation } from 'react-i18next';
// Import Swiper React components
import { Navigation, Pagination, Autoplay, Mousewheel, Keyboard } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  ref: any;
}

const calculatePagesCount = (pageSize: number, totalCount: number) => {
  // we suppose that if we have 0 items we want 1 empty page
  return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
};

const calculateSlidePage = (activeIdex: number, totalCount: number) => {
  // we suppose that if we have 0 items we want 1 empty page
  return totalCount < activeIdex
    ? 1
    : activeIdex === 0
    ? 1
    : Math.ceil(totalCount / activeIdex);
};

export default function ServicesProducts() {
  const { t } = useTranslation();
  const [activeSlide, setActiveSlide] = useState(1);
  const [swiperRef, setSwiperRef] = useState(null);

  const handleChangeSwiper = (swiper: any) => {
    // //console.log("swiper ===>", swiper.activeIndex)

    const activeIndex = swiper.activeIndex;
    const allSlides = document.querySelectorAll('.swiper-slide-custom-slide');

    if (allSlides.length > 0) {
      const activeElement = allSlides[activeIndex];
      const dataSlide = activeElement.getAttribute('data-slide');

      const rightSlidesCards = document.querySelectorAll('[data-bs-slide]');
      // Remove all actives cards
      const activeRightSlideCard = document.querySelector(
        '.card-slider.active'
      );
      if (activeRightSlideCard) {
        activeRightSlideCard.classList.remove('active');
      }
      if (rightSlidesCards.length > 0) {
        const targetRightSlideCard = rightSlidesCards[activeIndex];
        targetRightSlideCard.classList.add('active');
        // //console.log("Swiper changed.....", dataSlide, targetRightSlideCard);
      }

      /* Te goal here will be to calculate the number of pages of the right caroussel,
      so that every time the left slide change, we could know which card to target, and also the caroussel slide containing the card
      It is important here not to forget thatevery caroussel contains 4 cards
      */

      if (rightSlidesCards.length > 0) {
        const pages = rightSlidesCards.length;
        const numberOfCarousselPages = calculatePagesCount(4, pages);
        const slidePage = calculateSlidePage(4, activeIndex + 1);
        // //console.log("slidePage ======> ",  slidePage);
        // @ts-ignore
        window.$('.carousel').carousel(slidePage - 1);
        // @ts-ignore
        window.$('.carousel').carousel('pause');
        // console.log("slidePage ========> ", slidePage);
        if (slidePage >= 0 && slidePage <= 2) {
          // @ts-ignore
          window.$('#service-active').addClass('service-active');
          // @ts-ignore
          window.$('#product-active').removeClass('product-active');
        } else {
          // @ts-ignore
          window.$('#service-active').removeClass('service-active');
          // @ts-ignore
          window.$('#product-active').addClass('product-active');
        }
      }
    }

    setActiveSlide(activeIndex + 1);
  };

  const handleInitialiseSlides = (index: number) => {
    /* The goal here is to get the starting index of products and services, and
        the corresponding slide pagination indicator and then click it
    */

    if (swiperRef) {
      // @ts-ignore
      swiperRef?.slideTo(index, 0);
      const swiperObj = {
        activeIndex: index
      };
      handleChangeSwiper(swiperObj);
    }

    setActiveSlide(index + 1);
  };

  return (
    <section className="services mb-0" id="services">
      <div className="services-container">
        <div className="services-slider swiper-container">
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            onSlideChange={(swiper) => handleChangeSwiper(swiper)}
            // @ts-ignore
            onSwiper={setSwiperRef}
            modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false
            }}
            pagination={{
              clickable: true,
              enabled: true,
              el: '.swiper-pagination1'
            }}
            navigation={{
              nextEl: '.swiper-next1',
              prevEl: '.swiper-previous1'
            }}
            mousewheel={true}
            keyboard={true}
            style={{ height: '100%', zIndex: 0 }}
            className="mySwiper">
            <div className="swiper-controls">
              <div className="swiper-previous swiper-previous1"></div>
              <div className="swiper-counter">{activeSlide}</div>
              <div className="swiper-next swiper-next1"></div>
            </div>
            <div className="swiper-pagination1"></div>
            <SwiperSlide
              className="swiper-slide-custom-slide"
              style={{
                backgroundImage: 'url(assets/img/services/conseils.png',
                backgroundSize: 'contain'
              }}
              data-slide="website">
              <div className="eye">
                <Link to="#"></Link>
              </div>
              <div className="home-slider-content">
                <div className="title">{t('home_services_box_title_1')}</div>
                <Link className="text-link" to="/services-produits#advice">
                  {t('home_know_more')}
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="swiper-slide-custom-slide"
              style={{
                backgroundImage:
                  'url(assets/img/services/project-management.png)',
                backgroundSize: 'contain'
              }}
              data-slide="pwa">
              <div className="eye">
                <Link to="#"></Link>
              </div>
              <div className="home-slider-content">
                <div className="title">{t('home_services_box_title_2')}</div>
                <Link
                  className="text-link"
                  to="/services-produits#projects-drive">
                  {t('home_know_more')}
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="swiper-slide-custom-slide"
              style={{
                backgroundImage:
                  'url(assets/img/services/bussiness-intelligence.png)'
              }}
              data-slide="com-tools">
              <div className="eye">
                <Link to="#"></Link>
              </div>
              <div className="home-slider-content">
                <div className="title">{t('home_services_box_title_3')}</div>
                <Link className="text-link" to="/services-produits#business">
                  {t('home_know_more')}
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="swiper-slide-custom-slide"
              style={{ backgroundImage: 'url(assets/services/ingenierie.png)' }}
              data-slide="saas">
              <div className="eye">
                <Link to="#"></Link>
              </div>
              <div className="home-slider-content">
                <div className="title">{t('home_services_box_title_4')}</div>
                <Link className="text-link" to="/services-produits#engineering">
                  {t('home_know_more')}
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="swiper-slide-custom-slide"
              style={{ backgroundImage: 'url(assets/services/api.png)' }}
              data-slide="api">
              <div className="eye">
                <Link to="#"></Link>
              </div>
              <div className="home-slider-content">
                <div className="title">{t('home_services_box_title_5')}</div>
                <Link
                  className="text-link"
                  to="/services-produits#api-management">
                  {t('home_know_more')}
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="swiper-slide-custom-slide"
              style={{ backgroundImage: 'url(assets/services/cloud.png)' }}
              data-slide="cloud">
              <div className="eye">
                <Link to="#"></Link>
              </div>
              <div className="home-slider-content">
                <div className="title">{t('home_services_box_title_6')}</div>
                <Link className="text-link" to="/services-produits#cloud">
                  {t('home_know_more')}
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="swiper-slide-custom-slide"
              style={{
                backgroundImage: 'url(assets/img/services/training.png)',
                backgroundSize: 'contain'
              }}
              data-slide="training">
              <div className="eye">
                <Link to="#"></Link>
              </div>
              <div className="home-slider-content">
                <div className="title">{t('home_services_box_title_7')}</div>
                <Link className="text-link" to="/services-produits#training">
                  {t('home_know_more')}
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="swiper-slide-custom-slide"
              style={{
                backgroundImage: 'url(assets/img/services/assistance.png)',
                backgroundSize: 'contain'
              }}
              data-slide="support">
              <div className="eye">
                <Link to="#"></Link>
              </div>
              <div className="home-slider-content">
                <div className="title">{t('home_services_box_title_8')}</div>
                <Link className="text-link" to="/services-produits#support">
                  {t('home_know_more')}
                </Link>
              </div>
            </SwiperSlide>
            {/* Products */}
            <SwiperSlide
              className="swiper-slide-custom-slide"
              style={{
                backgroundImage: 'url(assets/img/products/tchoko.jpeg)',
                backgroundColor: 'rgb(21, 29, 65)',
                backgroundSize: '60%'
              }}
              data-slide="tchoko">
              <div className="eye">
                <Link to="#"></Link>
              </div>
              <div className="home-slider-content">
                <div className="title">{t('OVAPAY')}</div>
                <Link className="text-link" to="/services-produits#tchoko">
                  {t('home_know_more')}
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="swiper-slide-custom-slide"
              style={{
                backgroundImage: 'url(assets/img/products/ovassure-home.png)',
                backgroundColor: 'rgb(21, 29, 65)',
                backgroundSize: '60%'
              }}
              data-slide="ovassure">
              <div className="eye">
                <Link to="#"></Link>
              </div>
              <div className="home-slider-content">
                <div className="title">{t('OVASSURE')}</div>
                <Link className="text-link" to="/services-produits#ovassure">
                  {t('home_know_more')}
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="swiper-slide-custom-slide"
              style={{
                backgroundImage: 'url(assets/img/products/ovaged-home.png)',
                backgroundColor: 'rgb(21, 29, 65)',
                backgroundSize: 'contain'
              }}
              data-slide="ovaged">
              <div className="eye">
                <Link to="#"></Link>
              </div>
              <div className="home-slider-content">
                <div className="title">{t('DEMATE')}</div>
                <Link className="text-link" to="/services-produits#ovaged">
                  {t('home_know_more')}
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="swiper-slide-custom-slide"
              style={{
                backgroundImage: 'url(assets/img/products/ovacom-home.png)',
                backgroundColor: 'rgb(21, 29, 65)',
                backgroundSize: 'contain'
              }}
              data-slide="ovacom">
              <div className="eye">
                <Link to="#"></Link>
              </div>
              <div className="home-slider-content">
                <div className="title">{t('OVACOM')}</div>
                <Link className="text-link" to="/services-produits#ovacom">
                  {t('home_know_more')}
                </Link>
              </div>
            </SwiperSlide>
            {/* <div className="swiper-pagination1"/> */}
          </Swiper>
        </div>
        <div className="services-cards">
          <div className="title cursor-pointer">
            {/* Here we have to pass services slides first index in parameters */}
            <span
              onClick={() => handleInitialiseSlides(0)}
              id="service-active"
              className="service-active">
              {t('SERVICES')}
            </span>{' '}
            <span className="vertical-line" />{' '}
            {/* Here we have to pass products slides first index in parameters */}
            <span
              onClick={() => handleInitialiseSlides(8)}
              id="product-active"
              className="product-active">
              {t('PRODUCTS')}
            </span>
          </div>
          <div className="excerpt">{t('home_services_descr')}</div>
          <div className="cards">
            <div
              style={{ minHeight: '500px' }}
              id="carouselServiceControls"
              className="carousel slide"
              data-ride="carousel"
              data-interval="false">
              <ol className="carousel-indicators">
                <li
                  onClick={() => handleInitialiseSlides(0)}
                  data-target="#carouselServiceControls"
                  style={{ background: '#0092893d' }}
                  data-slide-to="0"
                  className="active"></li>
                <li
                  onClick={() => handleInitialiseSlides(4)}
                  data-target="#carouselServiceControls"
                  style={{ background: '#0092893d' }}
                  data-slide-to="1"></li>
                <li
                  onClick={() => handleInitialiseSlides(8)}
                  data-target="#carouselServiceControls"
                  style={{ background: '#0092893d' }}
                  data-slide-to="2"></li>
              </ol>
              <div className="carousel-inner">
                <div id="carousel-item-1" className="carousel-item active">
                  <div className="cards" style={{}}>
                    <Link
                      to="/services-produits#advice"
                      className="card unstyled-link card-slider active"
                      data-bs-slide="website">
                      <div className="icon"></div>
                      <div className="card-title">
                        <img src="assets/svg/conseils-strategies.svg" alt="" />
                        <div style={{ height: '1rem' }} />
                        {t('home_services_box_title_1')}
                      </div>
                      <div className="excerpt lines-5-description">
                        {t('home_services_box_text_1')}
                      </div>
                    </Link>
                    <Link
                      to="/services-produits#projects-drive"
                      className="card unstyled-link card-slider"
                      data-bs-slide="pwa">
                      <div className="icon"></div>
                      <div className="card-title">
                        <img src="assets/svg/conduite-gestion.svg" alt="" />
                        <div style={{ height: '1rem' }} />
                        {t('home_services_box_title_2')}
                      </div>
                      <div className="excerpt lines-5-description">
                        {t('home_services_box_text_2')}
                      </div>
                    </Link>
                    <Link
                      to="/services-produits#business"
                      className="card unstyled-link card-slider"
                      data-bs-slide="com-tools">
                      <div className="icon"></div>
                      <div className="card-title">
                        <img src="assets/svg/buss-int.svg" alt="" />
                        <div style={{ height: '1rem' }} />
                        {t('home_services_box_title_3')}
                      </div>
                      <div className="excerpt lines-5-description">
                        {t('home_services_box_text_3')}
                      </div>
                    </Link>
                    <Link
                      to="/services-produits#engineering"
                      className="card unstyled-link card-slider"
                      data-bs-slide="saas">
                      <div className="icon"></div>
                      <div className="card-title">
                        <img src="assets/svg/ing-log.svg" alt="" />
                        <div style={{ height: '1rem' }} />
                        {t('home_services_box_title_4')}
                      </div>
                      <div className="excerpt lines-5-description">
                        {t('home_services_box_text_4')}
                      </div>
                    </Link>
                  </div>
                </div>
                <div id="carousel-item-2" className="carousel-item">
                  <div className="cards" style={{}}>
                    {/* Second bloc to slide. */}
                    <Link
                      to="/services-produits#api-management"
                      className="card unstyled-link card-slider"
                      data-bs-slide="api">
                      <div className="icon"></div>
                      <div className="card-title">
                        <img src="assets/svg/api-manage.svg" alt="" />
                        <div style={{ height: '1rem' }} />
                        {t('home_services_box_title_5')}
                      </div>
                      <div className="excerpt lines-5-description">
                        {t('home_services_box_text_5')}
                      </div>
                    </Link>
                    <Link
                      to="/services-produits#cloud"
                      className="card unstyled-link card-slider"
                      data-bs-slide="cloud">
                      <div className="icon"></div>
                      <div className="card-title">
                        <img src="assets/svg/cloud.svg" alt="" />
                        <div style={{ height: '1rem' }} />
                        {t('home_services_box_title_6')}
                      </div>
                      <div className="excerpt lines-5-description  ">
                        {t('home_services_box_text_6')}
                      </div>
                    </Link>
                    <Link
                      to="/services-produits#training"
                      className="card unstyled-link card-slider"
                      data-bs-slide="training">
                      <div className="icon"></div>
                      <div className="card-title">
                        <img src="assets/svg/training.svg" alt="" />
                        <div style={{ height: '1rem' }} />
                        {t('home_services_box_title_7')}
                      </div>
                      <div className="excerpt lines-5-description">
                        {t('home_services_box_text_7')}
                      </div>
                    </Link>
                    <Link
                      to="/services-produits#support"
                      className="card unstyled-link card-slider"
                      data-bs-slide="support">
                      <div className="icon"></div>
                      <div className="card-title">
                        <img src="assets/svg/assistance.svg" alt="" />
                        <div style={{ height: '1rem' }} />
                        {t('home_services_box_title_8')}
                      </div>
                      <div className="excerpt lines-5-description">
                        {t('home_services_box_text_8')}
                      </div>
                    </Link>
                  </div>
                </div>
                {/* products l=slides */}
                <div id="carousel-item-3" className="carousel-item">
                  <div className="cards" style={{}}>
                    {/* Second bloc to slide. */}
                    <Link
                      to="/services-produits#tchoko"
                      className="card unstyled-link card-slider"
                      data-bs-slide="tchoko">
                      <div className="icon"></div>
                      <div className="card-title">
                        <img
                          src="assets/img/tchoko.png"
                          height={'50px'}
                          alt=""
                        />
                        <div style={{ height: '1rem' }} />
                        {t('OVAPAY')}
                      </div>
                      <div className="excerpt lines-5-description">
                        {t('tchoko_description')}
                      </div>
                    </Link>
                    <Link
                      to="/services-produits#ovassure"
                      className="card unstyled-link card-slider"
                      data-bs-slide="ovassure">
                      <div className="icon"></div>
                      <div className="card-title">
                        <img
                          src="assets/img/ovassure.jpg"
                          height={'50px'}
                          alt=""
                        />
                        <div style={{ height: '1rem' }} />
                        {t('OVASSURE')}
                      </div>
                      <div className="excerpt lines-5-description">
                        {t('ovassure_description')}
                      </div>
                    </Link>
                    {/* hidden cards to not have issues with animations */}
                    <Link
                      to="/services-produits#ovaged"
                      className="card unstyled-link card-slider"
                      data-bs-slide="ovaged">
                      <div className="icon"></div>
                      <div className="card-title">
                        <img
                          src="assets/img/logo_demate.jpeg"
                          height={'50px'}
                          alt=""
                        />
                        <div style={{ height: '1rem' }} />
                        {t('DEMATE')}
                      </div>
                      <div className="excerpt">
                        {t('home_product_ovaged_descr')}
                      </div>
                    </Link>
                    <Link
                      to="/services-produits#ovacom"
                      className="card unstyled-link card-slider"
                      data-bs-slide="ovacom">
                      <div className="icon"></div>
                      <div className="card-title">
                        <img
                          src="assets/img/products/ovacom-logo.png"
                          height={'50px'}
                          alt=""
                        />
                        <div style={{ height: '1rem' }} />
                        {t('OVACOM')}
                      </div>
                      <div className="excerpt">
                        {t('home_product_ovacom_descr')}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <Link
                className="carousel-control-prev"
                to="#carouselServiceControls"
                role="button"
                data-slide="prev">
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </Link>
              <Link
                className="carousel-control-next"
                to="#carouselServiceControls"
                role="button"
                data-slide="next">
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
