



export function openVkModal () {


    let vkModals = document.getElementsByClassName('vk-modal-overlay');
    let vkModal = vkModals[0];

    vkModal.className = "vk-modal-overlay is-open";
    vkModal.setAttribute('style', 'transition: all 300ms ease 0s; opacity: 1;');

}