import { Methodology } from "./timelines"
import EngineeringIcon from '@mui/icons-material/Engineering';
import HearingIcon from '@mui/icons-material/Hearing';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';


export interface ExpertiseTechnologyArray {
    image: string,
    id: string,
    name: string,
    type: string, //Language or framework
}

export interface ExpertiseTechnology {
    type?: string,
    contents: ExpertiseTechnologyArray[]
}

export interface ExpertiseSoftwareTechnology {
    type: string,
    contents: ExpertiseTechnologyArray[]
}

export interface ExpertiseClient {
    image: string,
    id: string,
    name: string,
}

export interface ExpertiseProject {
    id: string,
    image: string,
    name: string,
    description: string,
    link: string
}

export interface Project {
    name: string,
    image: string,
    link: string,
    description: string,
}

export interface Expertise {
    slug: string,
    title: string,
    description: string,
    expertises: ExpertiseTechnology[],
    projects: Project[],
    methodologies?: Methodology[]
}


export const expertises: Expertise[] = [
    {
        title: "Design",
        description: "expertise_design_text",
        slug: "design",
        expertises: [
            {
                contents: [
                    {
                        id: '1',
                        name: "Figma",
                        type: "design_tool",
                        image: "/assets/expertises/Figma-logo.svg"
                    },
                    {
                        id: '2',
                        name: "Sketch",
                        type: "design_tool",
                        image: "/assets/expertises/1133px-Sketch_Logo.svg.png"
                    },
                    {
                        id: '3',
                        name: "Photoshop",
                        type: "design_tool",
                        image: "/assets/expertises/Adobe_Photoshop_CC_icon.svg.png"
                    }
                ]
            },
            {
                contents: [
                    {
                        id: '4',
                        name: "Adobe illustrator",
                        type: "design_tool",
                        image: "/assets/expertises/adobe-illustrator-logo.png"
                    }
                ]
            } 
        ],
        projects: [
            {
                "name": "ESS",
                "image": "/assets/img/ess.png",
                "description": "ess_project_description",
                "link": "https://emraldsecuritiesservices.com/",
            },
            {
                "name": "Solar Hut",
                "image": "/assets/img/solar-home.png",
                "description": "solar_project_description",
                "link": "http://www.solarhut.energy/",
            }
        ],
        methodologies: [
            {
              title: "expertise_design_understand",
              text: "expertise_design_understand_text",
              icon: HearingIcon,
            },
            {
                title: "expertise_design_prototype",
                text: "expertise_design_prototype_text",
                icon: TextRotationNoneIcon,
            },
            {
                title: "expertise_design_design_solution",
                text: "expertise_design_design_solution_text",
                icon: DesignServicesIcon,
            }
        ]
    },
    {
        title: "Design",
        description: "expertise_software_engineering_text",
        slug: "software-engineering",
        expertises: [
            {
                contents: [
                    {
                        id: '1',
                        name: "Python",
                        type: "Language",
                        image: "/assets/stacks/python.png"
                    },
                    {
                        id: '2',
                        name: "Typescript",
                        type: "Language",
                        image: "/assets/stacks/typescript.png"
                    },
                    {
                        id: '3',
                        name: "Java",
                        type: "Language",
                        image: "/assets/stacks/1200px-Java_Logo.svg.png"
                    }
                ]
            },
            {
                contents: [
                    {
                        id: '4',
                        name: "Dart",
                        type: "Language",
                        image: "/assets/stacks/dart.png"
                    },
                    {
                        id: '5',
                        name: "Javascript",
                        type: "Language",
                        image: "/assets/stacks/Unofficial_JavaScript_logo_2.svg.png"
                    },
                    {
                        id: '6',
                        name: "PHP",
                        type: "Language",
                        image: "/assets/stacks/PHP-logo.svg.png"
                    }
                ]
            }
            
        ],
        projects: [
            {
                "name": "ESS",
                "image": "/assets/img/ess.png",
                "description": "ess_project_description",
                "link": "https://emraldsecuritiesservices.com/",
            },
            {
                "name": "Korione",
                "image": "/assets/img/korione.png",
                "description": "korione_project_description",
                "link": "https://korione.com/",
            }
        ]
    }
]

export const softwareXpertise = {
    title: "Design",
    description: "expertise_software_engineering_text",
    slug: "software-engineering",
    expertises: [
        {
            type: "language",
            expertises:
            [
                {
                    contents: [
                        {
                            id: '1',
                            name: "Python",
                            type: "Language",
                            image: "/assets/stacks/python.png"
                        },
                        {
                            id: '2',
                            name: "Typescript",
                            type: "Language",
                            image: "/assets/stacks/typescript.png"
                        },
                        {
                            id: '3',
                            name: "Java",
                            type: "Language",
                            image: "/assets/stacks/1200px-Java_Logo.svg.png"
                        }
                    ]
                },
                {
                    contents: [
                        {
                            id: '4',
                            name: "Dart",
                            type: "Language",
                            image: "/assets/stacks/dart.png"
                        },
                        {
                            id: '5',
                            name: "Javascript",
                            type: "Language",
                            image: "/assets/stacks/Unofficial_JavaScript_logo_2.svg.png"
                        },
                        {
                            id: '6',
                            name: "PHP",
                            type: "Language",
                            image: "/assets/stacks/PHP-logo.svg.png"
                        }
                    ]
                }
                
            ]
        },
        {
            type: "frontend",
            expertises:
            [
                {
                    contents: [
                        {
                            id: '1',
                            name: "React",
                            type: "Front-end",
                            image: "/assets/stacks/react.png"
                        },
                        {
                            id: '2',
                            name: "Next JS",
                            type: "Front-end",
                            image: "/assets/stacks/nextjs.png"
                        },
                        {
                            id: '3',
                            name: "Vue JS",
                            type: "Front-end",
                            image: "/assets/stacks/Vue.png"
                        }
                    ]
                },
                {
                    contents: [
                        {
                            id: '4',
                            name: "Nuxt js",
                            type: "Front-end",
                            image: "/assets/stacks/nuxt.png"
                        },
                        {
                            id: '5',
                            name: "Angular js",
                            type: "Front-end",
                            image: "/assets/expertises/angular-js.png"
                        }
                    ]
                }
                
            ]
        },
        {
            type: "backend",
            expertises:
            [
                {
                    contents: [
                        {
                            id: '1',
                            name: "NodeJS",
                            type: "Backend-end",
                            image: "/assets/stacks/nodejs.png"
                        },
                        {
                            id: '2',
                            name: "NestJS",
                            type: "Back-end",
                            image: "/assets/stacks/nest-js.png"
                        },
                        {
                            id: '3',
                            name: "Django",
                            type: "Back-end",
                            image: "/assets/stacks/django-logo.png"
                        }
                    ]
                },
                {
                    contents: [
                        {
                            id: '4',
                            name: "Flask",
                            type: "Back-end",
                            image: "/assets/stacks/flask.png"
                        },
                        {
                            id: '5',
                            name: "Symfony",
                            type: "Back-end",
                            image: "/assets/stacks/symfony-icon.png"
                        },
                        {
                            id: '7',
                            name: "Laravel",
                            type: "Back-end",
                            image: "/assets/stacks/laravel.png"
                        },
                    ]
                }
                
            ]
        },
        {
            type: "Mobile",
            expertises:
            [
                {
                    contents: [
                        {
                            id: '1',
                            name: "Flutter",
                            type: "Mobile",
                            image: "/assets/stacks/flutter.png"
                        },
                        {
                            id: '2',
                            name: "React Native",
                            type: "Mobile",
                            image: "/assets/stacks/react.png"
                        }
                    ]
                }
            ]
        },
        {
            type: "UI framework",
            expertises:
            [
                {
                    contents: [
                        {
                            id: '1',
                            name: "Tailwind CSS",
                            type: "UI framework",
                            image: "/assets/stacks/Tailwind.png"
                        },
                        {
                            id: '2',
                            name: "Styled Components",
                            type: "UI framework",
                            image: "/assets/stacks/styled-component.png"
                        },
                        {
                            id: '3',
                            name: "Bootstrap",
                            type: "UI framework",
                            image: "/assets/stacks/Bootstrap_logo.png"
                        }
                    ]
                },
                {
                    contents: [
                        {
                            id: '4',
                            name: "Ant Design",
                            type: "UI framework",
                            image: "/assets/stacks/ant-design.png"
                        }
                    ]
                }
                
            ]
        },
        {
            type: "Devops",
            expertises:
            [
                {
                    contents: [
                        {
                            id: '1',
                            name: "Kubernetes",
                            type: "Devops",
                            image: "/assets/stacks/kubernetes.png"
                        },
                        {
                            id: '2',
                            name: "Docker",
                            type: "Devops",
                            image: "/assets/stacks/docker.webp"
                        }
                    ]
                }
                
            ]
        },
        {
            type: "No Code",
            expertises:
            [
                {
                    contents: [
                        {
                            id: '1',
                            name: "Webflow",
                            type: "No Code",
                            image: "/assets/stacks/Webflow.png"
                        },
                        {
                            id: '2',
                            name: "Airtable",
                            type: "No Code",
                            image: "/assets/stacks/Airtable.png"
                        }
                    ]
                },
                
            ]
        },
        {
            type: "low code",
            expertises:
            [
                {
                    contents: [
                        {
                            id: '1',
                            name: "Power plate form",
                            type: "Low code",
                            image: "/assets/stacks/power-plateforms.png"
                        },
                        {
                            id: '2',
                            name: "power apps",
                            type: "Low code",
                            image: "/assets/stacks/PowerApps-Logo.png"
                        },
                        {
                            id: '3',
                            name: "power BI",
                            type: "Low code",
                            image: "/assets/stacks/Microsoft-Power-BI-Logo-2013.png"
                        },
                        {
                            id: '4',
                            name: "share point",
                            type: "Low code",
                            image: "/assets/stacks/sharepoint.png"
                        }
                    ]
                },
                
            ]
        },
        {
            type: "ERP",
            expertises:
            [
                {
                    contents: [
                        {
                            id: '1',
                            name: "Odoo",
                            type: "ERP",
                            image: "/assets/stacks/odoo_logo.png"
                        },
                        {
                            id: '2',
                            name: "BITRIX 24",
                            type: "ERP",
                            image: "/assets/stacks/Bitrix24_Logo.png"
                        }
                    ]
                },
                
            ]
        },
        {
            type: "More",
            expertises:
            [
                {
                    contents: [
                        {
                            id: '1',
                            name: "Electron",
                            type: "More",
                            image: "/assets/stacks/electron.png"
                        },
                        {
                            id: '2',
                            name: "ThreeJS",
                            type: "More",
                            image: "/assets/stacks/ThreeJS.png"
                        }
                    ]
                },
                
            ]
        }
    ],
    projects: [
        {
            "name": "ESS",
            "image": "/assets/img/ess.png",
            "description": "ess_project_description",
            "link": "https://emraldsecuritiesservices.com/",
        },
        {
            "name": "Korione",
            "image": "/assets/img/korione.png",
            "description": "korione_project_description",
            "link": "https://korione.com/",
        }
    ]
}