import produce from "immer";
import { ApplicationAction } from "../types";
import { LOADING_RECRUITMENT_FAILED, LOADING_RECRUITMENT_REQUEST, LOADING_RECRUITMENT_SUCCESS, RecruitmentState } from "../types/RecruitmentTypes";



const initialState: RecruitmentState = {
    loading: {
        loading_recruitment_failed: false,
        loading_recruitment_request: false,
        loading_recruitment_success: false
    },
    recruitments: {
        count: 0
    }
}

const reducer = (state = initialState, action: ApplicationAction) => {

    switch(action.type) {
        case LOADING_RECRUITMENT_REQUEST:
            return produce(state, (draft) => {
                draft.loading.loading_recruitment_request = true;
                draft.loading.loading_recruitment_success = false;
                draft.loading.loading_recruitment_failed = false;
            })
        case LOADING_RECRUITMENT_SUCCESS:
            return produce(state, (draft) => {
                draft.loading.loading_recruitment_request = false;
                draft.loading.loading_recruitment_success = true;
                draft.loading.loading_recruitment_failed = false;
                draft.recruitments = action.recruitments
            })
        case LOADING_RECRUITMENT_FAILED:
            return produce(state, (draft) => {
                draft.loading.loading_recruitment_request = false;
                draft.loading.loading_recruitment_success = false;
                draft.loading.loading_recruitment_failed = true;
            })
        default:
            return state
    }
}

export default reducer;