import { useTranslation } from "react-i18next"
import Header from "../../components/header/Header"
import Page from "../../page/Page";


 

function PrivacyPolicy (props) {

    const { t } = useTranslation();

    return (
        <Page title={"OVA consulting | Privacy policy"} description={"Effective date: August 2022. 1. General Statement. We take your privacy very seriously and are committed to protecting it. We consider that you should be able to easily know what personal data we collect and use, and understand your rights in relation to it."}>
            <Header/>
            <section className="banner privacy-policy-banner"  style={{backgroundImage: "url(/assets/img/ova-office-acceuil.jpg)"}}>
                <div className="banner-1">
                    <div className="container">
                        <div className='row'>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div>
                                    <p className="text h3">{t(`footer_privacy_policy_page_title`)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text">
                <div className="container">
                    <div className="privacy-policy-text">
                        <h2>{t("privacy_policy_date")}</h2>
                        <br />
                        <br />
                        <h2>{t("privacy_policy_text_general_statement_title")}</h2>
                        <p>{t("privacy_policy_text_general_statement_text")}</p>
                        <br />
                        <h2>{t("privacy_policy_text_who_we_are_title")}</h2>
                        <p>{t("privacy_policy_text_who_we_are_text")}</p>
                        <br />
                        <h2>{t("privacy_policy_text_which_data_title")}</h2>
                        <div dangerouslySetInnerHTML={{__html: t("privacy_policy_text_which_data_text")}}></div>
                        <br />
                        <h2>{t("privacy_policy_text_why_do_we_title")}</h2>
                        <div dangerouslySetInnerHTML={{__html: t("privacy_policy_text_text_why_do_we_text")}}></div>
                        <br />
                        <h2>{t("privacy_policy_text_how_long_title")}</h2>
                        <div dangerouslySetInnerHTML={{__html: t("privacy_policy_text_how_long_text")}}></div>
                        <br />
                        <h2>{t("privacy_policy_text_how_we_transfert_title")}</h2>
                        <div dangerouslySetInnerHTML={{__html: t("privacy_policy_text_how_we_transfert_text")}}></div>
                        <br />
                        <h2>{t("privacy_policy_text_how_we_protect_title")}</h2>
                        <div dangerouslySetInnerHTML={{__html: t("privacy_policy_text_how_we_protect_text")}}></div>
                        <br />
                        <h2>{t("privacy_policy_text_how_to_contact_us_title")}</h2>
                        <div dangerouslySetInnerHTML={{__html: t("privacy_policy_text_your_right_text")}}></div>
                        <br />
                        <h2>{t("privacy_policy_text_your_right_title")}</h2>
                        <div dangerouslySetInnerHTML={{__html: t("privacy_policy_text_how_to_contact_us_text")}}></div>
                        <br />
                        <h2>{t("privacy_policy_text_update_title")}</h2>
                        <div dangerouslySetInnerHTML={{__html: t("privacy_policy_text_update_text")}}></div>
                    </div>
                </div>
            </section>
        </Page>
    )
}


export default PrivacyPolicy