import { LOADING_RECRUITMENT_FAILED, LOADING_RECRUITMENT_REQUEST, LOADING_RECRUITMENT_SUCCESS, LoadRecruitmentFailed, LoadRecruitmentRequest, LoadRecruitmentSuccess, Recruitment } from "../types/RecruitmentTypes"





export const loadRecruitmentRequest = (): LoadRecruitmentRequest => ({
    type: LOADING_RECRUITMENT_REQUEST
})

export const loadRecruitmentSuccess = (recruitments: Recruitment): LoadRecruitmentSuccess => ({
    type: LOADING_RECRUITMENT_SUCCESS,
    recruitments
})

export const loadRecruitmentFailed = (): LoadRecruitmentFailed => ({
    type: LOADING_RECRUITMENT_FAILED,
})