


export const aboutAnimationEffects = () => {

    // le but ici est d'observer les sections au scroll afin de voir celle qui actuelement visible.

    //  Scroller links

    const scrollerLinks = document.querySelectorAll(".main-scroller .link");

    // @ts-ignore
    window.$('#straegyCarousel').on('slide.bs.carousel',function(e){
      // @ts-ignore
      var slideFrom = window.$(this).find('.active').index();
      // @ts-ignore
      var slideTo = window.$(e.relatedTarget).index();
      // @ts-ignore
      window.$('#strategySwiperCounter').text(slideTo+1)
      // //console.log(slideFrom+' ====> '+slideTo);
    });

    // Creer l'observeur.

    let observer = new IntersectionObserver(observables => {
        
        for ( let observable of observables) {
          if( observable.intersectionRatio > 0.4 ) {
            for(let link of scrollerLinks) {
              if ( link.getAttribute('data-scroller') === observable.target.id ) {
                link.classList.add('active')
              }
            }
          } else {
              for(let link of scrollerLinks) {
                if ( link.getAttribute('data-scroller') === observable.target.id ) {
                  link.classList.remove('active')
                }
              }
          }
        }
    }, 
    {
      threshold: [0.4]
    }
    ) 

    // Recupere les sections de la page;

    const sections = document.querySelectorAll('main > .section');


    for( let section of sections) {
        // @ts-ignore
        observer.observe(section);
    }

    const scrolTop = document.querySelector('.scrolltop');

    scrolTop?.addEventListener('click', () => {
      window.scrollTo(0, 0);
    })


    // document.addEventListener('scroll', function () {
    //   // @ts-ignore
    //   const currentScroller = document.querySelector(".main-scroller .active");
    //   const darkSections = ["agency","partners", "footer"];
    //   const scroller = document.querySelector(".main-scroller");
    //   const dataScroller = currentScroller!.getAttribute("data-scroller");

    //   const scrollerLinks = document.querySelectorAll(".main-scroller .link")

    //   if( dataScroller && darkSections.includes(dataScroller)) {

    //     scroller!.className = "main-scroller dark-bg";

    //     //console.log("add")

    //   } else if (dataScroller === 'contact') {

    //     scroller!.className = "main-scroller";

    //     //console.log("remove")
    //   } else {
        
    //     scroller!.className = "main-scroller";
    //     //console.log("remove")
    //   }

    //   //console.log("currentScroller", scrollerLinks);


    // }, {
    //   passive: true
    // });

    // window.addEventListener('scroll', () => {
    //   // @ts-ignore
    //   if(isOnScreen(window.$('#partners'))) {
    //     //console.log("is opened")
    //   } else {
    //     //console.log('scrolling anyways')
    //   }
    // })
    

}