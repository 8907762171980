import { SnackbarProps } from "notistack";
import { ThunkAction } from "redux-thunk";
import { API, RECRUITMENTAPI } from "../../components/api/recruitment";
import { StoreArticle } from "../../components/components/types/storeCard";
import { loadRecruitmentFailed, loadRecruitmentRequest, loadRecruitmentSuccess } from "../actions/recruitments";
import { addToCartFailed, addToCartRequest, addToCartSuccess, addToWishlistFailed, addToWishlistRequest, addToWishlistSuccess, loadArticleFailed, loadArticleRequest, loadArticlesFailed, loadArticlesRequest, loadArticlesSuccess, loadArticleSuccess, removeFromCartFailed, removeFromCartRequest, removeFromCartSuccess, removeFromWishlistFailed, removeFromWishlistRequest, removeFromWishlistSuccess } from "../actions/storeArticles";
import { ApplicationAction, ApplicationState } from "../types";
import { URLS } from "../../components/api/urls";



type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>



export const listArticlesEffect = (): Effect => async (
    dispatch
) => {

    dispatch(loadArticlesRequest());
 
    API.get(URLS.ARTICLES.list).then((resp)=>{
        const { data } = resp;
                dispatch(
                    loadArticlesSuccess(data)
                )
            }).catch(error => {
                dispatch(
                    loadArticlesFailed()
                )
            })
}


export const addToCartEffect = (article: StoreArticle, enqueueSnackbar: Function): Effect => async (dispatch) => {
    dispatch(
        addToCartRequest()
    )

    try {
        dispatch(
            addToCartSuccess(article)
        )
        enqueueSnackbar("Item added to cart", {variant: 'success'});
    } catch (error) {
        dispatch(
            addToCartFailed()
        )
    }
}



export const removeFromCartEffect = (article: StoreArticle, enqueueSnackbar: Function): Effect => async (dispatch) => {
    dispatch(
        removeFromCartRequest()
    )

    try {
        dispatch(
            removeFromCartSuccess(article)
        )
        enqueueSnackbar("Item Removed from cart", {variant: 'success'});
    } catch (error) {
        dispatch(
            removeFromCartFailed()
        )
    }
}

export const loadArticleEffect = (id: string , enqueueSnackbar: Function): Effect => async (
    dispatch
) => {

    dispatch(loadArticleRequest());

    API.get(URLS.ARTICLES.details(id)).then((resp)=>{
        const { data } = resp;
                dispatch(
                    loadArticleSuccess(data)
                )
            }).catch(error => {
                dispatch(
                    loadArticleFailed()
                );
                enqueueSnackbar("Loading article Error", {variant: 'error'})
            });
}

// Wishlist

export const addToWishlistEffect = (article: StoreArticle, enqueueSnackbar: Function): Effect => async (dispatch) => {
    dispatch(
        addToWishlistRequest()
    )

    try {
        dispatch(
            addToWishlistSuccess(article)
        )
        enqueueSnackbar("Item added to wishlist", {variant: 'success'});
    } catch (error) {
        dispatch(
            addToWishlistFailed()
        )
    }
}

export const removeFromWishlistEffect = (article: StoreArticle, enqueueSnackbar: Function): Effect => async (dispatch) => {
    dispatch(
        removeFromWishlistRequest()
    )

    try {
        dispatch(
            removeFromWishlistSuccess(article)
        )
        enqueueSnackbar("Item added to wishlist", {variant: 'success'});
    } catch (error) {
        dispatch(
            removeFromWishlistFailed()
        )
    }
}