import { useTranslation } from 'react-i18next';
import OwlCarousel from 'react-owl-carousel';
import { useParams } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Page from '../../page/Page';
import { getBgImage, getClients } from '../../utils/functions';
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { makeStyles} from '@mui/styles';
import theme from '../../components/theme/theme';
import Timeline from './components/timeline/Timeline';
import { datascienceMethodologies } from '../../const/timelines';
import { Link } from 'react-router-dom';

  const useStyles = makeStyles({
  tabs: {

    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary,
      height: 3,
    },
    // "& .MuiTab-root.MuiTab-root": {
    //   color: theme.palette.primary
    // },
    "& .MuiTab-root.Mui-selected": {
      color: theme.palette.primary
    },
    "& .MuiTab-root": {
      "&:not(.Mui-selected)": {
        color: 'white'
      }
    }
  },
  tabPanel: {
    // color: 'white'
  }
})



interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function ExpertiseDatascience() {
  const { expertise } = useParams();
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Page title={'OVA - Consulting | Data science'} description={"We leverage advanced big data and business intelligence tools to help clients extract actionable insights from diverse data sets generated in real time and at a large scale. We help them consolidate huge volumes of data coming from different sources into a holistic environment that can be used for modelling and predicting new market opportunities."} >
      <Header />
      <section className="expertise-details expertise-infrastructure">
        <section
          className="banner"
          style={{ backgroundImage: "url(/assets/expertises/datascience.jpg)" }}>
          <div className="banner-1">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div>
                    <Link to="/expertises" className="expertses-return h5">
                      <span className="d-flex">
                            <img src="/assets/img/stat-speed-up.png" alt="..." className="m-auto" />
                        </span>
                        <span>Expertises</span>
                      <span>Expertises</span>
                    </Link>
                    <p className="text h3">{t(`expertise_data_science`)}</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div>
                    <div className="banner-description">
                      <p>{t('expertise_data_science_text')}</p>
                      <Link
                        to="/contact"
                        className="button button--pan"
                        role={'button'}>
                        <span>{t('contact_contact_us')}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-2">
            <OwlCarousel
              className="owl-theme our-clients-carousel"
              loop
              margin={10}
              autoplayHoverPause
              dots={false}
              autoplay
              smartSpeed={2000}
              autoplayTimeout={3000}
              responsive={{
                0: {
                  items: 2,
                  loop: true
                },
                600: {
                  items: 4,
                  loop: true
                },
                1000: {
                  items: 5,
                  loop: true
                }
              }}>
              {getClients('software-engineering').map((item, index) => {
                return (
                  <div className="item" key={index}>
                    <div
                      className="reference"
                      style={{
                        backgroundImage: `url(${item.logo})`,
                        height: '100px'
                      }}></div>
                  </div>
                );
              })}
            </OwlCarousel> 
          </div>
        </section>
        <section className="details">
          <div className="container">
            <div className='data-science-road-map'>
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-4 col-6 mb-5">
                      <div className='item'>
                        <div className="p-4 my-6 bg-primary shadow text-white w-full relative">
                          {t("Define the problem")}
                          <div className="absolute bg-white rounded-full p-2 -top-6 right-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-7 w-7 text-primary"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-4 col-6 mb-5">
                      <div className='item'>
                          <div className="p-4 my-6 bg-secondary shadow text-white w-full relative">
                            {t("Collect data")}
                            <div className="absolute bottom bg-white rounded-full p-2 -bottom-6 right-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-7 w-7 text-secondary"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
                              </svg>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-4 col-6 mb-5">
                      <div className='item'>
                          <div className="p-4 my-6 bg-tertiary text-white shadow w-full relative">
                            {t("Prepare data")}
                            <div className="absolute bg-white rounded-full p-2 -top-6 right-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-7 w-7 text-tertiary"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                              </svg>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-4 col-6 mb-5">
                      <div className='item'>
                          <div className="p-4 my-6 bg-black shadow text-white w-full relative">
                            <div className="absolute bottom bg-white rounded-full p-2 -bottom-6 right-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-7 w-7 text-quartenary"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
                              </svg>
                            </div>
                            {t("Analyze data")}
                          </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-4 col-6 mb-5">
                      <div className='item'>
                          <div className="p-4 my-6 bg-quinquenary shadow text-white w-full relative">
                            {t("Incorporate in business")}
                            <div className="absolute bg-white rounded-full p-2 -top-6 right-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-7 w-7 text-quinquenary"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                              </svg>
                            </div>
                          </div>
                        
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-4 col-6 mb-5">
                      <div className='item'>
                      <div className="p-4 my-6 bg-sixth shadow text-white w-full relative">
                            {t("Validate the results")}
                            <div className="absolute bottom bg-white rounded-full p-2 -bottom-6 right-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-7 w-7 text-sixth"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
                              </svg>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
          </div>
          <section className="bg-tertiary mb-4">
            <div className="container">
              <div className='what-tech'>
                <h2 className="text-white">{t("what_tech_we_use")}</h2>
                <div className="techs">
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: '#ffffff4f', width: 'fit-content', margin: 'auto' }}>
                      <Tabs 
                        value={value} 
                        onChange={handleChange} 
                        aria-label="basic tabs example"
                        className={classes.tabs}
                      >
                        <Tab className={classes.tabPanel} label={t("Storage")} {...a11yProps(0)} />
                        <Tab className={classes.tabPanel} label={t("Mining")} {...a11yProps(1)} />
                        <Tab className={classes.tabPanel} label={t("Analytics")} {...a11yProps(2)} />
                        <Tab className={classes.tabPanel} label={t("Visualization")} {...a11yProps(3)} />
                      </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                      <div>
                        <div className="row justify-center">
                          <div className="col-lg-4">
                            <div>
                              <div className="tech-card">
                                <div className="item">
                                  <div><img height={'40px'} src="/assets/expertises/mongodb.png" alt="Mongo db" /></div>
                                  <div>MongoDB</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div>
                              <div className="tech-card">
                                <div className="item">
                                  <div><img height={'40px'} src="/assets/expertises/hadoop.png" alt="Hadoop" /></div>
                                  <div>Hadoop</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div>
                        <div className="row justify-center">
                          <div className="col-lg-4">
                            <div>
                              <div className="tech-card">
                                <div className="item">
                                  <div><img height={'40px'} src="/assets/expertises/presto.webp" alt="Presto" /></div>
                                  <div>Presto</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div>
                              <div className="tech-card">
                                <div className="item">
                                  <div><img height={'40px'} src="/assets/expertises/rapidminer.jpg" alt="Rapidminer" /></div>
                                  <div>Rapidminer</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div>
                              <div className="tech-card">
                                <div className="item">
                                  <div><img height={'40px'} src="/assets/expertises/elasticsearch.png" alt="Elasticsearch" /></div>
                                  <div>Elasticsearch</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <div>
                        <div className="row justify-center">
                          <div className="col-lg-4">
                            <div>
                              <div className="tech-card">
                                <div className="item">
                                  <div><img height={'40px'} src="/assets/expertises/R.png" alt="R" /></div>
                                  <div>R</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div>
                              <div className="tech-card">
                                <div className="item">
                                  <div><img height={'40px'} src="/assets/expertises/kafka.png" alt="Kafka" /></div>
                                  <div>Kafka</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div>
                              <div className="tech-card">
                                <div className="item">
                                  <div><img height={'40px'} src="/assets/expertises/spark.png" alt="Spark" /></div>
                                  <div>Spark</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div>
                              <div className="tech-card">
                                <div className="item">
                                  <div><img height={'40px'} src="/assets/expertises/kamine.png" alt="Kanime" /></div>
                                  <div>Kanime</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div>
                              <div className="tech-card">
                                <div className="item">
                                  <div><img height={'40px'} src="/assets/expertises/blockchain.png" alt="Blockchain" /></div>
                                  <div>Blockchain</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div>
                              <div className="tech-card">
                                <div className="item">
                                  <div><img height={'40px'} src="/assets/expertises/splunk.png" alt="Splunk" /></div>
                                  <div>Splunk</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <div>
                        <div className="row justify-center">
                          <div className="col-lg-4">
                            <div>
                              <div className="tech-card">
                                <div className="item">
                                  <div><img height={'40px'} src="/assets/expertises/Plotly.png" alt="Plotly" /></div>
                                  <div>Plotly</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div>
                              <div className="tech-card">
                                <div className="item">
                                  <div><img height={'40px'} src="/assets/expertises/Tableau.png" alt="Tableau" /></div>
                                  <div>Tableau</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </Box>
                </div>
              </div>
            </div>
          </section>
          <div className="container">
            <div>
              <h2>{t("how_receip")}</h2>
            </div>
            <div>
              <div className="row text-left mt-5">
                <div className="col-lg-4 col-am-4 col-12">
                  <div>
                    <div className="card">
                      <p className="title  text-primary">{t("understand_concept")}</p>
                      <p>
                      {t("understand_concept_text")}
                    </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-am-4 col-12">
                  <div>
                    <div className="card">
                      <p className="title text-primary">{t("prepare_data")}</p>
                      <div>
                        <p>
                          {t("prepare_data_text")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-am-4 col-12">
                  <div>
                    <div className="card">
                      <p className="title text-primary">{t("scale_iterate")}</p>
                      <div>
                        <p>
                          {t("scale_iterate_text")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="container">
            <div className="row">
              <div className="col-lg-6 col-am-6 col-12">
                <div>
                  <div className="card">
                    <p className="title">Machine learning</p>
                    <ul>
                      <li>
                        Distinguer et expérimenter les différentes applications
                        présentes sur le poste
                      </li>
                      <li>
                        Utiliser les outils de base indispensables à
                        l'exploitation du poste de travail
                      </li>
                      <li>Organiser les dossiers et les documents</li>
                      <li>Rechercher des documents ou des dossiers</li>
                      <li>
                        Gérer la configuration de votre environnement de
                        travail.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-am-6 col-12">
                <div>
                  <div className="card">
                    <p className="title">Machine learning</p>
                    <ul>
                      <li>
                        Distinguer et expérimenter les différentes applications
                        présentes sur le poste
                      </li>
                      <li>
                        Utiliser les outils de base indispensables à
                        l'exploitation du poste de travail
                      </li>
                      <li>Organiser les dossiers et les documents</li>
                      <li>Rechercher des documents ou des dossiers</li>
                      <li>
                        Gérer la configuration de votre environnement de
                        travail.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-am-6 col-12">
                <div>
                  <div className="card">
                    <p className="title">Machine learning</p>
                    <ul>
                      <li>
                        Distinguer et expérimenter les différentes applications
                        présentes sur le poste
                      </li>
                      <li>
                        Utiliser les outils de base indispensables à
                        l'exploitation du poste de travail
                      </li>
                      <li>Organiser les dossiers et les documents</li>
                      <li>Rechercher des documents ou des dossiers</li>
                      <li>
                        Gérer la configuration de votre environnement de
                        travail.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-am-6 col-12">
                <div>
                  <div className="card">
                    <p className="title">Machine learning</p>
                    <ul>
                      <li>
                        Distinguer et expérimenter les différentes applications
                        présentes sur le poste
                      </li>
                      <li>
                        Utiliser les outils de base indispensables à
                        l'exploitation du poste de travail
                      </li>
                      <li>Organiser les dossiers et les documents</li>
                      <li>Rechercher des documents ou des dossiers</li>
                      <li>
                        Gérer la configuration de votre environnement de
                        travail.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </section>
        <section>
          <div className="container">
            <div className='mt-3'>
              <h1 className="text-primary text-left">
                {t("our_methodology")}
              </h1>
            </div>
            <Timeline timeLines={datascienceMethodologies} />
          </div>
        </section>
      </section>
      <Footer />
    </Page>
  );
}

export default ExpertiseDatascience;
