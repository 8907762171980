
export interface TrainingDetails {
    objectives: string,
    required_level: string,
    concerned_public: string,
    program: Program[]
} 



export interface Training {
    title: string,
    description: string,
    duration: string,
    slug: string,
    trainingPlaces: string[],
    category: string[],
    trainingDetails?: TrainingDetails
}

export interface CourseCategory {
    

} 


export interface Program {
    title: string,
    content: Content[]
}

export interface Content {
    title: string,
    text: string
}

export const CourseCategories: string[] = [
    'management',
    'Microsoft office 365',
    'Infrastructure & cloud'
]


export const trainings:Training[] = [
    {
        title: 'training_title_1',
        description: "training_desc_1",
        duration: "training_duration_1",
        slug: "agile",
        trainingPlaces: [
            'online',
            // 'face-to-face'
        ],
        category: [
            'management'
        ],
        trainingDetails: {
            objectives: "training_agile_objectives",
            required_level: "training_agile_required_level",
            concerned_public: "training_agile_concerned_public",
            program: [
                {
                    title: "training_agile_program_title_1",
                    content: [
                        {
                            title:"training_agile_program_content_title_1",
                            text: "training_agile_program_content_text_1"
                        },
                        {
                            title:"training_agile_program_content_title_2",
                            text: "training_agile_program_content_text_2"
                        },
                        {
                            title:"training_agile_program_content_title_3",
                            text: "training_agile_program_content_text_3"
                        }
                    ]
                },
                {
                    title: "training_agile_program_title_2",
                    content: [
                        {
                            title:"training_agile_program_2_content_title_1",
                            text: "training_agile_program_2_content_text_1"
                        },
                        {
                            title:"training_agile_program_2_content_title_2",
                            text: "training_agile_program_2_content_text_2"
                        },
                        {
                            title:"training_agile_program_2_content_title_3",
                            text: "training_agile_program_2_content_text_3"
                        },
                        {
                            title:"training_agile_program_2_content_title_4",
                            text: "training_agile_program_2_content_text_4"
                        }
                    ]
                }
            ]
        },
    },
    {
        title: 'training_title_2',
        description: "training_desc_2",
        duration: "training_duration_2",
        slug: "microsoft-office-365",
        trainingPlaces: [
            'online',
            'face-to-face'
        ],
        category: [
            'Microsoft office 365',
        ],
        trainingDetails: {
            objectives: "training_microsoft_office_365_objectives",
            required_level: "training_microsoft_office_365_required_level",
            concerned_public: "training_microsoft_office_365_concerned_public",
            program: [
                {
                    title: "training_microsoft_office_365_program_title_1",
                    content: [
                        {
                            title:"training_microsoft_office_365_program_content_title_1",
                            text: "training_microsoft_office_365_program_content_text_1"
                        },
                        {
                            title:"training_microsoft_office_365_program_content_title_2",
                            text: "training_microsoft_office_365_program_content_text_2"
                        },
                        {
                            title:"training_microsoft_office_365_program_content_title_3",
                            text: "training_microsoft_office_365_program_content_text_3"
                        }
                    ]
                },
                {
                    title: "training_microsoft_office_365_program_title_2",
                    content: [
                        {
                            title:"training_microsoft_office_365_program_2_content_title_1",
                            text: "training_microsoft_office_365_program_2_content_text_1"
                        },
                        {
                            title:"training_microsoft_office_365_program_2_content_title_2",
                            text: "training_microsoft_office_365_program_2_content_text_2"
                        },
                        {
                            title:"training_microsoft_office_365_program_2_content_title_3",
                            text: "training_microsoft_office_365_program_2_content_text_3"
                        }
                    ]
                },
                {
                    title: "training_microsoft_office_365_program_title_3",
                    content: [
                        {
                            title:"training_microsoft_office_365_program_3_content_title_1",
                            text: "training_microsoft_office_365_program_3_content_text_1"
                        },
                        {
                            title:"training_microsoft_office_365_program_3_content_title_2",
                            text: "training_microsoft_office_365_program_3_content_text_2"
                        },
                        {
                            title:"training_microsoft_office_365_program_3_content_title_3",
                            text: "training_microsoft_office_365_program_3_content_text_3"
                        }
                    ]
                },
                {
                    title: "training_microsoft_office_365_program_title_4",
                    content: [
                        {
                            title:"training_microsoft_office_365_program_4_content_title_1",
                            text: "training_microsoft_office_365_program_4_content_text_1"
                        },
                        {
                            title:"training_microsoft_office_365_program_4_content_title_2",
                            text: "training_microsoft_office_365_program_4_content_text_2"
                        }
                    ]
                },
                {
                    title: "training_microsoft_office_365_program_title_5",
                    content: [
                        {
                            title:"training_microsoft_office_365_program_5_content_title_1",
                            text: "training_microsoft_office_365_program_5_content_text_1"
                        }
                    ]
                }
            ]
        },
    },
    {
        title: 'training_title_3',
        description: "training_desc_3",
        duration: "training_duration_3",
        slug: "system-administration",
        trainingPlaces: [
            'online',
            'face-to-face'
        ],
        category: [
            'management'
        ],
        trainingDetails: {
            objectives: "training_agile_objectives",
            required_level: "training_agile_required_level",
            concerned_public: "training_agile_concerned_public",
            program: [
                {
                    title: "training_agile_program_title_1",
                    content: [
                        {
                            title:"training_agile_program_content_title_1",
                            text: "training_agile_program_content_text_1"
                        },
                        {
                            title:"training_agile_program_content_title_2",
                            text: "training_agile_program_content_text_2"
                        },
                        {
                            title:"training_agile_program_content_title_3",
                            text: "training_agile_program_content_text_3"
                        }
                    ]
                },
                {
                    title: "training_agile_program_title_2",
                    content: [
                        {
                            title:"training_agile_program_2_content_title_1",
                            text: "training_agile_program_2_content_text_1"
                        },
                        {
                            title:"training_agile_program_2_content_title_2",
                            text: "training_agile_program_2_content_text_2"
                        },
                        {
                            title:"training_agile_program_2_content_title_3",
                            text: "training_agile_program_2_content_text_3"
                        },
                        {
                            title:"training_agile_program_2_content_title_4",
                            text: "training_agile_program_2_content_text_4"
                        }
                    ]
                }
            ]
        },
    },
    {
        title: 'training_title_4',
        description: "training_desc_4",
        duration: "training_duration_4",
        slug: "vmware",
        trainingPlaces: [
            'online',
            'face-to-face',
        ],
        category: [
            'Infrastructure & cloud'
        ],
        trainingDetails: {
            objectives: "training_vmware_objectives",
            required_level: "training_vmware_required_level",
            concerned_public: "training_vmware_concerned_public",
            program: [
                {
                    title: "training_vmware_program_title_1",
                    content: [
                        {
                            title:"training_vmware_program_content_title_1",
                            text: "training_vmware_program_content_text_1"
                        },
                        {
                            title:"training_vmware_program_content_title_2",
                            text: "training_vmware_program_content_text_2"
                        }
                    ]
                },
                {
                    title: "training_vmware_program_title_2",
                    content: [
                        {
                            title:"training_vmware_program_2_content_title_1",
                            text: "training_vmware_program_2_content_text_1"
                        },
                        {
                            title:"training_vmware_program_2_content_title_2",
                            text: "training_vmware_program_2_content_text_2"
                        },
                        {
                            title:"training_vmware_program_2_content_title_3",
                            text: "training_vmware_program_2_content_text_3"
                        },
                        {
                            title:"training_vmware_program_2_content_title_4",
                            text: "training_vmware_program_2_content_text_4"
                        }
                    ]
                },
                {
                    title: "training_vmware_program_title_3",
                    content: [
                        {
                            title:"training_vmware_program_3_content_title_1",
                            text: "training_vmware_program_3_content_text_1",
                        }
                    ]
                }
            ]
        },
    },
    {
        title: 'training_title_5',
        description: "training_desc_5",
        duration: "training_duration_5",
        slug: "devops",
        trainingPlaces: [
            'online',
            'face-to-face',
        ],
        category: [
            'Infrastructure & cloud',
        ],
        trainingDetails: {
            objectives: "training_devops_objectives",
            required_level: "training_devops_required_level",
            concerned_public: "training_devops_concerned_public",
            program: [
                {
                    title: "training_devops_program_title_1",
                    content: [
                        {
                            title:"training_devops_program_content_title_1",
                            text: "training_devops_program_content_text_1"
                        },
                        {
                            title:"training_devops_program_content_title_2",
                            text: "training_devops_program_content_text_2"
                        },
                        {
                            title:"training_devops_program_content_title_3",
                            text: "training_devops_program_content_text_3"
                        },
                        {
                            title:"training_devops_program_content_title_4",
                            text: "training_devops_program_content_text_4"
                        },
                        {
                            title:"training_devops_program_content_title_5",
                            text: "training_devops_program_content_text_5"
                        },
                    ]
                },
                {
                    title: "training_devops_program_title_2",
                    content: [
                        {
                            title:"training_devops_program_2_content_title_1",
                            text: "training_devops_program_2_content_text_1"
                        },
                        {
                            title:"training_devops_program_2_content_title_2",
                            text: "training_devops_program_2_content_text_2"
                        }
                    ]
                },
                {
                    title: "training_devops_program_title_3",
                    content: [
                        {
                            title:"training_devops_program_3_content_title_1",
                            text: "training_devops_program_3_content_text_1"
                        },
                        {
                            title:"training_devops_program_3_content_title_2",
                            text: "training_devops_program_3_content_text_2"
                        }
                    ]
                }
            ]
        },
    },
    {
        title: 'training_title_6',
        description: "training_desc_6",
        duration: "training_duration_6",
        slug: "itil-4",
        trainingPlaces: [
            'online',
            'face-to-face'
        ],
        category: [
            'Management',
        ],
        trainingDetails: {
            objectives: "training_itil_objectives",
            required_level: "training_itil_required_level",
            concerned_public: "training_itil_concerned_public",
            program: [
                {
                    title: "training_itil_program_title_1",
                    content: [
                        {
                            title:"training_itil_program_content_title_1",
                            text: "training_itil_program_content_text_1"
                        },
                        {
                            title:"training_itil_program_content_title_2",
                            text: "training_itil_program_content_text_2"
                        },
                    ]
                },
                {
                    title: "training_itil_program_title_2",
                    content: [
                        {
                            title:"training_itil_program_2_content_title_1",
                            text: "training_itil_program_2_content_text_1"
                        },
                        {
                            title:"training_itil_program_2_content_title_2",
                            text: "training_itil_program_2_content_text_2"
                        },
                    ]
                },
                {
                    title: "training_itil_program_title_3",
                    content: [
                        {
                            title:"training_itil_program_3_content_title_1",
                            text: "training_itil_program_3_content_text_1"
                        }
                    ]
                },
                {
                    title: "training_itil_program_title_4",
                    content: [
                        {
                            title:"training_itil_program_4_content_title_1",
                            text: "training_itil_program_4_content_text_1"
                        }
                    ]
                }
            ]
        },
    },
    {
        title: 'training_title_7',
        description: "training_desc_7",
        duration: "training_duration_7",
        slug: "cobit",
        trainingPlaces: [
            'online',
            'face-to-face'
        ],
        category: [
            'management',
        ],
        trainingDetails: {
            objectives: "training_cobit_objectives",
            required_level: "training_cobit_required_level",
            concerned_public: "training_cobit_concerned_public",
            program: [
                {
                    title: "training_cobit_program_title_1",
                    content: [
                        {
                            title:"training_cobit_program_content_title_1",
                            text: "training_cobit_program_content_text_1"
                        }
                    ]
                },
                {
                    title: "training_cobit_program_title_2",
                    content: [
                        {
                            title:"training_cobit_program_2_content_title_1",
                            text: "training_cobit_program_2_content_text_1"
                        },
                        {
                            title:"training_cobit_program_2_content_title_2",
                            text: "training_cobit_program_2_content_text_2"
                        },
                    ]
                },
                {
                    title: "training_cobit_program_title_3",
                    content: [
                        {
                            title:"training_cobit_program_3_content_title_1",
                            text: "training_cobit_program_3_content_text_1"
                        },
                    ]
                },
                {
                    title: "training_cobit_program_title_4",
                    content: [
                        {
                            title:"training_cobit_program_4_content_title_1",
                            text: "training_cobit_program_4_content_text_1"
                        },
                    ]
                }
            ]
        },
    },
    {
        title: 'training_title_9',
        description: "training_desc_9",
        duration: "training_duration_9",
        slug: "iso-27001",
        trainingPlaces: [
            'online',
            'face-to-face',
        ],
        category: [
            'management',
        ],
        trainingDetails: {
            objectives: "training_iso_27001_objectives",
            required_level: "training_iso_27001_required_level",
            concerned_public: "training_iso_27001_concerned_public",
            program: [
                {
                    title: "training_iso_27001_program_title_1",
                    content: [
                        {
                            title:"training_iso_27001_program_content_title_1",
                            text: "training_iso_27001_program_content_text_1"
                        },
                    ]
                },
                {
                    title: "training_iso_27001_program_title_2",
                    content: [
                        {
                            title:"training_iso_27001_program_2_content_title_1",
                            text: "training_iso_27001_program_2_content_text_1"
                        },
                    ]
                },
                {
                    title: "training_iso_27001_program_title_3",
                    content: [
                        {
                            title:"training_iso_27001_program_3_content_title_1",
                            text: "training_iso_27001_program_3_content_text_1"
                        },
                    ]
                },
                {
                    title: "training_iso_27001_program_title_4",
                    content: [
                        {
                            title:"training_iso_27001_program_4_content_title_1",
                            text: "training_iso_27001_program_4_content_text_1"
                        },
                    ]
                }
            ]
        },
    },
    {
        title: 'training_title_10',
        description: "training_desc_10",
        duration: "training_duration_10",
        slug: "identity-services-microsoft-365",
        trainingPlaces: [
            'online',
            'face-to-face',
        ],
        category: [
            'management',
        ],
        trainingDetails: {
            objectives: "training_identity_services_microsoft_365_objectives",
            required_level: "training_identity_services_microsoft_365_required_level",
            concerned_public: "training_identity_services_microsoft_365_concerned_public",
            program: [
                {
                    title: "training_identity_services_microsoft_365_program_title_1",
                    content: [
                        {
                            title:"training_identity_services_microsoft_365_program_content_title_1",
                            text: "training_identity_services_microsoft_365_program_content_text_1"
                        },
                        {
                            title:"training_identity_services_microsoft_365_program_content_title_2",
                            text: "training_identity_services_microsoft_365_program_content_text_2"
                        }
                    ]
                },
                {
                    title: "training_identity_services_microsoft_365_program_title_2",
                    content: [
                        {
                            title:"training_identity_services_microsoft_365_program_2_content_title_1",
                            text: "training_identity_services_microsoft_365_program_2_content_text_1"
                        }
                    ]
                },
                {
                    title: "training_identity_services_microsoft_365_program_title_3",
                    content: [
                        {
                            title:"training_identity_services_microsoft_365_program_3_content_title_1",
                            text: "training_identity_services_microsoft_365_program_3_content_text_1"
                        }
                    ]
                },
                {
                    title: "training_identity_services_microsoft_365_program_title_4",
                    content: [
                        {
                            title:"training_identity_services_microsoft_365_program_4_content_title_1",
                            text: "training_identity_services_microsoft_365_program_4_content_text_1"
                        }
                    ]
                },
                {
                    title: "training_identity_services_microsoft_365_program_title_5",
                    content: [
                        {
                            title:"training_identity_services_microsoft_365_program_5_content_title_1",
                            text: "training_identity_services_microsoft_365_program_5_content_text_1"
                        }
                    ]
                }
            ]
        },
    },
    {
        title: 'FORTINET FIREWALL',
        description: "training_desc_11",
        duration: "training_duration_11",
        slug: "fortinet-firewall",
        trainingPlaces: [
            'online',
            'face-to-face',
        ],
        category: [
            'Infrastructure & cloud',
        ],
        trainingDetails: {
            objectives: "training_fortinet_firewall_objectives",
            required_level: "training_fortinet_firewall_level",
            concerned_public: "training_fortinet_firewall_concerned_public",
            program: [
                {
                    title: "training_fortinet_firewall_title_1",
                    content: [
                        {
                            title:"training_fortinet_firewall_program_content_title_1",
                            text: "training_fortinet_firewall_program_content_text_1"
                        },
                        {
                            title:"training_fortinet_firewall_program_content_title_3",
                            text: "training_fortinet_firewall_program_content_text_3"
                        },
                        {
                            title:"training_fortinet_firewall_program_content_title_4",
                            text: "training_fortinet_firewall_program_content_text_4"
                        }
                    ]
                },
                {
                    title: "training_fortinet_firewall_program_title_2",
                    content: [
                        {
                            title:"training_fortinet_firewall_program_2_content_title_1",
                            text: "training_fortinet_firewall_program_2_content_text_1"
                        },
                        {
                            title:"training_fortinet_firewall_program_2_content_title_2",
                            text: "training_fortinet_firewall_program_2_content_text_2"
                        },
                        {
                            title:"training_fortinet_firewall_program_2_content_title_3",
                            text: "training_fortinet_firewall_program_2_content_text_3"
                        }
                    ]
                },
            ]
        },
    },
    {
        title: 'CISCO CCNA',
        description: "training_desc_12",
        duration: "training_duration_12",
        slug: "cisco-ccna",
        trainingPlaces: [
            'online',
            'face-to-face',
        ],
        category: [
            'Infrastructure & cloud',
        ],
        trainingDetails: {
            objectives: "training_cisco_ccna_objectives",
            required_level: "training_cisco_ccna_level",
            concerned_public: "training_cisco_ccna_concerned_public",
            program: [
                {
                    title: "training_cisco_ccna_title_1",
                    content: [
                        {
                            title:"training_cisco_ccna_program_content_title_1",
                            text: "training_cisco_ccna_program_content_text_1"
                        }
                    ]
                },
                {
                    title: "training_cisco_ccna_program_title_2",
                    content: [
                        {
                            title:"training_cisco_ccna_program_2_content_title_1",
                            text: "training_cisco_ccna_program_2_content_text_1"
                        }
                    ]
                },
                {
                    title: "training_cisco_ccna_program_title_3",
                    content: [
                        {
                            title:"training_cisco_ccna_program_3_content_title_1",
                            text: "training_cisco_ccna_program_3_content_text_1"
                        }
                    ]
                },
                {
                    title: "training_cisco_ccna_program_title_4",
                    content: [
                        {
                            title:"training_cisco_ccna_program_4_content_title_1",
                            text: "training_cisco_ccna_program_4_content_text_1"
                        }
                    ]
                },
                {
                    title: "training_cisco_ccna_program_title_5",
                    content: [
                        {
                            title:"training_cisco_ccna_program_5_content_title_1",
                            text: "training_cisco_ccna_program_5_content_text_1"
                        }
                    ]
                },
                {
                    title: "training_cisco_ccna_program_title_6",
                    content: [
                        {
                            title:"training_cisco_ccna_program_6_content_title_1",
                            text: "training_cisco_ccna_program_6_content_text_1"
                        }
                    ]
                }
            ]
        },
    }
]

export const trainingsCategories = [
    'management',
    'Microsoft office 365',
    'Infrastructure & cloud',
]