import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import { CONTACTAPI } from "../../api/contact";
import Footer from "../../components/footer/Footer";
import Header3 from "../../components/header/Header3";
import Page from "../../page/Page";



interface Alert {
    type: string;
    message: string
}


export default function Contact (props: any) {

    const { t } = useTranslation();
    const location = useLocation();

    const [iWant, setIWant] = useState( location.pathname.includes('/contact-reason-') ? location.pathname.replace('/contact-reason-', '') : 'WORK');
    const [file, setFile] = useState<File | null>(null);
    const [alert, setAlert] = useState<Alert | null >(null);


    const initialValues = {
        "lastname": "",
        "firstname": "",
        "email": "",
        "tel": "",
        "work": "WORK",
        "message": "",
    };


    ////console.log('location', location.pathname.replace('/contact-reason-', ''));

    const handleChangeNeed = (event: any) => {

        ////console.log(event.target.value);
        setIWant(event.target.value);
        setFile(null);
    }

    const handleChangeFile = (event: any) => {
        let file = event.target.files[0];
        ////console.log(file);
        setFile(file);
    }

    const handleSubmit = (values: any) => {

        const data = new FormData();

        data.append("first_name", values.firstname);
        data.append("last_name", values.lastname);
        data.append("email", values.email);
        data.append("phone", values.tel);
        data.append("message", values.message);
        data.append("subject", iWant);

        if(file){
            data.append("file", file);
        }
        CONTACTAPI.post("contact/", data).then((resp) => {
            setAlert({
                type: 'success',
                message: t('contact_message_success')
            })
        }).catch((err) => {
            setAlert({
                type: 'danger',
                message: t('contact_message_error')
            })
        })
    }

    return(
        <Page
            title={"OVA - Consulting | Contact"}
            description={t("contact_contact_us")}
        >
            <div>
                <Header3/>
                <div className="main contact" style={{marginTop: '3rem'}}>
                    <div className="main-content">

                        <div className="space-h-100"></div>
                        <p className="contact-page-title">Contact</p>
                        <section>
                            <div className="container position-relative">
                                <div className="our-ref-title1 text-contact">
                                <strong>Contact</strong>
                                </div>
                            </div>
                        </section>
                    {/* <!-- SECTION START --> */}
                        <section className="contact-map">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 col-md-12 col-12">
                                        <div>
                                            <div className="informations">
                                                <div className="contact-succursale">
                                                    <p className="title"> {t('contact_need_info')} </p>
                                                    <hr style={{background: '#fff'}} />
                                                    <p className="title1"> {t('contact_contact_us')} </p>
                                                    <p className="text"> {t('contact_location')}: {t('contact_location_cameroon')}</p>
                                                    <p className="text"> 
                                                        {t("ova_location_cameroon")}
                                                    </p>
                                                    <p className="text"> {t('contact_phone')}: {(" ")}
                                                        <a className="text-contact" style={{color: '#fff'}} href="tel:+237696427703"> (+237) 6 96 42 77 03</a>
                                                    </p>
                                                    <p className="text"> {t('contact_email')}: {' '}
                                                        <a className="text-contact" style={{color: '#fff'}} href="mailto:contact@ova-consulting.fr">
                                                        contact@ova-consulting.fr
                                                        </a>
                                                        <br/>
                                                        <a href="mailto:support@ova-consulting.fr">support@ova-consulting.fr</a>
                                                    </p>                                                </div>
                                                <div className="contact-succursale">
                                                    <br />
                                                    <p className="title1"> {t('contact_contact_us')} </p>
                                                    <p className="text"> {t('contact_location')}: {t('contact_location_france')}</p>
                                                    <p className="text"> 
                                                        {t("ova_location_paris")}
                                                    </p>
                                                    <p className="text"> {t('contact_phone')}: {(" ")}
                                                        <a className="text-contact" style={{color: '#fff'}} href="tel:+33142804357">(+33)1-42-80-43-57</a> /  <a className="text-contact" style={{color: '#fff'}} href="tel:+33638607849"> (+33)6-38-60-78-49 </a>
                                                    </p>
                                                    <p className="text"> {t('contact_email')}: {' '}
                                                        <a className="text-contact" style={{color: '#fff'}} href="mailto:contact@ova-corporate.com">
                                                            contact@ova-corporate.com
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="containerhh">
                                {/* <iframe src="https://www.google.com/maps/place/Ova+Consulting+SARL/@4.0851875,9.7413125,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x552f7d965911004!8m2!3d4.0851875!4d9.7413125?hl=fr" width="100%" height="100%" style={{border:'0px'}} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1884.0010451992248!2d9.741183646632777!3d4.085271443677229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x552f7d965911004!2sOva%20Consulting%20SARL!5e0!3m2!1sfr!2scm!4v1663936569919!5m2!1sfr!2scm" width="100%" height="100%" style={{border:0}} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </section>
                            <section className="contact p-0">

                                
                                <div className="contact p-0" >
                                    <div className="contact-container" style={{transform: 'none', borderRadius:0, boxShadow:'none', marginTop: '50px'}}>
                                        
                                    <div>
                                        <p className="text">
                                            {t('leave_us_message')}
                                        </p>
                                    </div>
                                    <div className="contact-form">
                                        {alert && <div className={`alert alert-${alert?.type}`}>
                                            {alert.message}
                                        </div>}
                                        <Formik
                                            initialValues={initialValues}
                                            onSubmit={ (values) => {
                                                handleSubmit(values)
                                            }}
                                        >
                                            <Form>
                                                <div className="form-group text-element">
                                                    <Field type="lastname" name="lastname" id="lastname" placeholder={t("home_contact_name")}/>
                                                </div>
                                                <div className="form-group text-element">
                                                    <Field type="firstname" name="firstname" id="firstname" placeholder={t("home_contact_surname")}/>
                                                </div>
                                                <div className="form-group email-element">
                                                    <Field type="email" name="email" id="email" placeholder={t("home_contact_email")}/>
                                                </div>
                                                <div className="form-group tel-element">
                                                    <Field type="tel" name="tel" id="tel" placeholder={t("home_contact_phone")}/>
                                                </div>
                                                <div className="form-group choices-element">
                                                    <div className="choice">
                                                        <input onChange={handleChangeNeed} checked={iWant === "WORK"} type="radio" name="work" id="work" value="WORK" />
                                                    <label htmlFor="work">{t("home_contact_i_want_work")}</label>
                                                    </div>
                                                    <div className="choice">
                                                        <input onChange={handleChangeNeed} checked={iWant === "ESTIMATION"} type="radio" name="work" id="work-2" value="ESTIMATION"/>
                                                    <label htmlFor="work-2">{t("home_contact_i_want_devis")}</label>
                                                    </div>
                                                </div>
                                                <div className="form-group textarea-element">
                                                    <Field as="textarea" name="message" id="message" cols={30} rows={10} placeholder={t("home_contact_message")}/>
                                                </div>
                                                <div className="form-actions">
                                                    <div className="form-group file-element">
                                                    <label htmlFor="cv">
                                                        {
                                                            file ? <span> {file.name} </span> : 

                                                            <span>{iWant ==="WORK" ? t("home_contact_cv") : t("contact_add_pdf_file")}</span>
                                                        }
                                                    </label>
                                                    <input onChange={handleChangeFile} type="file" accept="application/pdf" name="cv" id="cv"/>
                                                    </div>
                                                    <div className="form-group submit-element">
                                                    <button type="submit"><span>{t("home_contact_send")}</span></button>
                                                    </div>
                                                </div>
                                                
                                            </Form>
                                        </Formik>
                                        {alert && <div className={`alert alert-${alert?.type}`}>
                                            {alert.message}
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
                <Outlet/>
                <Footer/>
            </div>
        </Page>
    )
}