import * as React from 'react';
import Header from "../../../../components/header/Header";
import Page from "../../../../page/Page";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import theme from '../../../../components/theme/theme';
import { useNavigate, useParams } from 'react-router-dom';
import StorageIcon from '@mui/icons-material/Storage';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../stores/types';
import CartDrawer from '../cart/CartDrawer';

const drawerWidth = 250;

function ShopDrawer (props: any) {

    const { window, isCart } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [openCartDrawer, setOpenCartDrawer] = React.useState(false);
    const {store} = useParams();
    const navigate = useNavigate();
    const {cart} = useSelector((state: ApplicationState) => state.shop)


    const handleNavigate = (to: string) => {
        navigate(to);
    }

    const handleToggleCartDrawer = () => {
        setOpenCartDrawer(!openCartDrawer);
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            {/* <Toolbar />
            <Divider /> */}
            <List>
                <ListItem>
                    <ListItemButton onClick={() => handleNavigate("/ova-store/store/equipments")} sx={{ minWidth: '30px', height: {sm: "60px"}, borderRadius: '10px', backgroundColor: store === "equipments" ? theme.palette.secondary.main: ""}}>
                        <ListItemIcon>
                            <StorageIcon color={store === "equipments" ? "primary": "secondary"} />
                        </ListItemIcon>
                        <ListItemText
                        primaryTypographyProps={{
                            color: store === "equipments" ? "primary": "secondary",
                            fontWeight: 'medium',
                            // variant: 'body2',
                        }}
                        primary={"Equipments"} />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => handleNavigate("/ova-store/store/licenses")} sx={{ minWidth: '30px', height: {sm: "60px"}, borderRadius: '10px', backgroundColor: store === "licenses" ? theme.palette.secondary.main : ""}}>
                        <ListItemIcon>
                            <VpnKeyIcon sx={{ color: store === "licenses" ? "#fff": "" }}/>
                        </ListItemIcon>
                        <ListItemText 
                            primaryTypographyProps={{
                                color: store === "licenses" ? "primary": "secondary",
                                fontWeight: 'medium',
                                // variant: 'body2',
                            }}
                            primary={"Licenses"} />
                    </ListItemButton>
                </ListItem>
                <CartDrawer isCart />
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return(
        <>
            <CssBaseline />
            {/* <AppBar
                position="fixed"
                sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                    Responsive drawer
                </Typography>
                </Toolbar>
            </AppBar> */}
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, borderRadius: '1rem' },
                    
                }}
                >
                {drawer}
                </Drawer>
                <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, top: {sm: 120}, borderRadius: '1rem', marginLeft: {sm: '2rem'}},
                }}
                open
                >
                {drawer}
                </Drawer>
            </Box>
        </>
    )
}

export default ShopDrawer;