import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar } from '@mui/material';
import { Link } from "react-router-dom";



interface Props {
    title: string;
    description: string;
    image?: string;
    id: string;
    type: string;
    detailLink: string;
    applyText: string;
}



export default function Ovaccordion(props: Props) {

    const { title, description, id, image, type, detailLink, applyText } = props;

    const formatTitle = (title: string) => {
        let toDisplay = title;
        toDisplay = toDisplay.replace("OFFRE STAGE", "");
        toDisplay = toDisplay.replace("PROFFESSIONNEL", "");
        toDisplay = toDisplay.replace("PRO", "");
        toDisplay = toDisplay.replace(" – ", "");

        return toDisplay;
    }


    return(
        <div className="accordionSingle">
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={id}
                > 
                    <Avatar 
                        sx={{ width: 70, height: 70 }}
                        style={{background: "#f58d7b"}} 
                        src={image} /> {"  "}
                    <Typography className='mt-auto mb-auto accordion-title'>
                        <span>{formatTitle(title)}</span> <br />
                        <span>{type}</span>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='accordion-text' dangerouslySetInnerHTML={{__html: description.slice(0, 250) ?? ""}}>
                    </div>
                    <div className="link mt-4 mb-4">
                        <Link id={"button" + id} to={detailLink}>{applyText}</Link>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}