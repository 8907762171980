import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/footer/Footer";
import Header3 from "../../components/header/Header3";
import Page from "../../page/Page";


// Services and products.


export default function () {

    const { t } = useTranslation();

    const services = [
        {
            id: 1,
            title: t('swiper_slide_title_1'),
            description: t('swiper_slide_text_1'),
            image: '/assets/img/anounces/beyound-limits.jpg',
            tag: "beyong-the-limits"
        },
        {
            id: 2,
            title: t('swiper_slide_title_2'),
            description: t('swiper_slide_text_2'),
            image: '/assets/img/anounces/digital-workspace.jpeg',
            tag: "digital-work-place"
        },
        {
            id: 3,
            title: t('swiper_slide_title_3'),
            description: t('swiper_slide_text_3'),
            image: '/assets/img/anounces/agility.webp',
            tag: "agility-at-scale"
        },
        {
            id: 4,
            title: t('swiper_slide_title_4'),
            description: t('swiper_slide_text_4'),
            image: '/assets/img/anounces/journey.jpg',
            tag: "journey"
        }
    ]

    const products = [
        {
            id: 1,
            title: t("swiper_slide2_title_5"),
            description: t('swiper_slide2_text_5'),
            image: '/assets/img/axelor.png',
            tag: "axelor",
        },
        {
            id: 2,
            title: t("swiper_slide2_title_1"),
            description: t('swiper_slide2_text_1'),
            image: '/assets/img/onlyoffice_connector_Nuxeo-1.jpg',
            tag: "nuxeo",
        },
        {
            id: 3,
            title: t("swiper_slide2_title_2"),
            description: t('swiper_slide2_text_2'),
            image: '/assets/img/odoos.png',
            tag: "odoo",
        },
        {
            id: 4,
            title: t("swiper_slide2_title_3"),
            description: t('swiper_slide2_text_3'),
            image: '/assets/img/alfresco.png',
            tag: "alfresco",
        },
        {
            id: 5,
            title: t("swiper_slide2_title_4"),
            description: t('swiper_slide2_text_4'),
            image: '/assets/img/docuWare.jpg',
            tag: "docuWare",
        },
        {
            id: 5,
            title: t("swiper_slide_title_5"),
            description: t('swiper_slide2_text_5'),
            image: '/assets/img/bitrix24.jpg',
            tag: "bitrix24",
        },
        {
            id: 5,
            title: t("swiper_slide_title_6"),
            description: t('swiper_slide_text_6'),
            image: '/assets/img/fortinet.png',
            tag: "fortinet",
        }
    ]


    React.useEffect(() => {
    }, [])


    return (
        <Page
            title={"OVA - Consulting | " + t("anounces_title")}
            description={t("anounces_title")}
        >
            <div>
                {/* <!-- SCROLLER START --> */}
                {/* <div className="main-scroller">
                    <div className="link active" data-scroller="description">
                        <a className="link-text-no-effect" href="#description">
                            Description
                        </a>
                    </div>
                    <div className="link" data-scroller="services">
                        <a className="link-text-no-effect" href="#services">
                            {t('home_our_services')}
                        </a>
                    </div>
                    <div className="link" data-scroller="products">
                        <a className="link-text-no-effect" href="#products">
                            {t('home_our_products')}
                        </a>
                    </div>
                    <div className="scrolltop"></div>
                </div> */}
                {/* <!-- SCROLLER END --> */}
                <Header3/>
                <section style={{marginTop: "150px"}} id="description" >
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-3 col-12"></div>
                            <div className="col-lg-6 col-12">
                                <p className="template-page-title title" >
                                    <h1>
                                        {t("anounces_title")}
                                    </h1>
                                </p>
                                <h2 style={{fontFamily: 'Hurme'}} >
                                    <strong>
                                        {/* Sed ut perspiciatis unde omnis iste  */}
                                    </strong>
                                </h2>
                                <p className="about-us-text mt-3">
                                    {/* {t("about_text_p_2")} */}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '50px'}} >
                        <div className="col-lg-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad.png')", backgroundSize: 'cover'}}>
                            <div className="temp-h-500">
                            </div>
                        </div>
                        <div className="col-lg-0 col-6">
                            
                        </div>
                        <div className="col-lg-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad2.jpg')", backgroundSize: 'cover'}}>
                            <div className="temp-h-500">
                            </div>
                        </div>
                        <div className="col-lg-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad3.jpg')", backgroundSize: 'cover'}}>
                            <div className="temp-h-500">
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{marginTop: '50px'}} id="services">
                    <div className="container">
                        {
                            services.map((service, index) => {

                                if(index % 2 === 0) {

                                    return(

                                        <div key={'service' + index} className="row">
                                            <div className="col-lg-6 col-12">
                                                <div className="m-t-3">
                                                    <p className="template-page-title-text" id={service.tag} >
                                                    {service.title}
                                                    </p>
                                                    <p className="about-us-text mt-3">
                                                        {
                                                            service.description
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div>
                                                    <div className="text-template-img-rounded" style={{backgroundImage: `url(${service.image})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                                                        {/* <img src="assets/img/man-work-pad3.jpg" height={'100%'} alt="" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {

                                    return (

                                        <div key={'service' + index} className="row m-t-3 m-b-3">
                                            <div className="col-lg-6 col-12">
                                                <div>
                                                    <div className="text-template-img-rounded" style={{backgroundImage:`url(${service.image})`, backgroundSize: 'cover', backgroundPosition: 'bottom'}}>
                                                        {/* <img src="assets/img/man-work-pad3.jpg" height={'100%'} alt="" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="m-t-3">
                                                    <p className="template-page-title-text" id={service.tag}>
                                                        {service.title}
                                                    </p>
                                                    <p className="about-us-text mt-3">
                                                        {
                                                            service.description
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </section>
                <section id="products" className="text-align-left bg-green" style={{marginTop: "50px", paddingTop: '100px', paddingBottom: '100px'}} >
                    <div className="container">
                        <p className="template-page-title-text text-center" >
                            {/* {t('products')} */}
                        </p>
                        {/* <p className="about-us-text mt-3">
                            {t("about_text_p_2")}
                            {t("about_text_p_2")}
                            {t("about_text_p_2")}
                            {t("about_text_p_2")}
                        </p> */}
                    </div>
                    <div className="container">
                        {
                            products.map((service, index) => {

                                if(index % 2 === 0) {

                                    return(

                                        <div key={'service' + index} className="row">
                                            <div className="col-lg-6 col-12">
                                                <div className="m-t-3">
                                                    <p className="template-page-title-text" id={service.tag} >
                                                    {service.title}
                                                    </p>
                                                    <p className="about-us-text mt-3">
                                                        {
                                                            service.description
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div>
                                                    <div className="text-template-img-rounded" style={{backgroundImage: `url(${service.image})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: "no-repeat", backgroundColor: "#fff"}}>
                                                        {/* <img src="assets/img/man-work-pad3.jpg" height={'100%'} alt="" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {

                                    return (

                                        <div key={'service' + index} className="row m-t-3 m-b-3">
                                            <div className="col-lg-6 col-12">
                                                <div>
                                                    <div className="text-template-img-rounded" style={{backgroundImage:`url(${service.image})`, backgroundPosition: 'center', backgroundRepeat: "no-repeat", backgroundSize: "100% auto"}}>
                                                        {/* <img src="assets/img/man-work-pad3.jpg" height={'100%'} alt="" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="m-t-3">
                                                    <p className="template-page-title-text" id={service.tag}>
                                                        {service.title}
                                                    </p>
                                                    <p className="about-us-text mt-3">
                                                        {
                                                            service.description
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </section>
                <Footer/>
            </div>
        </Page>
    )
}