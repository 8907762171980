import { Grid } from "@mui/material";
import { CardProps, StoreArticle } from "../types/storeCard";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../stores/types";
import _ from "lodash";
import { addToCartEffect, removeFromCartEffect } from "../../../stores/effects/storeArticles";
import { useSnackbar } from "notistack";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
interface Props {
    key: string
}


function LoadingCard (props: Props) {

    const { key } = props;

    return(
        <Grid item md={4} key={key}>
            <Card sx={{ borderRadius: "1rem" }} className="product-card">
                <Stack spacing={1}>
                    {/* For variant="text", adjust the height via font-size */}
                    <Skeleton variant="rectangular" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="rectangular" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="rectangular" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="rectangular" sx={{ fontSize: '1rem' }} />

                    {/* For other variants, adjust the size with `width` and `height` */}
                    <Skeleton variant="rectangular" height={200} />
                </Stack>
            </Card>
        </Grid>
    )
}

export default LoadingCard;