import { useTranslation } from "react-i18next";



interface Props {
    title: string,
    description: string,
    nextButtonText: string,
    callBack: Function,
    id: string
}


export default function (props: Props) {

    const { title, description, nextButtonText, callBack, id } = props;

    const { t } = useTranslation();

    return (
        <div className="modal fade" id={id}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id={"modalLongTitle" + id}>{title}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {description}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('header_close')}</button>
                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => callBack('/contact-reason-WORK')} >{nextButtonText}</button>
                </div>
                </div>
            </div>
        </div>
    )
}