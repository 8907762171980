// Core modules imports are same as usual
import { Autoplay, Navigation, Pagination } from 'swiper';
// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react';

// Styles must use direct files imports
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import ProductCard from '../../../components/productCard/ProductCard';
import { StoreArticle } from '../../../components/types/storeCard';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { addToCartEffect, addToWishlistEffect, listArticlesEffect, removeFromCartEffect, removeFromWishlistEffect } from '../../../../stores/effects/storeArticles';
import { useEffect } from 'react';
import { ApplicationState } from '../../../../stores/types';

export interface Product {
  id: string;
  name: string;
  category: string;
  price: string;
  rate: number;
  image: string;
}

const productsList: Product[] = [
  {
    id: '1',
    name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ipsa veritatis voluptatem vero, saepe exercitationem perspiciatis. Voluptatem architecto cupiditate non quae nostrum numquam eum suscipit, voluptates vitae tempore error recusandae?',
    category: 'Logiciel Microsoft',
    rate: 4.5,
    image:
      'https://www.codesproduit.fr/wp-content/uploads/2021/10/office-2021-professional-plus-pc-300x400.jpg',
    price: '10.000 XAF'
  },
  {
    id: '2',
    name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ipsa veritatis voluptatem vero, saepe exercitationem perspiciatis. Voluptatem architecto cupiditate non quae nostrum numquam eum suscipit, voluptates vitae tempore error recusandae?',
    category: 'Logiciel Microsoft',
    rate: 4.5,
    image:
      'https://www.codesproduit.fr/wp-content/uploads/2021/10/office-2021-professional-plus-pc-300x400.jpg',
    price: '10.000 XAF'
  },
  {
    id: '3',
    name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ipsa veritatis voluptatem vero, saepe exercitationem perspiciatis. Voluptatem architecto cupiditate non quae nostrum numquam eum suscipit, voluptates vitae tempore error recusandae?',
    category: 'Logiciel Microsoft',
    rate: 4.5,
    image:
      'https://www.codesproduit.fr/wp-content/uploads/2021/10/office-2021-professional-plus-pc-300x400.jpg',
    price: '10.000 XAF'
  },
  {
    id: '4',
    name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ipsa veritatis voluptatem vero, saepe exercitationem perspiciatis. Voluptatem architecto cupiditate non quae nostrum numquam eum suscipit, voluptates vitae tempore error recusandae?',
    category: 'Logiciel Microsoft',
    rate: 4.5,
    image:
      'https://www.codesproduit.fr/wp-content/uploads/2021/10/office-2021-professional-plus-pc-300x400.jpg',
    price: '10.000 XAF'
  },
  {
    id: '5',
    name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ipsa veritatis voluptatem vero, saepe exercitationem perspiciatis. Voluptatem architecto cupiditate non quae nostrum numquam eum suscipit, voluptates vitae tempore error recusandae?',
    category: 'Logiciel Microsoft',
    rate: 4.5,
    image:
      'https://www.codesproduit.fr/wp-content/uploads/2021/10/office-2021-professional-plus-pc-300x400.jpg',
    price: '10.000 XAF'
  },
  {
    id: '6',
    name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ipsa veritatis voluptatem vero, saepe exercitationem perspiciatis. Voluptatem architecto cupiditate non quae nostrum numquam eum suscipit, voluptates vitae tempore error recusandae?',
    category: 'Logiciel Microsoft',
    rate: 4.5,
    image:
      'https://www.codesproduit.fr/wp-content/uploads/2021/10/office-2021-professional-plus-pc-300x400.jpg',
    price: '10.000 XAF'
  },
  {
    id: '7',
    name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ipsa veritatis voluptatem vero, saepe exercitationem perspiciatis. Voluptatem architecto cupiditate non quae nostrum numquam eum suscipit, voluptates vitae tempore error recusandae?',
    category: 'Logiciel Microsoft',
    rate: 4.5,
    image:
      'https://www.codesproduit.fr/wp-content/uploads/2021/10/office-2021-professional-plus-pc-300x400.jpg',
    price: '10.000 XAF'
  },
  {
    id: '8',
    name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ipsa veritatis voluptatem vero, saepe exercitationem perspiciatis. Voluptatem architecto cupiditate non quae nostrum numquam eum suscipit, voluptates vitae tempore error recusandae?',
    category: 'Logiciel Microsoft',
    rate: 4.5,
    image:
      'https://www.codesproduit.fr/wp-content/uploads/2021/10/office-2021-professional-plus-pc-300x400.jpg',
    price: '10.000 XAF'
  }
];

interface Props {
  itemsPer?: number,
  hideTitle?: boolean
}

export default function StoreBlock(props: Props) {

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const { articles } = useSelector((state: ApplicationState) => state.shop)


  const handleAddToCart = (article: StoreArticle, remove: boolean) => {
    if(remove){
      // Remove from cart
      dispatch(removeFromCartEffect({...article}, enqueueSnackbar));
    } else {
      dispatch(addToCartEffect({...article}, enqueueSnackbar));
    }
  }
  const handleAddToWishlist = (article: StoreArticle, remove: boolean) => {
    if(remove){
      dispatch(removeFromWishlistEffect({...article}, enqueueSnackbar));
    } else {
      dispatch(addToWishlistEffect({...article}, enqueueSnackbar));
    }
  }

  useEffect(() => {
    dispatch(listArticlesEffect())
  }, []);


  return (
    <section className="block-store">
        {/* {!props.hideTitle && <div className="block-store-title">LES INTERNAUTES ONT ACHETE</div>} */}
      <Swiper
        slidesPerView={props.itemsPer ?? 6}
        spaceBetween={30}
        loop={true}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        navigation={true}
        pagination={{
          clickable: true
        }}
        modules={[Navigation, Autoplay]}
        breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: props.itemsPer ?? 5,
              spaceBetween: 30,
            },
          }}
        className="block-store-swiper">
            {
                articles?.results.map((product, index) => {
                    return(
                        <SwiperSlide className='block-store-swiper-item' key={"k_" + index}>
                            <ProductCard handleAddToWishlist={handleAddToWishlist} handleAddToCart={handleAddToCart} product={product} />
                        </SwiperSlide>
                    )
                })
            }
      </Swiper>
    </section>
  );
}
