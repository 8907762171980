import { Button, Card, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DefaultButton } from './DefaultButton'


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }

interface ShippingAddressTypes {
    shippingName: string
    streetName: string
    city: string
    stateProvince: string
    country: string
}


function ShippingAddress () {

    const [shippingName, setShippingName] = useState<string>("");
    const [streetName, setStreetName] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [stateProvince, setStateProvince] = useState<string>("");
    const [country, setCountry] = useState<string>("");
    const [saveShippingDetails, setSaveShippingDetails] = useState(false);
    
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    
    const handleChangeShippingName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShippingName(event.target.value);
    };

    const handleChangeStreetName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStreetName(event.target.value);
    };

    const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);
    };

    const handleChangeStateProvince = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStateProvince(event.target.value);
    };

    const handleChangeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCountry(event.target.value);
    };

    const handleChangeSaveShippingDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSaveShippingDetails(event.target.checked);
    };

    return(
        <Card sx={{padding: '1rem'}}>
            <Typography variant="h5" className="text-left" >Shipping Address</Typography>
            <Grid container>
                <Grid item md={8} xs={12}>
                    <Grid>
                        <Box sx={{textAlign: "left"}}>
                            <Typography> {shippingName} </Typography>
                            <Typography> {streetName} </Typography>
                            <Typography> {city} </Typography>
                            <Typography> {stateProvince} </Typography>
                            <Typography> {country} </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Grid>
                        <Button variant="outlined" onClick={handleClickOpen}>Change</Button>
                        <BootstrapDialog
                            onClose={handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                            maxWidth={"sm"}
                            fullWidth={true}
                        >
                            <DialogTitle sx={{ m: 0, p: 2 }}>
                                <Typography> Add Shipping address </Typography>
                                    <IconButton
                                    aria-label="close"
                                    onClick={handleClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                    >
                                    <CloseIcon />
                                    </IconButton>
                            </DialogTitle>
                            <DialogContent dividers>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { width: '100%', marginBottom: "1rem" },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    >
                                    <TextField 
                                        id="outlined-basic" 
                                        value={shippingName} 
                                        onChange={handleChangeShippingName} 
                                        label="Shipping Name"
                                        placeholder="Dakossa Djatsa"
                                        variant="outlined" />
                                    <TextField 
                                        id="outlined-street" 
                                        label="Street Name" 
                                        value={streetName} 
                                        onChange={handleChangeStreetName} 
                                        placeholder="123 Plae Gront Street" 
                                        variant="outlined" />
                                    <TextField 
                                        id="outlined-city" 
                                        label="City" 
                                        placeholder="Vermont" 
                                        value={city} 
                                        onChange={handleChangeCity} 
                                        variant="outlined" />
                                    <TextField 
                                        id="outlined-state" 
                                        label="State / Province" 
                                        placeholder="California"
                                        value={stateProvince} 
                                        onChange={handleChangeStateProvince} 
                                        variant="outlined" />
                                    <TextField 
                                        id="outlined-country" 
                                        label="Country" 
                                        placeholder="Cameroon" 
                                        value={country}
                                        onChange={handleChangeCountry}
                                        variant="outlined" />
                                    <FormControlLabel
                                        value="end"
                                        control={
                                            <Checkbox 
                                            onChange={handleChangeSaveShippingDetails}
                                            checked={saveShippingDetails} />
                                        }
                                        label="Save this as your default address"
                                        labelPlacement="end"
                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                            <DefaultButton onClick={handleClose}>
                                Cancel
                            </DefaultButton>
                            <Button autoFocus variant="contained" color="primary" onClick={handleClose}>
                                Save changes
                            </Button>
                            </DialogActions>
                        </BootstrapDialog>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}

export default ShippingAddress;