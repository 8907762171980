
import React from "react";
import {Helmet} from "react-helmet";

interface Props {
    children?: any,
    title: string,
    description?: string
}


function Page(props: Props) {

    const { children, title, description } = props

    return(
        <main>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta property="og:title" content="Site Title" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://ova-consulting.fr" />
                <meta property="og:image" content="https://ova-corporate.com/logo_white.png" />
                <meta property="og:description" content={description} />
                <meta name="theme-color" content="#151D41"/>
                <meta id="og-title" property="title" content={title} />
                {/* <meta id="og-image" property="image" content="/favicon.svg" /> */}
                <link rel="canonical" href="https://ova-consulting.fr" />
                {/* <meta name="description" content={description} /> */}
            </Helmet>
            {children}
        </main>
    )
}


export default Page