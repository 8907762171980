import { ArrowBack } from "@mui/icons-material";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Header3 from "../../../../components/header/Header3";
import Page from "../../../../page/Page";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ShippingAddress from "./components/shipping/ShippingAddress";
import PaymentMethods from "./components/shipping/PaymentMethods";
import Footer from "../../../../components/footer/Footer";




function Checkout () {

    const { store } = useParams();

    const navigate = useNavigate();

    const handleNavigate = (url:string) => {
        navigate(url);
    }


    return (
        <Page title="OVA Consulting | Store checkout" >
            <>
                <Header3/>
                <section className="checkout-page">
                    <div className="container">
                        <div className="d-flex mb-3">
                            <Button 
                                startIcon={<ArrowBack color="primary"/>} 
                                onClick={() => handleNavigate(`/ova-store/${store}/cart`)
                                }
                                variant="outlined"> GO BACK </Button>
                        </div>
                        <Grid container spacing={3}>
                            <Grid item md={9}>
                                <Grid sx={{marginBottom: "1rem"}}>
                                    <ShippingAddress/>
                                </Grid>
                                <Grid>
                                    <PaymentMethods/>
                                </Grid>
                            </Grid>
                            <Grid item md={3}  xs={12}>
                                <Box>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h5">Checkout summary</Typography>
                                            <TableContainer>
                                                <Table aria-label="simple table">
                                                    <TableBody>
                                                        <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row" color="text.secondary">
                                                                {"Items"}
                                                            </TableCell>
                                                            <TableCell align="right">{"$ 100.3"}</TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row" color="text.secondary">
                                                                {"Shipping fees"}
                                                            </TableCell>
                                                            <TableCell align="right">{"$ 0"}</TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row" color="error">
                                                                <span className="text-error">{"Order total"}</span>
                                                            </TableCell>
                                                            <TableCell align="right"><span className="text-error" >{"$ 100.3"}</span></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Button variant="contained">Place your order</Button>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </section>
                <Footer/>
            </>
        </Page>
    );
}


export default Checkout;