import { motion } from "framer-motion";
import { useState } from "react";
import { useTranslation } from "react-i18next"



function OurTeam1 () {

    const { t } = useTranslation();
    const [showAll, setShowAll] = useState(false);

    return (
        <section id="our-team" className="our-team-1">
            <div className="container">
                <p className="title">
                    {t("our_team")}
                </p>
                <motion.div
                    transition={{type: "spring"}}
                >
                    <div className="team-tabs">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-executive-team-tab" data-toggle="pill" data-target="#pills-executive-team" type="button" role="tab" aria-controls="pills-executive-team" aria-selected="true">{t("Executive Team")}</button>
                            </li>
                            {/* <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-production-team-tab" data-toggle="pill" data-target="#pills-production-team" type="button" role="tab" aria-controls="pills-production-team" aria-selected="false">{t("Production Team")}</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-com-team-tab" data-toggle="pill" data-target="#pills-com-team" type="button" role="tab" aria-controls="pills-com-team" aria-selected="false">{t("Team Com")}</button>
                            </li> */}
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-executive-team" role="tabpanel" aria-labelledby="pills-executive-team-tab">
                                <div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div>
                                                <div className="team-card text-center">
                                                    <div className="avatar" style={{backgroundImage: "url(/assets/img/avatar.jpeg)"}} >
                                                        {/* <img src="/assets/img/avatar.jpeg" alt="OVA consulting CEO" /> */}
                                                    </div>
                                                    <div className="description">
                                                        <p className="team-name">Eric NEOSSI </p>
                                                        <p className="team-role">{t("Founder, CEO")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div>
                                                <div className="team-card text-center">
                                                    <div className="avatar" style={{backgroundImage: "url(/assets/img/avatar.jpeg)"}} >
                                                        {/* <img src="/assets/img/avatar.jpeg" alt="OVA consulting CEO" /> */}
                                                    </div>
                                                    <div className="description">
                                                        <p className="team-name">Franck MBATCHANKUI </p>
                                                        <p className="team-role">COO</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div>
                                                <div className="team-card text-center">
                                                    <div className="avatar" style={{backgroundImage: "url(/assets/img/avatar.jpeg)"}} title="OVA consulting CTO">
                                                        {/* <img src="/assets/img/avatar.jpeg" alt="OVA consulting CEO" /> */}
                                                    </div>
                                                    <div className="description">
                                                        <p className="team-name">Dakossa Djatsa </p>
                                                        <p className="team-role">CTO</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-production-team" role="tabpanel" aria-labelledby="pills-production-team-tab">
                                <div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div>
                                                <div className="team-card text-center">
                                                    <div className="avatar" style={{backgroundImage: "url(/assets/img/avatar.jpeg)"}} >
                                                        {/* <img src="/assets/img/avatar.jpeg" alt="OVA consulting CEO" /> */}
                                                    </div>
                                                    <div className="description">
                                                        <p className="team-name">Franck MBATCHANKUI </p>
                                                        <p className="team-role">{t("Operations Manager")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div>
                                                <div className="team-card text-center">
                                                    <div className="avatar" style={{backgroundImage: "url(/assets/img/avatar.jpeg)"}} >
                                                        {/* <img src="/assets/img/avatar.jpeg" alt="OVA consulting CEO" /> */}
                                                    </div>
                                                    <div className="description">
                                                        <p className="team-name">Dakossa DJATSA </p>
                                                        <p className="team-role">{t("Tech Lead")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div>
                                                <div className="team-card text-center">
                                                    <div className="avatar" style={{backgroundImage: "url(/assets/img/avatar.jpeg)"}} >
                                                        {/* <img src="/assets/img/avatar.jpeg" alt="OVA consulting CEO" /> */}
                                                    </div>
                                                    <div className="description">
                                                        <p className="team-name">Arnaud TOKO </p>
                                                        <p className="team-role">{t("Business Developer")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div>
                                                <div className="team-card text-center">
                                                    <div className="avatar" style={{backgroundImage: "url(/assets/img/avatar.jpeg)"}} >
                                                        {/* <img src="/assets/img/avatar.jpeg" alt="OVA consulting CEO" /> */}
                                                    </div>
                                                    <div className="description">
                                                        <p className="team-name">Arnold </p>
                                                        <p className="team-role">{t("UX/UI Designer, Graphic Designer")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div>
                                                <div className="team-card text-center">
                                                    <div className="avatar" style={{backgroundImage: "url(/assets/img/avatar.jpeg)"}} >
                                                        {/* <img src="/assets/img/avatar.jpeg" alt="OVA consulting CEO" /> */}
                                                    </div>
                                                    <div className="description">
                                                        <p className="team-name">PatricK </p>
                                                        <p className="team-role">{t("Project manager")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div>
                                                <div className="team-card text-center">
                                                    <div className="avatar" style={{backgroundImage: "url(/assets/img/avatar.jpeg)"}} >
                                                        {/* <img src="/assets/img/avatar.jpeg" alt="OVA consulting CEO" /> */}
                                                    </div>
                                                    <div className="description">
                                                        <p className="team-name">Vannick NONONGO </p>
                                                        <p className="team-role">{t("Software Engineer")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        { showAll && <><div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div>
                                                <div className="team-card text-center">
                                                    <div className="avatar" style={{backgroundImage: "url(/assets/img/avatar.jpeg)"}} >
                                                        {/* <img src="/assets/img/avatar.jpeg" alt="OVA consulting CEO" /> */}
                                                    </div>
                                                    <div className="description">
                                                        <p className="team-name">Eric NEOSSI </p>
                                                        <p className="team-role">{t("Software Achitech")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div>
                                                <div className="team-card text-center">
                                                    <div className="avatar" style={{backgroundImage: "url(/assets/img/avatar.jpeg)"}} >
                                                        {/* <img src="/assets/img/avatar.jpeg" alt="OVA consulting CEO" /> */}
                                                    </div>
                                                    <div className="description">
                                                        <p className="team-name">Cedric  </p>
                                                        <p className="team-role">{t("Inra/Security Expert")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div>
                                                <div className="team-card text-center">
                                                    <div className="avatar" style={{backgroundImage: "url(/assets/img/avatar.jpeg)"}} >
                                                        {/* <img src="/assets/img/avatar.jpeg" alt="OVA consulting CEO" /> */}
                                                    </div>
                                                    <div className="description">
                                                        <p className="team-name">Vincent  </p>
                                                        <p className="team-role">{t("Software Engineer")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div>
                                                <div className="team-card text-center">
                                                    <div className="avatar" style={{backgroundImage: "url(/assets/img/avatar.jpeg)"}} >
                                                        {/* <img src="/assets/img/avatar.jpeg" alt="OVA consulting CEO" /> */}
                                                    </div>
                                                    <div className="description">
                                                        <p className="team-name">Anicet  </p>
                                                        <p className="team-role">{t("Network expert and Azure Specialist")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> </>}
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex h-100">
                                                <button onClick={() => setShowAll(!showAll)}  className="btn btn-primary btn-rounded m-auto">
                                                    {showAll? t("see_less") : t("see_more")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-com-team" role="tabpanel" aria-labelledby="pills-com-team">
                                <div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div>
                                                <div className="team-card text-center">
                                                    <div className="avatar" style={{backgroundImage: "url(/assets/img/avatar.jpeg)"}} >
                                                        {/* <img src="/assets/img/avatar.jpeg" alt="OVA consulting CEO" /> */}
                                                    </div>
                                                    <div className="description">
                                                        <p className="team-name">Karim AYOMI </p>
                                                        <p className="team-role">{t("Sales Manager, Executive Assistant")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div>
                                                <div className="team-card text-center">
                                                    <div className="avatar" style={{backgroundImage: "url(/assets/img/avatar.jpeg)"}} title="OVA consulting CTO">
                                                        {/* <img src="/assets/img/avatar.jpeg" alt="OVA consulting CEO" /> */}
                                                    </div>
                                                    <div className="description">
                                                        <p className="team-name">Rhyvelle MONTHE </p>
                                                        <p className="team-role">{t("Commercial")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div>
                                                <div className="team-card text-center">
                                                    <div className="avatar" style={{backgroundImage: "url(/assets/img/avatar.jpeg)"}} title="OVA consulting CTO">
                                                        {/* <img src="/assets/img/avatar.jpeg" alt="OVA consulting CEO" /> */}
                                                    </div>
                                                    <div className="description">
                                                        <p className="team-name">Steve TOKO </p>
                                                        <p className="team-role">{t("Comunity manager")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
                <div className="row"></div>
            </div>
        </section>
    )
}


export default OurTeam1