import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listArticlesEffect } from '../../../../../../stores/effects/storeArticles';
import { ApplicationState } from '../../../../../../stores/types';
import StoreCard from '../../../../../components/card';
import LoadingCard from '../../../../../components/card/LoadingCard';

function EquipementsStore(props: any) {
  const dispatch = useDispatch();

  const { loading, articles } = useSelector(
    (state: ApplicationState) => state.shop
  );

  useEffect(() => {
    dispatch(listArticlesEffect());
  }, []);

  return (
    <section>
      <Grid container spacing={2}>
        {loading.loading_articles_request &&
          [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
            return <LoadingCard key={`${item}`} />;
          })}
        {articles.results.map((article) => {
          return <StoreCard product={article} />;
        })}
      </Grid>
    </section>
  );
}

export default EquipementsStore;
