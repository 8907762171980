import { motion } from "framer-motion";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";


export default function Strategies () {

    const {t} = useTranslation();
    const [ image, setImage ] = useState('/assets/img/maintenance.png')

    useEffect(() => {
        // @ts-ignore
        window.$('#straegyCarousel').on('slide.bs.carousel', function (e) {
            // do something…
            // @ts-ignore
            var active = window.$(e.target).find('.carousel-item.active');
            // @ts-ignore
            var next = window.$(e.relatedTarget);
            // //console.log('lklkl ===> ', next)
            // @ts-ignore
            // window.$('.strategies-caousel-icon').attr('src', next.attr('data-image'))
            setImage(next.attr('data-image'))
        })
    }, [])

    return (

        <section id="our-strategy" className="hiw strategy-section section" style={{paddingBottom: '40px'}}>
                <div className="container">
                    <div className="hiw-highlight">
                        <div className="title">
                            {t("our_strategy")} 
                        </div>
                        <div className="excerpt">
                            {t("how_it_work_text_1")}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-sm-6 col-12 mb-4 mt-4">
                            <div className="d-flex h-100">
                                <div className="m-auto">
                                <motion.div
                                    key={image}
                                    initial={{ scale: 0 }}
                                    animate={{ rotate: 0, scale: 1 }}
                                    transition={{
                                        type: "spring",
                                        // stiffness: 260,
                                        // damping: 20,
                                        duration: 2
                                    }}
                                    // className="strategies-caousel-icon"
                                >
                                    {/* when state {image} value change, this component will re-render with new src */}
                                    <img width={'100%'} className="m-auto strategies-caousel-icon" src={image} alt="" />
                                </motion.div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12">
                            <div>
                            <div id="straegyCarousel" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-image="/assets/img/maintenance.png" style={{minHeight: '300px'}}>
                                <div className="row">
                                    {/* <div className="col-lg-6 col-sm-6 col-12">
                                        <div>
                                            <div>
                                                <div className="picture active d-flex">
                                                    <div className="img m-auto">
                                                        {/* <img src="/assets/img/methods.png" alt="..." /> *
                                                        <img width={'400px'} className="m-auto" src="/assets/img/maintenance.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-12">
                                        <div className="carousel-right">
                                            <div>
                                                <div className="swiper-slide " data-slide-id="methods">
                                                    <div className="title digit-slider-title">
                                                    <a href="/our-strategy#maintenance">
                                                        {t("how_it_work_1")}
                                                    </a>
                                                    </div>
                                                    <div className="excerpt">
                                                    {t("how_it_work_1_text")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" data-image="/assets/img/architect.png" style={{minHeight: '300px'}}>
                                <div className="row">
                                    {/* <div className="col-lg-6 col-sm-6 col-12">
                                        <div>
                                            <div>
                                                <div className="picture d-flex">
                                                    <div className="img m-auto">
                                                        {/* <img src="/assets/img/methods.png" alt="..." /> /}
                                                        <img width={'400px'} className="m-auto" src="/assets/img/architect.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-12">
                                        <div className="carousel-right">
                                            <div>
                                                <div className="swiper-slide" data-slide-id="architect">
                                                    <div className="title digit-slider-title">
                                                        <a href="/our-strategy#architect">{t("how_it_work_2")}</a>
                                                    </div>
                                                    <div className="excerpt">
                                                    {t("how_it_work_2_text")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" data-image="/assets/img/devops.png" style={{minHeight: '300px'}}>
                                <div className="row">
                                    {/* <div className="col-lg-6 col-sm-6 col-12">
                                        <div className="h-100">
                                            <div className="h-100 d-flex">
                                                <div className="img m-auto h-100 d-flex">
                                                    {/* <img src="/assets/img/methods.png" alt="..." /> /}
                                                    <img width={'400px'} className="m-auto devops-slide" src="/assets/img/devops.png" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-12">
                                        <div className="carousel-right">
                                            <div>
                                                <div className="swiper-slide" data-slide-id="devops">
                                                    <div className="title digit-slider-title">
                                                        <a href="/our-strategy#budget">{t("how_it_work_3")}</a>
                                                    </div>
                                                    <div className="excerpt">
                                                    {t("how_it_work_3_text")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" data-image="/assets/img/cloud.png" style={{minHeight: '300px'}}>
                                <div className="row">
                                    {/* <div className="col-lg-6 col-sm-6 col-12">
                                        <div>
                                            <div>
                                                <div className="picture active d-flex">
                                                    <div className="img m-auto">
                                                        {/* <img src="/assets/img/methods.png" alt="..." /> /}
                                                        <img width={'400px'} className="m-auto" src="/assets/img/cloud.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-12">
                                        <div className="carousel-right">
                                            <div>
                                                <div className="swiper-slide" data-slide-id="cloud">
                                                    <div className="title digit-slider-title">
                                                    <a href="/our-strategy#cloud">{t("how_it_work_4")}</a>
                                                    </div>
                                                    <div className="excerpt">
                                                    {t("how_it_work_4_text")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" data-image="/assets/img/methods.png" style={{minHeight: '300px'}}>
                                <div className="row">
                                    {/* <div className="col-lg-6 col-sm-6 col-12">
                                        <div>
                                            <div>
                                                <div className="picture d-flex">
                                                    <div className="img m-auto">
                                                        {/* <img src="/assets/img/methods.png" alt="..." /> /}
                                                        <img width={'400px'} className="m-auto" src="/assets/img/methods.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-12">
                                        <div className="carousel-right">
                                            <div>
                                                <div className="swiper-slide swiper-slide-active" data-slide-id="maintenance">
                                                    <div className="title digit-slider-title">
                                                        <a href="/our-strategy#methods">{t("how_it_work_5")}</a>
                                                    </div>
                                                    <div className="excerpt">
                                                    {t("how_it_work_5_text")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" data-image="/assets/svg/saas-big.svg" style={{minHeight: '300px'}}>
                                <div className="row">
                                    {/* <div className="col-lg-6 col-sm-6 col-12">
                                        <div>
                                            <div>
                                                <div className="picture active d-flex">
                                                    <div className="img m-auto">
                                                        {/* <img src="/assets/img/methods.png" alt="..." /> /}
                                                        <img className="m-auto" width={'400px'} src="/assets/svg/saas-big.svg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-12">
                                        <div className="carousel-right">
                                            <div>
                                                <div className="swiper-slide swiper-slide-active" data-slide-id="maintenance">
                                                    <div className="title digit-slider-title">
                                                        <a href="/our-strategy#saas-big">{t("how_it_work_6")}</a>    
                                                    </div>
                                                    <div className="excerpt">
                                                    {t("how_it_work_6_text")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" data-image="/assets/svg/dev.svg" style={{minHeight: '300px'}}>
                                <div className="row">
                                    {/* <div className="col-lg-6 col-sm-6 col-12">
                                        <div>
                                            <div>
                                                <div className="picture active d-flex">
                                                    <div className="img m-auto">
                                                        {/* <img src="/assets/img/methods.png" alt="..." /> /}
                                                        <img width={'400px'} className="m-auto" src="/assets/svg/dev.svg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-12">
                                        <div className="carousel-right">
                                            <div>
                                                <div className="swiper-slide swiper-slide-active" data-slide-id="maintenance">
                                                    <div className="title digit-slider-title">
                                                        <a href="/our-strategy#dev">{t("how_it_work_7")}</a>
                                                    </div>
                                                    <div className="excerpt">
                                                    {t("how_it_work_7_text")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" data-image="/assets/svg/tools-big.svg" style={{minHeight: '300px'}}>
                                <div className="row">
                                    {/* <div className="col-lg-6 col-sm-6 col-12">
                                        <div>
                                            <div>
                                                <div className="picture active d-flex">
                                                    <div className="img m-auto">
                                                        {/* <img src="/assets/img/methods.png" alt="..." /> /}
                                                        <img width={'400px'} className="m-auto" src="/assets/svg/tools-big.svg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-12">
                                        <div className="carousel-right">
                                            <div>
                                                <div className="swiper-slide swiper-slide-active" data-slide-id="maintenance">
                                                    <div className="title digit-slider-title">
                                                        <a href="/our-strategy#tools-big">{t("how_it_work_8")}</a>
                                                    </div>
                                                    <div className="excerpt">
                                                    {t("how_it_work_8_text")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" data-image="/assets/svg/marketing-big.svg" style={{minHeight: '300px'}}>
                                <div className="row">
                                    {/* <div className="col-lg-6 col-sm-6 col-12">
                                        <div>
                                            <div>
                                                <div className="picture active d-flex">
                                                    <div className="img m-auto">
                                                        {/* <img src="/assets/img/methods.png" alt="..." /> /}
                                                        <img width={'400px'} className="m-auto" src="/assets/svg/marketing-big.svg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-12">
                                        <div className="carousel-right">
                                            <div>
                                                <div className="swiper-slide swiper-slide-active" data-slide-id="maintenance">
                                                    <div className="title digit-slider-title">
                                                        <a href="/our-strategy#marketing">{t("how_it_work_9")}</a>
                                                    
                                                    </div>
                                                    <div className="excerpt">
                                                    {t("how_it_work_9_text")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item"  data-image="/assets/svg/pwa-big.svg" style={{minHeight: '300px'}}>
                                <div className="row">
                                    {/* <div className="col-lg-6 col-sm-6 col-12">
                                        <div>
                                            <div>
                                                <div className="picture active d-flex">
                                                    <div className="img m-auto">
                                                        {/* <img src="/assets/img/methods.png" alt="..." /> /}
                                                        <img width={'400px'} className="m-auto" src="/assets/svg/pwa-big.svg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-12">
                                        <div className="carousel-right">
                                            <div>
                                                <div className="swiper-slide swiper-slide-active" data-slide-id="maintenance">
                                                    <div className="title digit-slider-title">
                                                        <a href="/our-strategy#pwa">{t("how_it_work_10")}</a>
                                                    </div>
                                                    <div className="excerpt">
                                                    {t("how_it_work_10_text")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="strategy-caroussel-controls">
                            <div className="row">
                                <div className="col-12">
                                    <div>
                                    {/* <a className="carousel-control-prev" href="#straegyCarousel" role="button" data-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next" href="#straegyCarousel" role="button" data-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a> */}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="swiper-controls">
                                        <a href="#straegyCarousel" role="button" className="swiper-previous"  data-slide="prev" tabIndex={0}  aria-label="Previous slide"></a>
                                        <div className="swiper-counter" id="strategySwiperCounter">1</div>
                                        <a href="#straegyCarousel" className="swiper-next"  data-slide="next" tabIndex={0} role="button" aria-label="Next slide"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="strategy-caroussel-controls-bars">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div>
                                    </div>
                                </div>
                                <div className="col-12 ">
                                    <div className="">
                                    <ol className="digit-carousel-indicators carousel-indicators" style={{marginLeft: '1rem', width: 'fit-content'}}>
                                            <li data-target="#straegyCarousel" style={{background:'#0092893d'}} data-slide-to="0" className="active"></li>
                                            <li data-target="#straegyCarousel" style={{background:'#0092893d'}} data-slide-to="1"></li>
                                            <li data-target="#straegyCarousel" style={{background:'#0092893d'}} data-slide-to="2"></li>
                                            <li data-target="#straegyCarousel" style={{background:'#0092893d'}} data-slide-to="3"></li>
                                            <li data-target="#straegyCarousel" style={{background:'#0092893d'}} data-slide-to="4"></li>
                                            <li data-target="#straegyCarousel" style={{background:'#0092893d'}} data-slide-to="5"></li>
                                            <li data-target="#straegyCarousel" style={{background:'#0092893d'}} data-slide-to="6"></li>
                                            <li data-target="#straegyCarousel" style={{background:'#0092893d'}} data-slide-to="7"></li>
                                            <li data-target="#straegyCarousel" style={{background:'#0092893d'}} data-slide-to="8"></li>
                                            <li data-target="#straegyCarousel" style={{background:'#0092893d'}} data-slide-to="9"></li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}