import * as React from 'react';
import { makeStyles } from '@mui/styles';
import SecureLS from 'secure-ls';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { header3CssEffects } from './components/utils/header3Animations';
import { redirect } from '../utils/utils';
import HeaderUtil from './components/utils/HeaderUtil';
import { ApplicationState } from '../../../stores/types';
import { changeLanguage } from '../../../stores/actions';
import { InternationalisationTypes } from '../../../stores/types/InternationalizationTypes';
import { Link, useNavigate } from 'react-router-dom';

var localStorage = new SecureLS({ encodingType: 'aes' });

const useStyles = makeStyles({
  root: {
    backgroundColor: 'red'
  },
  myOvButton: {
    background: 'transparent',
    width: 'auto',
    padding: 0,
    borderRadius: 28,
    '& hover': {
      border: 0,
      background: '#51b4b0'
    }
  }
});

export default function Header () {
  const classNames = useStyles();
  const { i18n, t } = useTranslation();
  const language = navigator.language;

  const [lang, setLang] = React.useState('');

  const {
    recruitments: {
      loading: { loading_recruitment_request, loading_recruitment_success },
      recruitments
    }
  } = useSelector((state: ApplicationState) => state);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChangeLang = (lng: InternationalisationTypes) => {
    if (typeof lng !== 'undefined') {
      i18n.changeLanguage(lng.toLowerCase());
      localStorage.set('language', lng.toLowerCase());
      setLang(lng.toLowerCase());
      dispatch(changeLanguage(lng));
    }
  };

  React.useEffect(() => {
    const curr_lang = localStorage.get('language');
    setLang(curr_lang);
    dispatch(changeLanguage(curr_lang));
  }, []);

  React.useEffect(() => {
    header3CssEffects();
    // detect menu click
    window.addEventListener('click', function (e) {
      // //console.log("document.querySelector('.js-open') ===>", document.querySelector('.js-open'))

      // If drawer is already visible
      // @ts-ignore
      // alert(!document.querySelector('.menu-toggle')?.contains(e.target))
      // @ts-ignore
      if (!document.querySelector('.menu-toggle')?.contains(e.target)) {
        // @ts-ignore
        if (document.querySelector('.js-open')?.contains(e.target)) {
          // Clicked in box
          // //console.log("Clicked in box")
        } else {
          // Clicked outside the box
          document.querySelector('.main-menu')?.classList.remove('js-open');
        }
      }
    });

    HeaderUtil();
    // Scroll event detector

    // @ts-ignore
    window.$(window).scroll(function () {
      var aTop = $('.main-header').height();
      // @ts-ignore
      // console.log(window.$(this).scrollTop(), aTop)
      // @ts-ignore
      if (window.$(this).scrollTop() >= aTop) {
        // alert('header just passed.');

        // @ts-ignore
        window.$('header.main-header').addClass('js-scrolled');
        // instead of alert you can use to show your ad
        // something like $('#footAd').slideup();
      } else {
        // @ts-ignore
        window.$('header.main-header').removeClass('js-scrolled');
      }
    });
    // @ts-ignore
    window.$('.menu-toggle').click(function () {
      console.log('Clicked ....');

      // @ts-ignore
      window.$('.main-menu').toggleClass('js-open');
    });
  }, []);

  return (
    <header className="main-header">
      <div className="menu-toggle">
        <div
          id="header3-toggle-bar1"
          className="toggle-bar"
          style={{ backgroundColor: '#009289' }}></div>
        <div
          id="header3-toggle-bar2"
          className="toggle-bar"
          style={{ backgroundColor: '#009289' }}></div>
        <div
          id="header3-toggle-bar3"
          className="toggle-bar"
          style={{ backgroundColor: '#009289' }}></div>
      </div>
      <div className="main-menu">
        <div className="menu-toggle">{t('header_close')}</div>

        <div className="menu-bg"></div>
        <div className="menu-links">
          <Link to="/" className="menu-link">
            {t('home')}
          </Link>
          <Link to="/about" className="menu-link">
            {t('home_about')}
          </Link>
          <Link to="/expertises" className="menu-link">
            {t('our_experties')}
          </Link>
          <Link to="/trainings" className="menu-link">
            {t('our_trainings')}
          </Link>
          <Link to="/recruitment" className="menu-link">
            {t('header_recruitment')}
          </Link>
          <Link to="/contact" className="menu-link">
            Contact
          </Link>
        </div>
        <div className="menu-footer">
          <div className="logo"></div>
          <div className="socials">
            <div className="instagram">
              <Link to="http://instagram.com"></Link>
            </div>
            <div className="twitter">
              <Link to="http://twitter.com"></Link>
            </div>
            <div className="facebook">
              <Link to="https://www.facebook.com/Ova-consulting-sarl-102490568946434"></Link>
            </div>
            <div className="behance">
              <Link to="http://behance.com"></Link>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div
          id="header3-company"
          className="company"
          style={{ backgroundImage: 'none' }}
          onClick={() =>
            redirect('https://erp-ova.ova-consulting.fr/', navigate, true)
          }>
          <img
            id="heaader3-green-logo"
            src="/assets/img/logo-green.png"
            alt="..."
          />
        </div>
        {/* <div
          className="my-ova-button company"
          onClick={() =>
            redirect("/ova-store", navigate, false)
          }>
          {' '}
          <span>MyStore</span>{' '}
        </div> */}
      </div>

      {/* <div className="logo" style={{backgroundImage: 'none'}}>
        <img src="favicon.svg" width={90} alt="..." />
    </div> */}

      <div
        id="header-center-logo"
        onClick={() => redirect('/', navigate)}
        style={{ cursor: 'pointer' }}
        className="logo"></div>
      <div className="icon-menu">
        <Link
          id="header3-position"
          onClick={() =>
            redirect(
              'https://maps.app.goo.gl/RvQ39sgREMQt6yYf6',
              navigate,
              true
            )
          }
          style={{ backgroundImage: "url(/assets/svg/placje_black.svg')" }}
          to="#"
          className="header3-position position">
          <div className="text">Maps</div>
        </Link>
        <Link
          id="header3-phone"
          onClick={() => redirect("tel:(+237) 6 96 42 77 03", navigate, true)}
          to="#"
          className="phone header3-phone">
          <div className="text">696427703</div>
        </Link>
        <div className="recruitment">
          <Link to="/recruitment">
            <div className="text">{t('header_recruitment')}</div>
            {loading_recruitment_request && (
              <div className="notifications blink">
                <span className=""></span>
              </div>
            )}
            {loading_recruitment_success && (
              <div className="notifications">
                <span className="text-white">{recruitments.count}</span>
              </div>
            )}
          </Link>
        </div>
      </div>
      <div className="language-switcher">
        {lang === 'en' ? (
          <div
            className={'lang is-active'}
            onClick={() => handleChangeLang(InternationalisationTypes.FR)}>
            FR
          </div>
        ) : (
          <div
            className={'lang is-active'}
            onClick={() => handleChangeLang(InternationalisationTypes.EN)}>
            EN
          </div>
        )}
      </div>
    </header>
  );
}
