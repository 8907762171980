import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/footer/Footer";
import Header3 from "../../components/header/Header3";
import Page from "../../page/Page";
import { HashScroll } from "react-hash-scroll";

// Services and products.


export default function () {

    const { t } = useTranslation();

    const services = [
        {
            id: 1,
            title: t('expertise_software_method_farming'),
            description: t('expertise_software_method_farming_text'),
            image: '/assets/img/methods/cadrage.jpeg',
            tag: "cadrage"
        },
        {
            id: 2,
            title: t('expertise_software_method_planing'),
            description: t('expertise_software_method_planing_text'),
            image: '/assets/img/methods/planing.jpeg',
            tag: "budgetisation"
        },
        {
            id: 3,
            title: t('expertise_software_lot_realisation'),
            description: t('expertise_software_lot_realisation_text'),
            image: '/assets/img/methods/todo.jpeg',
            tag: "slot-realisation"
        },
        {
            id: 4,
            title: t('expertise_software_method_countinous_delivery'),
            description: t('expertise_software_method_countinous_delivery_text'),
            image: '/assets/img/methods/delivery.jpg',
            tag: "countinious-delivery"
        },
        {
            id: 5,
            title: t('expertise_software_modifications'),
            description: t('expertise_software_modifications_text'),
            image: '/assets/img/methods/adjust.jpeg',
            tag: "adjustement"
        },
        {
            id: 6,
            title: t('expertise_software_final_delivery'),
            description: t('expertise_software_final_delivery_text'),
            image: '/assets/img/methods/launching.webp',
            tag: "launching"
        },
        {
            id: 7,
            title: t('expertise_software_user_training'),
            description: t('expertise_software_user_training_text'),
            image: '/assets/img/methods/training.jpeg',
            tag: "training"
        },
        {
            id: 8,
            title: t('expertise_software_support_maintainance'),
            description: t('expertise_software_support_maintainance_text'),
            image: 'assets/img/methods/support.jpeg',
            tag: "support"
        }
    ]




    return (
        <Page
            title={"OVA consulting | Our Methods"}
            description={"Indeed, we support both large groups and SMEs in their digital transformation process. Thanks to our expertise in the integration and deployment of IT solutions, particularly in the sectors (Banking, Insurance, Agrifood, Logistics/Transport etc…)"}
        >
            <Header3/>
            <section style={{marginTop: "150px"}} >
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-12"></div>
                        <div className="col-lg-6 col-12">
                            <p className="template-page-title" >
                                {t("home_our_methods")}
                            </p>
                            <h2 style={{fontFamily: 'Hurme'}} >
                                <strong>
                                    {/* Sed ut perspiciatis unde omnis iste  */}
                                </strong>
                            </h2>
                            <p className="about-us-text mt-3">
                                {t("about_text_p_2")}
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="row" style={{marginTop: '50px'}} >
                    <div className="col-lg-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad.png')", backgroundSize: 'cover'}}>
                        <div className="temp-h-500">
                        </div>
                    </div>
                    <div className="col-lg-0 col-6">
                        
                    </div>
                    <div className="col-lg-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad2.jpg')", backgroundSize: 'cover'}}>
                        <div className="temp-h-500">
                        </div>
                    </div>
                    <div className="col-lg-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad3.jpg')", backgroundSize: 'cover'}}>
                        <div className="temp-h-500">
                        </div>
                    </div>
                </div> */}
            </section>
            
            <section className="text-align-left" style={{marginTop: '50px'}}>
                <div className="container">
                    {
                        services.map((service, index) => {

                            if(index % 2 === 0) {

                                return(
                                    <HashScroll hash={`#${service.tag}`} position="center">
                                        <div key={'our-methods' + index} className="row">
                                            <div className="col-lg-6 col-12">
                                                <div className="m-t-3">
                                                    <p className="template-page-title-text" id={service.tag} >
                                                    {service.title}
                                                    </p>
                                                    <p className="about-us-text mt-3">
                                                        {
                                                            service.description
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div>
                                                    <div className="text-template-img-rounded" style={{backgroundImage: `url(${service.image})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                                                        {/* <img src="assets/img/man-work-pad3.jpg" height={'100%'} alt="" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </HashScroll>
                                )
                            } else {

                                return (
                                    <HashScroll hash={`#${service.tag}`} position="center">
                                    <div key={'service' + index} className="row m-t-3 m-b-3">
                                        <div className="col-lg-6 col-12">
                                            <div>
                                                <div className="text-template-img-rounded bg-primary" style={{backgroundImage:`url(${service.image})`, backgroundSize: 'cover', backgroundPosition: 'bottom'}}>
                                                    {/* <img src="assets/img/man-work-pad3.jpg" height={'100%'} alt="" /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="m-t-3">
                                                <p className="template-page-title-text" id={service.tag}>
                                                    {service.title}
                                                </p>
                                                <p className="about-us-text mt-3">
                                                    {
                                                        service.description
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    </HashScroll>
                                )
                            }
                        })
                    }
                </div>
            </section>
            <Footer/>
        </Page>
    )
}