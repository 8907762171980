import produce from 'immer';
import SecureLS from 'secure-ls';
import { ApplicationAction } from '../types';
import {
  InternationalisationState,
  CHANGE_LANGUAGE,
  SET_TRANSLATOR_FUNCTION,
} from '../types/InternationalizationTypes';

const ls = new SecureLS({ encodingType: 'aes' });

export const initialState: InternationalisationState = {
  locale: ls.get('lang') || "en"
};

const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return produce(state, (draft) => {
        draft.locale = action.language;
      });
    case SET_TRANSLATOR_FUNCTION:
      return produce(state, (draft) => {
        draft.t = action.t;
      });
    default:
      return state;
  }
};

export default reducer;
