import { useTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Page from "../../page/Page";
import { getBgImage, getClients } from "../../utils/functions";





function ExpertiseInfrastructure () {

    const { expertise } = useParams();
    const { t } = useTranslation();


    return(
        <Page 
            title={"OVA - Consulting | Infrastructure and security"}
            description={"he concept of infrastructure security and perimeter security refers to the products, solutions and services used to protect the infrastructures of companies."}
        >
            <Header/>
            <section className="expertise-details expertise-infrastructure">
                <section className="banner"  style={{backgroundImage: "url(/assets/expertises/infra-security.jpg)"}}>
                    <div className="banner-1">
                        <div className="container">
                            <div className='row'>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div>
                                        <Link to="/expertises" className="expertses-return h5">
                                            <span className="d-flex">
                                                <img src="/assets/img/stat-speed-up.png" alt="..." className="m-auto" />
                                            </span>
                                            <span>Expertises</span>
                                        </Link>
                                        <p className="text h3">{t(`expertise_infrastructure`)}</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div>
                                        <div className='banner-description'>
                                            <p>
                                                {t("expertise_infrastructure_text")}
                                            </p>
                                            <Link to='/contact' className="button button--pan" role={'button'}><span>{t("contact_contact_us")}</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-2">
                        <OwlCarousel className='owl-theme our-clients-carousel' 
                            loop 
                            margin={10} 
                            autoplayHoverPause
                            dots={false} 
                            autoplay 
                            smartSpeed={2000}
                            autoplayTimeout={3000}
                            responsive= {{
                                0:{
                                items:2,
                                loop:true
                                },
                                600:{
                                items:4,
                                loop:true
                                },
                                1000:{
                                items:5,
                                loop:true
                                }
                            }} 
                        >
                            {
                                getClients("software-engineering").map((item, index) => {

                                    return(
                                        <div className='item' key={index} >
                                            <div className="reference" style={{backgroundImage: `url(${item.logo})`, height: '100px'}}></div>
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                </section>
                <section className="details">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-am-6 col-12 mb-3">
                                <div className="h-100">
                                    <div className="card h-100">
                                        <div className="infra-card-header">
                                            <div>
                                                <img width={'50px'} src="/assets/expertises/Fortinet_logo.png" alt="Fortinet" />
                                            </div>
                                            <div>
                                                <p className="title">FORTINET</p>
                                            </div>
                                        </div>
                                        <div>
                                            {t("infra_fortinet_text")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-am-6 col-12 mb-3">
                                <div className="h-100">
                                    <div className="card h-100">
                                        <div className="infra-card-header">
                                            <div>
                                                <img width={'50px'} src="/assets/expertises/vmware.png" alt="Fortinet" />
                                            </div>
                                            <div>
                                                <p className="title">VMWARE</p>
                                            </div>
                                        </div>
                                        <div>
                                            {t("infra_vmware_text")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-am-6 col-12 mb-3">
                                <div className="h-100">
                                    <div className="card h-100">
                                        <div className="infra-card-header">
                                            <div>
                                                <img width={'50px'} src="/assets/expertises/wservers.png" alt="Fortinet" />
                                            </div>
                                            <div>
                                                <p className="title">Windows servers</p>
                                            </div>
                                        </div>
                                        <div>
                                            {t("infra_w_servers_text")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-am-6 col-12 mb-3">
                                <div className="h-100">
                                    <div className="card h-100">
                                        <div className="infra-card-header">
                                            <div>
                                                <img width={'50px'} src="/assets/expertises/itsupport.jpg" alt="Fortinet" />
                                            </div>
                                            <div>
                                                <p className="title">{t("infra_infor_support")}</p>
                                            </div>
                                        </div>
                                        <div>
                                            {t("infra_infor_support_text")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-am-6 col-12 mb-3">
                                <div className="h-100">
                                    <div className="card h-100">
                                        <div className="infra-card-header">
                                            <div>
                                                <img width={'50px'} src="/assets/expertises/itsupport.jpg" alt="Fortinet" />
                                            </div>
                                            <div>
                                                <p className="title">{t("infra_material_fournit")}</p>
                                            </div>
                                        </div>
                                        <div>
                                            {t("infra_material_fournit_text")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-am-6 col-12 mb-3">
                                <div className="h-100">
                                    <div className="card h-100">
                                        <div className="infra-card-header">
                                            <div>
                                                <img width={'70px'} src="/assets/partners/paloalto.png" alt="Paloalto" />
                                            </div>
                                            <div>
                                                <p className="title">{t("Paloalto")}</p>
                                            </div>
                                        </div>
                                        <div>
                                            {t("paloalto_text")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <Footer/>
        </Page>
    )
}

export default ExpertiseInfrastructure