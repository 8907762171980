

export default function BlockPhotos() {

    return (
        <section className="block-photos">
            {/* Old */}
            {/* <div className="photo">
                <img src="/assets/img/home/1.jpg" alt="" />
            </div>
            <div className="photo">
                <img src="/assets/img/home/2.jpg" alt="" />
            </div>
            <div className="photo">
                <img src="/assets/img/home/_DSC9508.jpg" alt="" />
            </div> */}
            <div className="photo">
                <img src="/assets/img/about/espace.jpeg" alt="" loading="lazy"/>
            </div>
            <div className="photo">
                <img src="/assets/img/about/_DSC9521.jpg" alt="" loading="lazy"/>
            </div>
            <div className="photo">
                <img src="/assets/img/about/cto.jpeg" alt="" loading="lazy"/>
            </div>
        </section>
    )
}