import React from 'react';
import { Outlet } from 'react-router-dom';

type Props = {};

function OvaStoreLayout({}: Props) {
  return <Outlet />;
}

export default OvaStoreLayout;
