import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Expertise, expertises, softwareXpertise } from "../../const/expertises";
import Page from "../../page/Page";
import { getBgImage, getClients } from "../../utils/functions";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { makeStyles} from '@mui/styles';
import theme from '../../components/theme/theme';
import Timeline from "./components/timeline/Timeline";
import { softwareMethodologies } from "../../const/timelines";

  const useStyles = makeStyles({
  tabs: {

    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary,
      height: 3,
    },
    // "& .MuiTab-root.MuiTab-root": {
    //   color: theme.palette.primary
    // },
    "& .MuiTab-root.Mui-selected": {
      color: theme.palette.primary
    },
    "& .MuiTab-root": {
      "&:not(.Mui-selected)": {
        color: '#151D41'
      }
    }
  },
  tabPanel: {
    // color: 'white'
  }
})

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  



function ExpertiseSoftware () {

    const { expertise } = useParams();
    const { t } = useTranslation();
    const [selectedExpertise, setSelectedExpertise] = useState<Expertise | undefined>(undefined);
    const classes = useStyles();

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        const selected = _.filter(expertises, {'slug': "software-engineering"})
        if(selected && selected.length > 0){
            setSelectedExpertise(selected[0]);
        };
    },[])


    return(
        <Page 
            title={"OVA - Consulting | Software Engineering"}
            description={"We have the drive, expertise and software engineering talent to take any idea from the early concept stage to a commercial product that delivers value to your business and unlock new opportunities."}
        >
            <Header/>
            <section className="expertise-details expertise-infrastructure">
                <section className="banner"  style={{backgroundImage: "url(/assets/expertises/software1.jpg)"}}>
                    <div className="banner-1">
                        <div className="container">
                            <div className='row'>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div>
                                        <Link to="/expertises" className="expertses-return h5">
                                            <span className="d-flex">
                                                <img src="/assets/img/stat-speed-up.png" alt="..." className="m-auto" />
                                            </span>
                                            <span>Expertises</span>
                                        </Link>
                                        <p className="text h3">{t(`expertise_software_engineering`)}</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div>
                                        <div className='banner-description'>
                                            <p>
                                                {t("expertise_software_engineering_text")}
                                            </p>
                                            <Link to='/contact' className="button button--pan" role={'button'}><span>{t("contact_contact_us")}</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-2">
                        <OwlCarousel className='owl-theme our-clients-carousel' 
                            loop 
                            margin={10} 
                            autoplayHoverPause
                            dots={false} 
                            autoplay 
                            smartSpeed={2000}
                            autoplayTimeout={3000}
                            responsive= {{
                                0:{
                                items:2,
                                loop:true
                                },
                                600:{
                                items:4,
                                loop:true
                                },
                                1000:{
                                items:5,
                                loop:true
                                }
                            }} 
                        >
                            {
                                getClients("software-engineering").map((item, index) => {

                                    return(
                                        <div className='item' key={index} >
                                            <div className="reference" style={{backgroundImage: `url(${item.logo})`, height: '100px'}}></div>
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                </section>
                <section className="main-techno expertise-section"> 
                    <div className="container">
                        <div>
                            <h2 className="text-left title-1">
                                {t("Our main technologies stacks")}
                            </h2>
                        </div>
                        <div className='stacks'>

                            <div>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: '#ffffff4f', maxWidth: '100%', margin: 'auto' }}>
                                    <Tabs 
                                        value={value} 
                                        onChange={handleChange} 
                                        aria-label="basic tabs example"
                                        className={classes.tabs}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        {
                                            softwareXpertise.expertises.map((xpertise, index) => {
                                                return (

                                                    <Tab key={"tab-"+index} className={classes.tabPanel} label={xpertise.type} {...a11yProps(index)} />
                                                )
                                            })
                                        }
                                        {/* <Tab className={classes.tabPanel} label={t("Mining")} {...a11yProps(1)} />
                                        <Tab className={classes.tabPanel} label={t("Analytics")} {...a11yProps(2)} />
                                        <Tab className={classes.tabPanel} label={t("Visualization")} {...a11yProps(3)} /> */}
                                    </Tabs>
                                    </Box>
                                    {
                                        softwareXpertise.expertises.map((xpertise, index1) => {

                                            return (

                                                <TabPanel key={"tabPanel-" + index1} value={value} index={index1}>
                                                    <div className="row">
                                                        {
                                                            xpertise.expertises.map((expert, index) => {

                                                                return expert.contents.map(content => {

                                                                    if(index%2 === 0) {

                                                                        return(
                                                                            <div key={"design" + content.id} className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                                                <div className='stack-items'>
                                                                                    <div className="d-flex">
                                                                                        <div className={`item ${index1%2 === 0 ? "bg-tertiary" : "bg-primary"}`}>
                                                                                            <div style={{backgroundImage: `url(${content.image})`}}>

                                                                                            </div>
                                                                                            {/* <img height={100} src={content.image} alt="Python programing language" /> */}
                                                                                        </div>
                                                                                        <div className="item">
                                                                                            <div className="m-auto">
                                                                                                <p className="language-name">
                                                                                                    {content.name}
                                                                                                </p>
                                                                                                <p className="language-text">
                                                                                                    <span className={index1%2 === 0 ? "bg-primary" : "bg-tertiary"}>
                                                                                                        {t(content.type)}
                                                                                                    </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    } else {

                                                                        return (
                                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                                                <div className='stack-items'>
                                                                                    <div className="d-flex row-reverse">
                                                                                        <div className={`item ${index1%2 === 0 ? "bg-tertiary" : "bg-primary"}`}>
                                                                                            {/* <img height={100} src={content.image} alt="typescript programing language" /> */}
                                                                                            <div style={{backgroundImage: `url(${content.image})`}}>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="item">
                                                                                            <div className="m-auto">
                                                                                                <p className="language-name">
                                                                                                    {content.name}
                                                                                                </p>
                                                                                                <p className="language-text">
                                                                                                    <span className={index1%2 === 0 ? "bg-primary" : "bg-tertiary"}>
                                                                                                        {t(content.type)}
                                                                                                    </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                            })
                                                        }
                                                        {/* <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                            <div className='stack-items'>
                                                                <div className="d-flex">
                                                                    <div className="item">
                                                                        <img src="/assets/stacks/typescript.png" alt="typescript programing language" />
                                                                    </div>
                                                                    <div className="item">
                                                                        <div className="m-auto">
                                                                            <p className="language-name">
                                                                                Typescript
                                                                            </p>
                                                                            <p className="language-text">
                                                                                <span>
                                                                                    Language
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                            <div className='stack-items'>
                                                                <div className="d-flex row-reverse">
                                                                    <div className="item">
                                                                        <img src="/assets/stacks/typescript.png" alt="typescript programing language" />
                                                                    </div>
                                                                    <div className="item">
                                                                        <div className="m-auto">
                                                                            <p className="language-name">
                                                                                Typescript
                                                                            </p>
                                                                            <p className="language-text">
                                                                                <span>
                                                                                    Language
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                            <div className='stack-items'>
                                                                <div className="d-flex row-reverse">
                                                                    <div className="item">
                                                                        <img src="/assets/stacks/typescript.png" alt="typescript programing language" />
                                                                    </div>
                                                                    <div className="item">
                                                                        <div className="m-auto">
                                                                            <p className="language-name">
                                                                                Typescript
                                                                            </p>
                                                                            <p className="language-text">
                                                                                <span>
                                                                                    Language
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                            <div className='stack-items'>
                                                                <div className="d-flex row-reverse">
                                                                    <div className="item">
                                                                        <img src="/assets/stacks/typescript.png" alt="typescript programing language" />
                                                                    </div>
                                                                    <div className="item">
                                                                        <div className="m-auto">
                                                                            <p className="language-name">
                                                                                Typescript
                                                                            </p>
                                                                            <p className="language-text">
                                                                                <span>
                                                                                    Language
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </TabPanel>
                                            )
                                        })
                                    }
                                </Box>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="details">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-am-6 col-12 mb-3">
                                <div className="h-100">
                                    <div className="card h-100">
                                        <div className="infra-card-header">
                                            <div>
                                                <img width={'50px'} src="/assets/expertises/Fortinet_logo.png" alt="Fortinet" />
                                            </div>
                                            <div>
                                                <p className="title">FORTINET</p>
                                            </div>
                                        </div>
                                        <div>
                                            {t("infra_fortinet_text")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-am-6 col-12 mb-3">
                                <div className="h-100">
                                    <div className="card h-100">
                                        <div className="infra-card-header">
                                            <div>
                                                <img width={'50px'} src="/assets/expertises/vmware.png" alt="Fortinet" />
                                            </div>
                                            <div>
                                                <p className="title">VMWARE</p>
                                            </div>
                                        </div>
                                        <div>
                                            {t("infra_vmware_text")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-am-6 col-12 mb-3">
                                <div className="h-100">
                                    <div className="card h-100">
                                        <div className="infra-card-header">
                                            <div>
                                                <img width={'50px'} src="/assets/expertises/wservers.png" alt="Fortinet" />
                                            </div>
                                            <div>
                                                <p className="title">Windows servers</p>
                                            </div>
                                        </div>
                                        <div>
                                            {t("infra_w_servers_text")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-am-6 col-12 mb-3">
                                <div className="h-100">
                                    <div className="card h-100">
                                        <div className="infra-card-header">
                                            <div>
                                                <img width={'50px'} src="/assets/expertises/itsupport.jpg" alt="Fortinet" />
                                            </div>
                                            <div>
                                                <p className="title">{t("infra_infor_support")}</p>
                                            </div>
                                        </div>
                                        <div>
                                            {t("infra_infor_support_text")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-am-6 col-12 mb-3">
                                <div className="h-100">
                                    <div className="card h-100">
                                        <div className="infra-card-header">
                                            <div>
                                                <img width={'50px'} src="/assets/expertises/itsupport.jpg" alt="Fortinet" />
                                            </div>
                                            <div>
                                                <p className="title">{t("infra_material_fournit")}</p>
                                            </div>
                                        </div>
                                        <div>
                                            {t("infra_material_fournit_text")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <section className="expertise-projects">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                <div>
                                    <p className="title">
                                        {t("Projetcts we have")}
                                        <br />
                                        <span>
                                            carried out
                                        </span>
                                    </p>
                                    {/* <Link to="/references" className="view-all">
                                        {t("view all")}
                                        <span>
                                            <img src="/assets/img/stat-speed-up.png" alt="" />
                                        </span>
                                    </Link> *}
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-6 col-12">
                                <div> 
                                    <div className="row">
                                        {
                                            softwareXpertise?.projects.map((project, index) => {
    
                                                return(
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={`project-${index}`}>
                                                        <div>
                                                            <div className="card">
                                                                <img className="card-img-top h-200" src={project.image} alt={project.name}/> 
                                                                <div className="card-body">
                                                                    <h2 className="card-title">{project.name}</h2>
                                                                    <p className="card-text" dangerouslySetInnerHTML={{__html: t(project.description)}} />
                                                                </div>
                                                                <div className="card-desc">
                                                                    <div className="d-flex h-100">
                                                                        <Link to={project.link} target={'_blank'} className="view-all m-auto">
                                                                            {t("Read more")}
                                                                            {/* <span>
                                                                                <img width={''} src="/assets/img/stat-speed-up.png" alt="" />
                                                                            </span> *}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className="expertise-section pt-0">
                    <div className="container">
                        <div>
                            <div className=''>
                                <h1 className="text-primary text-left title-1">
                                    {t("our_methodology")}
                                </h1>
                                <p className="text text-left">{t("expertise_method_text")}</p>
                            </div>
                        </div>
                        <div>
                            <div className="row methodology-cards">
                                {
                                    softwareMethodologies.map((methodology, index) => {
                                        return(
                                            <div key={"methodology-" + index} className="col-lg-4 col-sm-4 col-12 mb-3">
                                                <div className="h-100">
                                                    <div className="card methodology-card h-100">
                                                        <p className="title  text-primary h5">{t(methodology.title)}</p>
                                                        <div>
                                                            <p>
                                                                {t(methodology.text)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            {/* <Timeline timeLines={softwareMethodologies} /> */}
                        </div>
                    </div>
                </section>
            </section>
            <Footer/>
        </Page>
    )
}

export default ExpertiseSoftware