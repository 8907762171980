import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SecureLS from 'secure-ls';
import persistStore from 'redux-phoenix';
import { enableES5 } from 'immer';
import store from './stores';
import { AnyAction, Store } from 'redux';
import { ApplicationState } from './stores/types';
import './components/locales';
import { CookiesProvider } from 'react-cookie';


const ls = new SecureLS({ encodingType: 'aes' });


enableES5();

// @ts-ignore
ls.setItem = ls.set;
// @ts-ignore
ls.getItem = ls.get;

persistStore(store, {
  whitelist: ['users'],
  blacklist: ['users.loading.users'],
  storage: ls,
  serialize: (data: any) => data,
  deserialize: (data: any) => data,
  throttle: 5 * 1000 // wait 5 seconds before save
}).then((store: Store<ApplicationState, AnyAction>) =>
{
  ReactDOM.render(
    <React.StrictMode>
      <CookiesProvider>
       <App store={store} />
      </CookiesProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
