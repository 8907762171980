import { ThunkAction } from "redux-thunk";
import { RECRUITMENTAPI } from "../../components/api/recruitment";
import { loadRecruitmentFailed, loadRecruitmentRequest, loadRecruitmentSuccess } from "../actions/recruitments";
import { ApplicationAction, ApplicationState } from "../types";



type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>



export const listRecruitments = (): Effect => async (
    dispatch
) => {

    dispatch(loadRecruitmentRequest());

    RECRUITMENTAPI.get("/jobs/").then(
        response => {
            const { data } = response;

            dispatch(loadRecruitmentSuccess(data));

        }
    ).catch(err => {
        dispatch(loadRecruitmentFailed());
    })
}
