import { SuccessStories } from "../../types/successStory";

export const successStories: SuccessStories[] = [
    {
      title: 'success_story_total_energies',
      company: 'Total Energies',
      description: 'success_story_total_energies_description',
      image: '/assets/img/ess.png',
      link: 'https://totalenergies.com/fr',
      domain: 'Compagnie pétrolière',
      country: 'Cameroun',
      illustration: '/assets/img/home/plateforme-petroliere.jpeg',
      cleint_logo: '/assets/clients/Logo_TotalEnergies.png'
    },
    {
      title: "success_story_cud",
      company: 'CUD',
      description: 'success_story_cud_description',
      image: '/assets/services/ged.jpg',
      link: 'https://douala.cm/',
      domain: 'Service publique',
      country: 'Cameroun',
      cleint_logo: '/assets/clients/logo_cud_tranparent.png',
      illustration: '/assets/img/home/rhone-poulenc.jpg'
    },
    {
      cleint_logo: '/assets/clients/perenco.png',
      title: "success_story_air_liquide",
      company: 'PERENCO',
      description: 'success_story_air_liquide_description',
      image: '/assets/services/api.png',
      link: 'https://www.perenco.com/',
      domain: 'Compagnie pétrolière',
      country: 'Cameroun',
      illustration: '/assets/img/home/plateforme-petroliere.jpeg'
    },
    {
      title: "success_story_afrexim",
      cleint_logo: '/assets/clients/afrexim-bank.png',
      company: 'AFREXIMBANK',
      description: 'success_story_afrexim_description',
      image: '/assets/services/cloud.png',
      link: 'https://www.afreximbank.com/',
      domain: 'Banque',
      country: 'Cameroun',
      illustration: '/assets/img/home/banque.jpg'
    },
    {
      title: "success_story_cadyst",
      cleint_logo: '/assets/clients/cadyst.png',
      company: 'CADYST',
      description: 'success_story_cadyst_description',
      image: '/assets/clients/cadyst-usine.jpeg',
      illustration: '/assets/clients/cadyst-usine.jpeg',
      link: 'http://cadyst.com/',
      domain: 'Agroalimentaire',
      country: 'Cameroun'
    },
    {
      cleint_logo: '/assets/clients/logo-rullier.png',
      title: 'success_story_rullier',
      company: 'RULLIER BOIS',
      description: 'success_story_rullier_description',
      image: '/assets/services/cloud.png',
      link: 'https://rullier.fr',
      domain: 'Bois et dérivés pour la construction',
      country: 'France',
      illustration: '/assets/clients/rullier-bois.jpeg'
    },
    {
      cleint_logo: '/assets/clients/ess-logo.svg',
      title: 'success_story_ess',
      company: 'ESS',
      description: 'success_story_ess_description',
      image: '/assets/clients/ess-logo.svg',
      link: 'https://emraldsecuritiesservices.com/',
      domain: 'Groupe camerounais indépendant d’établissements financiers et d’investissement',
      country: 'Cameroun',
      illustration: '/assets/img/ess.png'
    },
    {
      cleint_logo: '/assets/clients/ellios.png',
      title: 'success_story_ellios',
      company: 'ELLIOS',
      description: 'success_story_ellios_description',
      image: '/assets/clients/high-security-safe.jpeg',
      link: '#',
      domain: 'La surveillance humaine, la surveillance par des systèmes électroniques de sécurité ou de gardiennage, de transport de fonds.',
      country: 'France',
      illustration: '/assets/clients/high-security-safe.jpeg'
    },
    {
      cleint_logo: '/assets/clients/solar-hut.svg',
      title: 'success_story_solar_hut',
      company: 'SOLAR HUT',
      description: 'success_story_solar_hut_description',
      image: '/assets/img/solar-home.png',
      link: 'https://solarhut.energy',
      domain: 'La surveillance humaine, la surveillance par des systèmes électroniques de sécurité ou de gardiennage, de transport de fonds.',
      country: 'Cameroun',
      illustration: '/assets/img/solar-home.png'
    }
  ];