// import "./components/styles/styles.css"

import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ApplicationState } from "../../../stores/types";
import { RECRUITMENTAPI } from "../../api/recruitment";
import Footer from "../../components/footer/Footer";
import Header3 from "../../components/header/Header3";
import { Category, Job } from "../../components/types/jobs";
import Page from "../../page/Page";
import { Link } from 'react-router-dom';



export default function () {

    const { t } = useTranslation();

    const params = useParams();

    const [job, setJob] = useState<Job | null>(null);
    
    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState<Category | null>(null);
    const {locale} = useSelector((state: ApplicationState) => state.i18n);

    const job_id = params.job_id;


    // //console.log("params ======>", params.job_id)

    useEffect(() => {
        RECRUITMENTAPI.get(`jobs/${job_id}/`).then((resp) => {
            setJob(resp.data)
            RECRUITMENTAPI.get(`categories/${resp.data.job_category}/`).then((resp) => {
                setCategory(resp.data)
            })
        }).catch((err) => {
            setError(t("fetching_jobs_error"))
        });
    }, [])

    return(
        <Page
            title={"OVA - Consulting | " + t('recruitement_text')}
            description={t("recruitement_our_profiles")}
        >
            <main style={{background: '#F2F5F7'}} className="main homepage">
                <Header3/>
                <section className="main-content">

                    <section className="news-banner" >
                        <div id="newsCarousel" className="carousel news-carousel carousel-dark slide" data-bs-ride="carousel">
                            
                            <div className="carousel-inner news-banner-mh">
                                <div className="carousel-item active news-banner-mh news-banner-item-bg" data-bs-interval="10000" style={{backgroundImage: "url(/assets/img/agency-bg.png)"}} >
                                    <div className=" news-banner-mh">
                                        <div className="container news-banner-mh d-flex flex-column">
                                            <div className="news-banner-text-container">
                                            <p className="news-banner-title text-left"> 
                                                <Link to="/recruitment" className="jobs-details-go-back">
                                                <img src="/assets/img/stat-speed-up.png" alt="go back"/>
                                                </Link> 
                                            {t("recruitement_text")}
                                            </p>
                                            {
                                                error && <div className="alert alert-danger mt-3" role="alert">
                                                {error}
                                            </div>
                                            }
                                            <br/>
                                            <br/>
                                            <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <!-- Events details section  --> */}

                    {
                        loading && <section className="event-details job-offer-details text-left" style={{paddingTop: "50px"}} >
                            <Skeleton width={"100%"} height={'50vh'} />
                        </section>
                    }

                    {!loading && job && <section className="event-details job-offer-details text-left" style={{paddingTop: "50px"}} >
                        <div className="container">
                            <div className="">
                                <div>
                                    <div className="event-details-container" >
                                        <div className="row job-description">
                                            <div className="col-lg-8 col-sm-12 col-12">
                                                <div>
                                                    <div className="job-offer-cards">
                                                        <div className="job-offer-card">
                                                            <div className="row">
                                                                <div className="col-md-3 col-12">
                                                                    <div className="job-offer-card-image">
                                                                        <img src={job.file ?? "https://static6.depositphotos.com/1037613/650/i/600/depositphotos_6509200-stock-photo-searching-for-jobs.jpg"} alt="..."/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-9 col-12">
                                                                    <div className="text-left">
                                                                        <p className="title">{job.job_title}</p>
                                                                        <div className="job-offer-card-details">
                                                                            <div>
                                                                                <img width="15px" src="/assets/img/job1.png" alt="..."/>
                                                                                <span> {category?.name}</span> 
                                                                            </div>
                                                                            <div>
                                                                                <img width="15px" src="/assets/img/map-grey.png" alt="..."/>
                                                                                <span>  {job.job_location}</span>
                                                                            </div>
                                                                            <div>
                                                                                <img width="15px" src="/assets/img/alarm.png" alt="..."/>
                                                                                <span>{job.job_starting_date}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="badges mt-3">
                                                                            <span className="badge success">{t(job.job_disponibility)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-12 col-12">
                                                <div>
                                                    <p className="text">
                                                        {t("job_application_ends")} : <span style={{color: 'red'}} >{job.job_application_delay}</span>
                                                    </p>
                                                    <Link to={`/recruitment/${job_id}/apply`} className="apply-link">{t("job_apply")}</Link>
                                                    {/* <Link to="#" className="fav-link">
                                                        <img src="/assets/img/bookmark.png" width="20px" alt=""/>
                                                    </Link> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-sm-12 col-12">

                                                <div style={{marginTop: '3rem'}}>
                                                    <p className="subtitle">
                                                        {t("job_description")}
                                                    </p>
                                                    <p className="text" dangerouslySetInnerHTML={{__html: locale === "en" ? job.job_description_en :  job.job_description_fr}}>
                                                    </p>
                                                </div>
                                                {/* <div style={{marginTop: '3rem'}}>
                                                    <p className="subtitle">
                                                        {t("job_key_responsabilities")}
                                                        
                                                    </p>
                                                    
                                                    <p className="text" dangerouslySetInnerHTML={{__html: job.job_responsabilities ?? ""}}></p>
                                                        
                                                </div>
                                                <div style={{marginTop: '3rem'}}>
                                                    <p className="subtitle">
                                                        {t("skills_experience")}
                                                    </p>
                                                    <p className="text" dangerouslySetInnerHTML={{__html: job.job_competences_experience ?? ""}}></p>

                                                </div> */}
                                                <div>
                                                    <div className="footer-socials recruitment-socials d-flex">
                                                        <p className="text text-black" >{t("share_job")}</p>
                                                        <Link to="#" className="footer-link social-link">
                                                            <img width="30px" src="/assets/img/facebook.png" alt="map"/> &nbsp;
                                                        </Link>
                                                        <Link to="#" className="footer-link social-link">
                                                            <img width="30px" src="/assets/img/twitter.png" alt="map"/> &nbsp;
                                                        </Link>
                                                        <Link to="#" className="footer-link social-link">
                                                            <img width="30px" src="/assets/img/instagram.png" alt="map"/> &nbsp;
                                                        </Link>
                                                        <Link to="#" className="footer-link social-link">
                                                            <img width="30px" src="/assets/img/linkedin.png" alt="map"/> &nbsp;
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>}
                </section>
                <Footer/>
            </main>
        </Page>
    )

}