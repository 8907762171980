import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Footer from "../../../../components/footer/Footer";
import Header from "../../../../components/header/Header";
import Page from "../../../../page/Page";
import { motion } from "framer-motion";
import { Training, trainings } from "../../../../const/training";
import _ from 'lodash';
import { CONTACTAPI } from "../../../../api/contact";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Link } from 'react-router-dom';

interface Message {
    type: boolean,
    message: string
}

function TrainingDetails () {

    const {training} = useParams();

    const { register, handleSubmit } = useForm();
    const { register: register1, handleSubmit: handleSubmit1, reset } = useForm();

    const [course, setCourse] = useState<Training | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState<Message | undefined>(undefined);
    const [trainingPlace, setTrainingPlace] = useState(undefined);


    const { t } = useTranslation()

    const handleSFilter = (data) => {
        setLoading(true);
        const toSend = {
            "first_name": data.firstName,
            "last_name": data.lastName,
            "society": data.society,
            "role": data.role,
            "phone": data.phone,
            "postal_code": data.postalCode,
            "training": t(course?.title ?? ""),
            "email": data.email,
            "number_of_inscriptions": data.number,
            "oher_informations": data.complement,
            "interested_by_the_course": data.form_interested,
            "accepted": data.form_accept
        }

        // //console.log("Data to send ", toSend)

        CONTACTAPI.post("training", toSend).then(resp => {
            setLoading(false);
            setMessage(
                {
                    type: true,
                    message: t("posted_subscriptions")
                }
            )
        }).catch(err => {
            setLoading(false);
            setMessage(
                {
                    type: false,
                    message: t("error_posted_subscriptions")
                }
            )
        })
    };

    const handleSubmitForm = (data) => {
        setLoading(true);
        const toSend = {
            "first_name": data.firstName,
            "last_name": data.lastName,
            "society": data.society,
            "role": data.role,
            "phone": data.phone,
            "postal_code": data.postalCode,
            "training": data.training,
            "email": data.email,
            "number_of_inscriptions": data.number,
            "oher_informations": data.complement,
            "interested_by_the_course": data.form_interested,
            "accepted": data.form_accept,
            "is_custom": true,
            "starting_date": data.starting_date,
            "ending_date": data.ending_date,
        }

        // //console.log("Data to send ", toSend)
        
        CONTACTAPI.post("training", toSend).then(resp => {
            setLoading(false);
            reset();
            toast(t("obtain_estimation_success"), {
                position: toast.POSITION.TOP_RIGHT
            });
            // @ts-ignore
            window.$('#exampleModalLong').modal('hide')
        }).catch(err => {
            setLoading(false);
            toast.error(t("obtain_estimation_error"), {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    };


    const handleChange = (str) => {
        setTrainingPlace(str);
    }

    const handleAskTraining = () => {

        Swal.fire({
            title: t("are_you_company"),
            // text: t("only_entr_can_subscribe"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#151d41',
            cancelButtonColor: '#d33',
            confirmButtonText: t("yes"),
            cancelButtonText: t("no"),
          }).then((result) => {
            if (result.isConfirmed) {
                // @ts-ignore
                window.$('#exampleModalLong').modal('show')
            } else {
                Swal.fire({
                    title: "",
                    text: t("subscription_not_entreprise"),
                    icon: 'warning',
                    confirmButtonColor: '#151d41',
                    cancelButtonColor: '#d33',
                    confirmButtonText: "Ok",
                })
                // toast.success(t("obtain_estimation_success"), {
                //     position: toast.POSITION.TOP_RIGHT
                // });
            }
          })
    }

    useEffect(() => {

        const selectedTraining = _.filter(trainings, {'slug': training});
        // //console.log(selectedTraining);

        if(selectedTraining){

            setCourse(selectedTraining[0]);
        }
    }, [])


    return (
        <Page
            title={"OVA - Consulting | " + training}
        >
            <Header/>
            <section className="training-page training-details main homepage ">
                <section className="banner" style={{backgroundImage: "url(/assets/expertises/training-1.png)"}}>
                    <div className="banner-content">
                        <div className="container">
                            <Link to="/trainings" className="expertses-return h5">
                                <span className="d-flex">
                                    <img height={'16px'} className="m-auto" src="/assets/img/stat-speed-up.png" alt="..." />
                                </span>
                                <span>{t('training_title')}</span>
                            </Link>
                        </div>
                        <div className="container">
                            <p className="title">{course && t(course.title)}</p>
                            <p className="text">{course && t(course.description)}</p>
                        </div>
                    </div>
                </section>
                <section className="sections">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-8 col-sm-12 col-12">
                                <div className="d-flex">
                                    <div className="sect">
                                        <div>
                                            <Link to="#objectis-pedagogiques">
                                                {t("course_objectives")}
                                                <br />
                                                <span><img alt="pointer hand"  src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E"/></span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="sect">
                                        <div>
                                            <Link to="#required-level">
                                                {t("required_level")}
                                                <br />
                                                <span><img alt="pointer hand"  src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E"/></span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="sect">
                                        <div>
                                            <Link to="#public">
                                                {t("public_concerned")}
                                                <br />
                                                <span><img alt="pointer hand"  src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E"/></span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="sect">
                                        <div>
                                            <Link to="#program">
                                                {t("detailed_program")}
                                                <br />
                                                <span><img alt="pointer hand"  src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E"/></span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="links trainings p-0">
                    <div className="container training-card pl-0 training-card-no-effects">
                        <div className="training-bottom-branding">
                            <div className="socials">
                                <div className="linkedin">
                                    <Link to={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.protocol + "//" +window.location.host + "/trainings/" + training}`}>
                                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                                    </Link>
                                </div>
                                <div className="instagram">
                                    <Link to={`https://www.instagram.com/?url=${window.location.protocol + "//" +window.location.host + "/trainings/" + training}`}>
                                        <i className="fa fa-instagram" aria-hidden="true"></i>
                                    </Link>
                                </div>
                                <div className="twitter">
                                    <Link to={`https://twitter.com/intent/tweet?url=${window.location.protocol + "//" +window.location.host + "/trainings/" + training}`}>
                                        <i className="fa fa-twitter" aria-hidden="true"></i>
                                    </Link>
                                </div>
                                <div className="facebook">
                                    <Link target={"_blank"} to={`https://www.facebook.com/sharer/sharer.php?u=${window.location.protocol + "//" +window.location.host + "/trainings/" + training}`}>
                                        <i className="fa fa-facebook" aria-hidden="true"></i>
                                    </Link>
                                </div>
                                {/* <div className="behance">
                                    <Link to="#">
                                        <i className="fa fa-behance" aria-hidden="true"></i>
                                    </Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="course-content" style={{backgroundColor: 'transparent'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-8 col-sm-12 col-12">
                                <div className="details-content">
                                    <div id="objectis-pedagogiques">
                                        <p className="title">
                                            {t("objectives_competences")}
                                        </p>
                                        <div className="pt-2 pb-2" >
                                            <div dangerouslySetInnerHTML={{__html: t(course?.trainingDetails?.objectives ?? "")}}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="details-content">
                                    <div id="required-level">
                                        <p className="title">
                                            {t("required_level")}
                                        </p>
                                        <p dangerouslySetInnerHTML={{__html: t(course?.trainingDetails?.required_level ?? "")}}>
                                        </p>
                                    </div>
                                </div>
                                <div className="details-content">
                                    <div id="public">
                                        <p className="title">
                                            {t("public_concerned")}
                                        </p>
                                        <div className="pb-2">
                                            {t(course?.trainingDetails?.concerned_public ?? "")}
                                        </div>
                                    </div>
                                </div>

                                <div className="details-content">
                                    <div id="program">
                                        <p className="title">
                                            {t("detailed_program")}
                                        </p>
                                        <div className="program-accordion">
                                            <div className="accordion" id="accordionExample">
                                                {
                                                    course?.trainingDetails?.program.map((program, index) => {

                                                        return (

                                                            <div className="card" key={"program-" + index}>
                                                                <div className="card-header" id={`heading-${index}`}>
                                                                <h2 className="mb-0">
                                                                    <button className="btn title btn-block text-left" type="button" data-toggle="collapse" data-target={`#collapse` + index} aria-expanded="true" aria-controls={`collapse` + index}>
                                                                        {
                                                                            t(program.title)
                                                                        }
                                                                        <img src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cg transform='translate(-1233 -1483)'%3E%3Ccircle cx='14' cy='14' r='14' transform='translate(1233 1483)' fill='rgba(0,146,137,.2)'/%3E%3Cpath d='M1245.307 1489a1.432 1.432 0 011.393 1.423v3.17a1.562 1.562 0 012.253.2 1.454 1.454 0 011.979.522c1.56-.285 2.263.7 2.263 2.305 0 .087-.006.422-.006.508.005 1.968-.986 2.442-1.217 3.929a.761.761 0 01-.753.644h-5.448a1.524 1.524 0 01-1.392-.9c-.413-.921-1.557-3.033-2.455-3.418a1.353 1.353 0 01-.924-1.27 1.525 1.525 0 012.125-1.4 4.316 4.316 0 01.791.449v-4.739a1.455 1.455 0 011.391-1.423zm.011 13.209h6.1a.762.762 0 01.762.762v1.529a.762.762 0 01-.762.762h-6.1a.762.762 0 01-.762-.762v-1.529a.762.762 0 01.762-.762zm5.334.889a.635.635 0 10.635.635.635.635 0 00-.634-.633z' fill='%23009289'/%3E%3C/g%3E%3C/svg%3E" alt="" />
                                                                    </button>
                                                                </h2>
                                                                </div>

                                                                <div id={`collapse` + index} className="collapse" aria-labelledby={`heading-${index}`} data-parent="#accordionExample">
                                                                    <div className="card-body">
                                                                        {
                                                                            program.content.map((content, i) => {

                                                                                return (
                                                                                    <div className="pt-2 pb-2">

                                                                                        <p className="title">
                                                                                        {t(content.title)}
                                                                                        </p>
                                                                                        <div dangerouslySetInnerHTML={{__html: t(content.text)}} ></div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                
                                            </div>
                                        </div>
                                        <p className="title" style={{opacity: '.5'}}></p>
                                        
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-lg-5 col-md-4 col-sm-12 col-12">
                                <div className="to-sticky">
                                    <div className="trainings-details-card">
                                        <motion.div
                                            key={trainingPlace}
                                            initial={{ scale: 0 }}
                                            animate={{ rotate: 0, scale: 1 }}
                                            transition={{
                                                type: "spring",
                                                // stiffness: 260,
                                                // damping: 20,
                                                duration: 2
                                            }}
                                            // className="strategies-caousel-icon"
                                        >
                                        {
                                            trainingPlace === "online" ? <div className="trainings-details-card-2">
                                                <p className="title">
                                                {t("face_to_online_session_q")}
                                                </p>
                                                <span onClick={() => handleChange(undefined)} className="close">
                                                    <img src="/assets/img/close.png" alt="" width={'1rem'} />
                                                </span>
                                                <div className="trainings-prices table-responsive">
                                                    {/* <p className="price-row">
                                                        <span className="col date">04/09/2022</span>
                                                        <span className="col place"> {t("online")}</span>
                                                        <span className="col price">{t("price-on-demand")}</span>
                                                        <button style={{padding: "10px"}} onClick={() => handleChange("subscribe")} className="btn btn-primary border-radius-0">Je m'inscris</button>
                                                    </p>
                                                    <hr className="m-0" />
                                                    <p className="price-row">
                                                        <span className="col date">04/10/2022</span>
                                                        <span className="col place"> {t("online")}</span>
                                                        <span className="col price">{t("price-on-demand")}</span>
                                                        <button style={{padding: "10px"}} onClick={() => handleChange("subscribe")} className="btn btn-primary border-radius-0">Je m'inscris</button>
                                                    </p> */}
                                                    <table className="table table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <td><span className="">04/09/2022</span></td>
                                                                <td><span className="strong"> {t("online")}</span></td>
                                                                <td><span className="">{t("price-on-demand")}</span></td>
                                                                <td><button onClick={() => handleChange("subscribe")} style={{padding: "10px"}} className="btn btn-primary border-radius-0">Je m'inscris</button></td>
                                                            </tr>
                                                            <tr>
                                                                <td>04/10/2022</td>
                                                                <td>{t("online")}</td>
                                                                <td>{t("price-on-demand")}</td>
                                                                <td><button onClick={() => handleChange("subscribe")} style={{padding: "10px"}} className="btn btn-primary border-radius-0">Je m'inscris</button></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> : trainingPlace === "face" ? <div className="trainings-details-card-2">
                                                <p className="title">
                                                {t("face_to_face_session_q")}
                                                </p>
                                                <span onClick={() => handleChange(undefined)} className="close">
                                                    <img src="/assets/img/close.png" alt="" width={'1rem'} />
                                                </span>
                                                <div className="trainings-prices table-responsive">
                                                    {/* <p className="price-row">
                                                        <span className="col date">04/09/2022</span>
                                                        <span className="col place"> Douala</span>
                                                        <span className="col price">{t("price-on-demand")}</span>
                                                        <button onClick={() => handleChange("subscribe")} style={{padding: "10px"}} className="btn btn-primary border-radius-0">Je m'inscris</button>
                                                    </p>
                                                    <hr className="m-0" />
                                                    <p className="price-row">
                                                        <span className="col date">04/10/2022</span>
                                                        <span className="col place"> Douala</span>
                                                        <span className="col price">{t("price-on-demand")}</span>
                                                        <button onClick={() => handleChange("subscribe")} style={{padding: "10px"}} className="btn btn-primary border-radius-0">Je m'inscris</button>
                                                    </p> */}
                                                    <table className="table table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <td><span className="">04/09/2022</span></td>
                                                                <td><span className="strong"> Douala</span></td>
                                                                <td><span className="">{t("price-on-demand")}</span></td>
                                                                <td><button onClick={() => handleChange("subscribe")} style={{padding: "10px"}} className="btn btn-primary border-radius-0">Je m'inscris</button></td>
                                                            </tr>
                                                            <tr>
                                                                <td>04/10/2022</td>
                                                                <td>Douala</td>
                                                                <td>{t("price-on-demand")}</td>
                                                                <td><button onClick={() => handleChange("subscribe")} style={{padding: "10px"}} className="btn btn-primary border-radius-0">Je m'inscris</button></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> : trainingPlace === "subscribe" ? <div>
                                                <div className="position-relative">
                                                    <p className="title">
                                                        {t("inscript_form")} 
                                                    </p>
                                                    <span onClick={() => handleChange(undefined)} className="position-absolute top-0 right-0">
                                                        <img src="/assets/img/close.png" alt="" width={'20px'} />
                                                    </span>
                                                    <div className="inscription-form">
                                                        <div>
                                                            <div className="p-3">
                                                                <div>
                                                                    {
                                                                        message && message.type ?
                                                                        <div className="alert alert-success" role="alert">
                                                                        {message.message}
                                                                        </div>
                                                                        : message && !message.type ?
                                                                        <div className="alert alert-danger" role="alert">
                                                                        {message?.message}
                                                                        </div>
                                                                        : <div></div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <form onSubmit={handleSubmit(handleSFilter)}>
                                                                
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control border-radius-0" {...register('firstName', {required: true})} id="firstName" placeholder={t("home_contact_name") + " *"}/>
                                                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                                </div>
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control border-radius-0" {...register('lastName', {required: true})} id="lastName" placeholder={t("form_last_name") + " *"}/>
                                                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                                </div>
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control border-radius-0" {...register('society', {required: true})} id="society" placeholder={t("form_society") + " *"}/>
                                                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                                </div>
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control border-radius-0" {...register('role', {required: true})} id="role" placeholder={t("form_role") + " *"}/>
                                                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                                </div>
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control border-radius-0" {...register('phone', {required: true})} id="phone" placeholder={t("home_contact_phone") + " *"}/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control border-radius-0" {...register('postalCode', {required: false})} id="postalCode" placeholder={t("form_postal_code")}/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <input type="email" className="form-control border-radius-0" {...register('email', {required: true})} id="email" placeholder={t("home_contact_email") + " *"}/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <input type="number" className="form-control border-radius-0" {...register('number', {required: false})} id="number" placeholder={t("form_inscriptions_number")}/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <textarea className="form-control border-radius-0" {...register('complement', {required: false})} id="complement" placeholder={t("form_compl_info")}/>
                                                                </div>
                                                                {/* <div className="form-check">
                                                                    <input type="checkbox" className="form-check-input border-radius-0" {...register('interested', {required: false})} id="interested" placeholder={t("form_interested")}/>
                                                                    <label htmlFor="interested" className="form-check-label">{t("form_interested")}</label>
                                                                </div> */}
                                                                <div className="form-check mb-2">
                                                                    <input type="checkbox" className="form-check-input border-radius-0" {...register('acceptMentions', {required: false})} id="acceptMentions" placeholder={t("form_accept")}/>
                                                                    <label htmlFor="acceptMentions" className="form-check-label">{t("form_accept")}</label>
                                                                </div>

                                                                <div className="mb-3">
                                                                    {
                                                                        loading ?
                                                                        <button disabled className="btn btn-primary border-radius-0">
                                                                            {t("please_wait")}
                                                                        </button>
                                                                        :
                                                                        <button className="btn btn-primary border-radius-0">
                                                                            {t("i_subscribe")}
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div>

                                                <p className="title">
                                                    {t("do_you_want_to_follow_this_training")}
                                                </p>
                                                <p className="text">
                                                    {t("training_availability")}
                                                <br />
                                                    {t("choose_modality")}
                                                </p>
                                                <div>
                                                    <div className="row classes-places">
                                                        { course?.trainingPlaces.includes("face-to-face") && <div className="col-6">
                                                            <div onClick={() => handleChange("face")}>
                                                                <div className="classes-face">
                                                                    <img width={"60%"} src="/assets/img/group.png" alt="" />
                                                                    <p>
                                                                        {t("follow_training_in_presence")}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                        {course?.trainingPlaces.includes("online") && <div className="col-6">
                                                            <div onClick={() => handleChange("online")}>
                                                                <div className="classes-online">
                                                                    <img width={"60%"} src="/assets/img/video-conference.png" alt="" />
                                                                    <p>
                                                                        {t("follow_training_in_online")}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </motion.div>
                                        {/* <hr className="mt-0"/> */}
                                        <p>
                                            {t("your_company_need_special_offer")}
                                        </p>
                                        <Link to="#" onClick={handleAskTraining}>{t("contact_us")}</Link>
                                    </div>
                                </div>
                                {/* <!-- Modal --> */}
                                <div className="modal fade" id="exampleModalLong" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <form onSubmit={handleSubmit(handleSubmitForm)}>
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLongTitle">{t("custom_training_enter_your_informations")}</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="inscription-form text-align-left">
                                                        <div>
                                                                
                                                            <div className="form-group">
                                                                <label htmlFor="title">{t("home_contact_training_title") + " "}<span className="text-danger"> *</span></label>
                                                                <input type="text" className="form-control border-radius-0" {...register1('title', {required: true})} id="title" placeholder={t("home_contact_training_title") + " *"}/>
                                                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="society">{t("form_society") + " "}<span className="text-danger"> *</span></label>
                                                                <input type="text" className="form-control border-radius-0" {...register1('society', {required: true})} id="society" placeholder={t("form_society") + " *"}/>
                                                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="firstName">{t("home_contact_name") + " "}<span className="text-danger"> *</span></label>
                                                                <input type="text" className="form-control border-radius-0" {...register1('firstName', {required: true})} id="firstName" placeholder={t("home_contact_name") + " *"}/>
                                                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="lastName">{t("form_last_name") + " "}<span className="text-danger"> *</span></label>
                                                                <input type="text" className="form-control border-radius-0" {...register1('lastName', {required: true})} id="lastName" placeholder={t("form_last_name") + " *"}/>
                                                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="title">{t("starting_date") + " "}<span className="text-danger"> *</span></label>
                                                                <input type="date" className="form-control border-radius-0" {...register1('starting_date', {required: true})} id="startingDate" placeholder={t("starting_date") + " *"}/>
                                                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="endingDate">{t("ending_date") + " "}<span className="text-danger"> *</span></label>
                                                                <input type="date" className="form-control border-radius-0" {...register1('ending_date', {required: true})} id="endingDate" placeholder={t("ending_date") + " *"}/>
                                                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="role">{t("form_role") + " "}<span className="text-danger"> *</span></label>
                                                                <input type="text" className="form-control border-radius-0" {...register1('role', {required: true})} id="role" placeholder={t("form_role") + " *"}/>
                                                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="phone">{t("phone") + " "}<span className="text-danger"> *</span></label>
                                                                <input type="text" className="form-control border-radius-0" {...register1('phone', {required: true})} id="phone" placeholder={t("home_contact_phone") + " *"}/>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="postalCode">{t("form_postal_code") + " "}</label>
                                                                <input type="text" className="form-control border-radius-0" {...register1('postalCode', {required: false})} id="postalCode" placeholder={t("form_postal_code")}/>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="email">{t("home_contact_email") + " "}<span className="text-danger"> *</span></label>
                                                                <input type="email" className="form-control border-radius-0" {...register1('email', {required: true})} id="email" placeholder={t("home_contact_email") + " *"}/>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="number">{t("form_inscriptions_number") + " "}<span className="text-danger"> *</span></label>
                                                                <input type="number" className="form-control border-radius-0" {...register1('number', {required: false})} id="number" placeholder={t("form_inscriptions_number")}/>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="complement">{t("form_compl_info") + " "}<span className="text-danger"> *</span></label>
                                                                <textarea className="form-control border-radius-0" {...register1('complement', {required: false})} id="complement" placeholder={t("form_compl_info")}/>
                                                            </div>
                                                            {/* <div className="form-check text-left">
                                                                <input type="checkbox" className="form-check-input border-radius-0" {...register1('interested', {required: false})} id="interested" placeholder={t("form_interested")}/>
                                                                <label htmlFor="interested" className="form-check-label">{t("form_interested")}</label>
                                                            </div> */}
                                                            <div className="form-check mb-2 text-left">
                                                                <input type="checkbox" className="form-check-input border-radius-0" {...register1('acceptMentions', {required: false})} id="acceptMentions" placeholder={t("form_accept")}/>
                                                                <label htmlFor="acceptMentions" className="form-check-label">{t("form_accept")}</label>
                                                            </div>

                                                            {/* <div className="mb-3">
                                                                {
                                                                    loading ?
                                                                    <button disabled className="btn btn-primary border-radius-0">
                                                                        {t("please_wait")}
                                                                    </button>
                                                                    :
                                                                    <button className="btn btn-primary border-radius-0">
                                                                        {t("i_subscribe")}
                                                                    </button>
                                                                }
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <div className="">

                                                        <button type="button" className="btn btn-secondary border-radius-0 mt-0" data-dismiss="modal">{t("close")}</button>
                                                    </div>
                                                    <div className="">
                                                        {
                                                            loading ?
                                                            <button disabled className="btn btn-primary border-radius-0">
                                                                {t("obtain_estimation")}
                                                            </button>
                                                            :
                                                            <button type="submit" className="btn btn-primary border-radius-0">
                                                                {t("obtain_estimation")}
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <Footer/>
        </Page>
    )
}


export default TrainingDetails;