import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/footer/Footer";
import Header3 from "../../components/header/Header3";
import Player from "../../components/player/Player";
import { customPlayerJs } from "../../components/player/playerJS";




export default function () {

    const { t } = useTranslation();

    React.useEffect(() => {
        customPlayerJs();
    }, [])
    return (
        <div>
            <Header3/>
            <section style={{marginTop: "150px"}} >
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-12"></div>
                        <div className="col-lg-6 col-12">
                            <p className="template-page-title" >
                                {t("header_our_expertise")}
                            </p>
                            <h2 style={{fontFamily: 'Hurme'}} >
                                <strong>
                                    {/* Sed ut perspiciatis unde omnis iste  */}
                                </strong>
                            </h2>
                            <p className="about-us-text mt-3">
                                {t("about_text_p_2")}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop: '50px'}} >
                    <div className="col-lg-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad.png')", backgroundSize: 'cover'}}>
                        <div className="temp-h-500">
                        </div>
                    </div>
                    <div className="col-lg-0 col-6">
                        
                    </div>
                    <div className="col-lg-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad2.jpg')", backgroundSize: 'cover'}}>
                        <div className="temp-h-500">
                        </div>
                    </div>
                    <div className="col-lg-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad3.jpg')", backgroundSize: 'cover'}}>
                        <div className="temp-h-500">
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-align-left" style={{marginTop: "100px"}} >
                <div className="container">
                    <p className="template-page-title-text" >
                    {/* Sed ut perspiciatis */}
                    </p>
                    <p className="about-us-text mt-3">
                        {t("about_text_p_2")}
                        {t("about_text_p_2")}
                        {t("about_text_p_2")}
                        {t("about_text_p_2")}
                    </p>
                    <div className="row p-l-1" style={{marginTop: '50px'}} >
                        <div className="col-lg-6 col-12" style={{backgroundImage: "url('assets/img/man-work-pad.png')", backgroundSize: 'cover'}}>
                            <div className="temp-h-500">
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            <section className="text-align-left bg-green" style={{marginTop: "50px", paddingTop: '100px', paddingBottom: '100px'}} >
                <div className="container">
                    <p className="template-page-title-text" >
                    {/* Sed ut perspiciatis */}
                    </p>
                    <p className="about-us-text mt-3">
                        {t("about_text_p_2")}
                        {t("about_text_p_2")}
                        {t("about_text_p_2")}
                        {t("about_text_p_2")}
                    </p>
                    <div className="row p-l-1" style={{marginTop: '50px'}} >
                        <div className="col-lg-6 col-12">
                            <div className="temp-h-500">
                                <Player/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-align-left" style={{marginTop: "100px"}} >
                <div className="container">
                    <p className="template-page-title-text" >
                    Sed ut perspiciatis
                    </p>
                    <p className="about-us-text mt-3">
                        {t("about_text_p_2")}
                        {t("about_text_p_2")}
                        {t("about_text_p_2")}
                        {t("about_text_p_2")}
                    </p>
                </div>
                
            </section>
            <section style={{marginTop: "150px"}} >
                <div className="row" style={{marginTop: '50px'}} >
                    <div className="col-lg-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad.png')", backgroundSize: 'cover'}}>
                        <div className="temp-h-500">
                        </div>
                    </div>
                    <div className="col-lg-0 col-6">
                        
                    </div>
                    <div className="col-lg-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad2.jpg')", backgroundSize: 'cover'}}>
                        <div className="temp-h-500">
                        </div>
                    </div>
                    <div className="col-lg-4 col-6" style={{backgroundImage: "url('assets/img/man-work-pad3.jpg')", backgroundSize: 'cover'}}>
                        <div className="temp-h-500">
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-align-left" style={{marginTop: '50px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="m-t-3">
                                <p className="template-page-title-text" >
                                Sed ut perspiciatis
                                </p>
                                <p className="about-us-text mt-3">
                                    {t("about_text_p_2")}
                                    {t("about_text_p_2")}
                                    {t("about_text_p_2")}
                                    {t("about_text_p_2")}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div>
                                <div className="text-template-img-rounded" style={{backgroundImage: "url('assets/img/man-work-pad3.jpg')", backgroundSize: 'cover'}}>
                                    {/* <img src="assets/img/man-work-pad3.jpg" height={'100%'} alt="" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-t-3 m-b-3">
                        <div className="col-lg-6 col-12">
                            <div>
                                <div className="text-template-img-rounded" style={{backgroundImage: "url('assets/img/man-work-pad3.jpg')", backgroundSize: 'cover'}}>
                                    {/* <img src="assets/img/man-work-pad3.jpg" height={'100%'} alt="" /> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="m-t-3">
                                <p className="template-page-title-text" >
                                Sed ut perspiciatis
                                </p>
                                <p className="about-us-text mt-3">
                                    {t("about_text_p_2")}
                                    {t("about_text_p_2")}
                                    {t("about_text_p_2")}
                                    {t("about_text_p_2")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    )
}