import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import Footer from "../../components/footer/Footer"
import Header from "../../components/header/Header"
import Page from "../../page/Page"
import OurTeam from "../about/OurTeam"
import { digitAnimation } from "./components/digitanimations/digitAnimations"



export default function () {

    const { t } = useTranslation()

    useEffect(() => {
        
        digitAnimation();

    }, [])

    return (
        <Page
            title={"OVA - Consulting | Contact"}
            description={t("digitalize_us")}
        >
            <div className="main digit">
                <div className="main-content">
                    <Header/>
                        {/* <!-- SCROLLER START --> */}
                        <div className="main-scroller">
                            <div className="link active" data-scroller="digit">
                                <a className="link-text-no-effect" href="#digit">
                                    {t('digitalize_us')}
                                </a>
                            </div>
                            <div className="link" data-scroller="definition">
                                <a className="link-text-no-effect" href="#definition">
                                    {t('def_of')} {' '} {t('digit')}
                                </a>
                            </div>
                            <div className="link" data-scroller="strategy">
                                <a className="link-text-no-effect" href="#strategy">
                                    {t('our_strategy')}
                                </a>
                            </div>
                            <div className="scrolltop"></div>
                        </div>
                        {/* <!-- SCROLLER END --> */}
                    {/* <!-- SECTION START --> */}
                    <section className="hero " id="digit" style={{backgroundImage: "url(assets/img/background-digit.jpg)"}}>
                        <div className="container">
                            <div className="hero-title ">
                            {t('digitalize_us')}
                            </div>
                            <div className="hero-banner ">
                            <div className="title">
                                <p className="home-swiper-title" style={{color:'#fff'}}>

                                    {t('digitalize')}
                                </p>
                            </div>
                            <div className="excerpt">
                            {t('digitalize_text')}
                            </div>
                            </div>
                            <div className="hero-scrolldown"></div>
                        </div>
                        </section>
                        {/* <!-- SECTION END -C'est quand mm mieux 😊-> */}

                        {/* <!-- SECTION START --> */}
                        <div className="container" >
                            <section className="definition" id="definition">
                                <div className="container">
                                    <div className="content">
                                    <div className="title">
                                        <p className="def-of-digit">
                                            {t('def_of')} {' '}
                                            <span style={{color: '#008E87'}} >{t('digit')}</span>
                                        </p>
                                    </div>
                                    <div className="excerpt">
                                        {t("to_us_text")}
                                    </div>
                                    </div>
                                    <div className="picture d-flex">
                                    <div className="img" style={{backgroundImage: "url(/assets/img/digit-img.png)"}}></div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        {/* <!-- SECTION END --> */}

                        {/* <!-- SECTION START --> */}
                        <section className="hiw" id="strategy">
                            <div className="container">
                                <div className="hiw-highlight">
                                    <div className="title">
                                        {t("our_strategy")} 
                                    </div>
                                    <div className="excerpt">
                                        {/* {t("how_it_work_text")} */}
                                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat
                                    </div>
                                </div>
                                <div className="hiw-content">
                                    <div style={{minHeight: '500px', width: '100%', paddingTop: '4rem'}} id="carouselMethodsLeftControls" className="carousel slide" data-ride="carousel" data-interval="3000">
                                        {/* <ol className="carousel-indicators">
                                            <li data-target="#carouselMethodsLeftControls" style={{background:'#0092893d'}} data-slide-to="0" className="active"></li>
                                            <li data-target="#carouselMethodsLeftControls" style={{background:'#0092893d'}} data-slide-to="1"></li>
                                            <li data-target="#carouselMethodsLeftControls" style={{background:'#0092893d'}} data-slide-to="2"></li>
                                        </ol> */}
                                        <div className="carousel-inner">
                                            <div id="carousel-item-1" className="carousel-item active">
                                                <div className="picture active d-flex">
                                                    <div className="img" style={{backgroundImage: "url(/assets/img/maintenance.png)", height: '300px', maxWidth: '250px', margin: "auto"}}>
                                                        {/* <img src="/assets/img/methods.png" alt="..." /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="carousel-item-2" className="carousel-item">
                                                <div className="picture active d-flex">
                                                    <div className="img" style={{backgroundImage: "url(/assets/img/architect.png)", height: '300px', maxWidth: '250px', margin: "auto"}}>
                                                        {/* <img src="/assets/img/architect.png" alt="..." /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="carousel-item-3" className="carousel-item">
                                                <div className="picture active d-flex">
                                                    <div className="img" style={{backgroundImage: "url(/assets/img/devops.png)", height: '300px', maxWidth: '250px', margin: "auto"}}>
                                                        {/* <img src="/assets/img/devops.png" alt="..." /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="carousel-item-4" className="carousel-item">
                                                <div className="picture active d-flex">
                                                    <div className="img" style={{backgroundImage: "url(/assets/img/cloud.png)", height: '300px', maxWidth: '250px', margin: "auto"}}>
                                                        {/* <img src="/assets/img/cloud.png" alt="..." /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="carousel-item-5" className="carousel-item">
                                                <div className="picture active d-flex">
                                                    <div className="img" style={{backgroundImage: "url(/assets/img/methods.png)", height: '300px', maxWidth: '250px', margin: "auto"}}>
                                                        {/* <img src="/assets/img/maintenance.png" alt="..." /> */}
                                                    </div>
                                                </div>
                                            </div>  
                                            <div id="carousel-item-6" className="carousel-item">
                                                <div className="picture active d-flex">
                                                    <div className="img" style={{backgroundImage: "url(/assets/svg/saas.svg)", height: '300px', maxWidth: '250px', margin: "auto"}}>
                                                        {/* <img src="/assets/img/maintenance.png" alt="..." /> */}
                                                    </div>
                                                </div>
                                            </div>  
                                            <div id="carousel-item-7" className="carousel-item">
                                                <div className="picture active d-flex">
                                                    <div className="img" style={{backgroundImage: "url(/assets/svg/dev.svg)", height: '300px', maxWidth: '250px', margin: "auto"}}>
                                                        {/* <img src="/assets/img/maintenance.png" alt="..." /> */}
                                                    </div>
                                                </div>
                                            </div>  
                                            <div id="carousel-item-8" className="carousel-item">
                                                <div className="picture active d-flex">
                                                    <div className="img" style={{backgroundImage: "url(/assets/svg/tools-big.svg)", height: '300px', maxWidth: '250px', margin: "auto"}}>
                                                        {/* <img src="/assets/img/maintenance.png" alt="..." /> */}
                                                    </div>
                                                </div>
                                            </div>  
                                            <div id="carousel-item-9" className="carousel-item">
                                                <div className="picture active d-flex">
                                                    <div className="img" style={{backgroundImage: "url(/assets/svg/maketing-big.svg)", height: '300px', maxWidth: '250px', margin: "auto"}}>
                                                        {/* <img src="/assets/img/maintenance.png" alt="..." /> */}
                                                    </div>
                                                </div>
                                            </div>  
                                            <div id="carousel-item-10" className="carousel-item">
                                                <div className="picture active d-flex">
                                                    <div className="img" style={{backgroundImage: "url(/assets/svg/pwa-big.svg)", height: '300px', maxWidth: '250px', margin: "auto"}}>
                                                        {/* <img src="/assets/img/maintenance.png" alt="..." /> */}
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                    <div className="website swiper-container">
                                        <div style={{minHeight: '500px', width: '100%', paddingTop: '4rem'}} id="carouselMethodsRightControls" className="carousel slide" data-ride="carousel" data-interval="3000">
                                            <ol className="digit-carousel-indicators carousel-indicators">
                                                <li data-target="#carouselMethodsRightControls" style={{background:'#0092893d'}} data-slide-to="0" className="active"></li>
                                                <li data-target="#carouselMethodsRightControls" style={{background:'#0092893d'}} data-slide-to="1"></li>
                                                <li data-target="#carouselMethodsRightControls" style={{background:'#0092893d'}} data-slide-to="2"></li>
                                                <li data-target="#carouselMethodsRightControls" style={{background:'#0092893d'}} data-slide-to="3"></li>
                                                <li data-target="#carouselMethodsRightControls" style={{background:'#0092893d'}} data-slide-to="4"></li>
                                                <li data-target="#carouselMethodsRightControls" style={{background:'#0092893d'}} data-slide-to="4"></li>
                                                <li data-target="#carouselMethodsRightControls" style={{background:'#0092893d'}} data-slide-to="4"></li>
                                                <li data-target="#carouselMethodsRightControls" style={{background:'#0092893d'}} data-slide-to="4"></li>
                                                <li data-target="#carouselMethodsRightControls" style={{background:'#0092893d'}} data-slide-to="4"></li>
                                                <li data-target="#carouselMethodsRightControls" style={{background:'#0092893d'}} data-slide-to="4"></li>
                                            </ol>
                                            <div className="carousel-inner">
                                                <div id="carousel-item-1" className="carousel-item active">
                                                    <div className="swiper-slide " data-slide-id="methods">
                                                        <div className="title digit-slider-title">
                                                        {t("how_it_work_1")}
                                                        </div>
                                                        <div className="excerpt">
                                                        {t("how_it_work_1_text")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="carousel-item-2" className="carousel-item">

                                                    <div className="swiper-slide" data-slide-id="architect">
                                                        <div className="title digit-slider-title">
                                                        {t("how_it_work_2")}
                                                        </div>
                                                        <div className="excerpt">
                                                        {t("how_it_work_2_text")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="carousel-item-3" className="carousel-item">
                                                    <div className="swiper-slide" data-slide-id="devops">
                                                        <div className="title digit-slider-title">
                                                        {t("how_it_work_3")}
                                                        </div>
                                                        <div className="excerpt">
                                                        {t("how_it_work_3_text")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="carousel-item-4" className="carousel-item">

                                                    <div className="swiper-slide" data-slide-id="cloud">
                                                        <div className="title digit-slider-title">
                                                        {t("how_it_work_4")}
                                                        </div>
                                                        <div className="excerpt">
                                                        {t("how_it_work_4_text")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="carousel-item-5" className="carousel-item">
                                                    <div className="swiper-slide swiper-slide-active" data-slide-id="maintenance">
                                                        <div className="title digit-slider-title">
                                                        {t("how_it_work_5")}
                                                        </div>
                                                        <div className="excerpt">
                                                        {t("how_it_work_5_text")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="carousel-item-6" className="carousel-item">
                                                    <div className="swiper-slide swiper-slide-active" data-slide-id="maintenance">
                                                        <div className="title digit-slider-title">
                                                        {t("how_it_work_6")}
                                                        </div>
                                                        <div className="excerpt">
                                                        {t("how_it_work_6_text")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="carousel-item-7" className="carousel-item">
                                                    <div className="swiper-slide swiper-slide-active" data-slide-id="maintenance">
                                                        <div className="title digit-slider-title">
                                                        {t("how_it_work_7")}
                                                        </div>
                                                        <div className="excerpt">
                                                        {t("how_it_work_7_text")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="carousel-item-8" className="carousel-item">
                                                    <div className="swiper-slide swiper-slide-active" data-slide-id="maintenance">
                                                        <div className="title digit-slider-title">
                                                        {t("how_it_work_8")}
                                                        </div>
                                                        <div className="excerpt">
                                                        {t("how_it_work_8_text")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="carousel-item-9" className="carousel-item">
                                                    <div className="swiper-slide swiper-slide-active" data-slide-id="maintenance">
                                                        <div className="title digit-slider-title">
                                                        {t("how_it_work_9")}
                                                        </div>
                                                        <div className="excerpt">
                                                        {t("how_it_work_9_text")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="carousel-item-10" className="carousel-item">
                                                    <div className="swiper-slide swiper-slide-active" data-slide-id="maintenance">
                                                        <div className="title digit-slider-title">
                                                        {t("how_it_work_10")}
                                                        </div>
                                                        <div className="excerpt">
                                                        {t("how_it_work_10_text")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="swiper-wrapper">
                                            
                                        </div>
                                        <div className="swiper-controls">
                                            <div className="swiper-previous"></div>
                                            <div className="swiper-counter"></div>
                                            <div className="swiper-next"></div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <!-- SECTION END --> */}


                <Footer/>
                </div>  
            </div>
        </Page>
    )
}