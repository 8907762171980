import { Routes, Route } from 'react-router-dom';

import AboutUs from '../../views/about/AboutUs';
import AnnouncesList from '../../views/AnouncesList/AnnouncesList';
import Contact from '../../views/contact/Contact';
import Digit from '../../views/digit/Digit';
import Page404 from '../../views/errors/page-404/Page404';
import Expertise from '../../views/expertise/Expertise';
import ExpertiseDatascience from '../../views/expertise/ExpertiseDataSciance';
import ExpertiseDetails from '../../views/expertise/ExpertiseDetails';
import ExpertiseInfrastructure from '../../views/expertise/ExpertiseInfrastructures';
import ExpertiseSoftware from '../../views/expertise/ExpertiseSoftware';
import TrainingDetails from '../../views/home/components/trainings/TrainingDetails';
import TrainingsPage from '../../views/home/components/trainings/TrainingsPage';
import Home from '../../views/home/Home';
import OurMethods from '../../views/ourMethods/OurMethods';
import OurRef from '../../views/ourRef/OurRef';
import Ovastore from '../../views/ovaStore';
import CartPage from '../../views/ovaStore/components/cart/CartPage';
import Checkout from '../../views/ovaStore/components/checkout/Checkout';
import Hosting from '../../views/ovaStore/components/hosting/Hosting';
import StoreArticleDetails from '../../views/ovaStore/components/licenses/components/details/StoreArticleDetails';
import LicensesEquipements from '../../views/ovaStore/components/licenses/LicensesEquipements';
import PrivacyPolicy from '../../views/privacyPolicy/PrivacyPolicy';
import JobApplication from '../../views/recruitment/JobApplication';
import Recruitment from '../../views/recruitment/Recruitment';
import RecruitmentDetails from '../../views/recruitment/RecruitmentDetails';
import Services from '../../views/services/Services';
import Strategies from '../../views/strategies/Strategies';
import TextTemplate from '../../views/textTemplate/TextTemplate';
import SlashScreen from '../slashSceen/SlashScreen';
import OvaStoreLayout from '../Layouts/OvaStoreLayout';

export default function RoutesComponent() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/recruitment/:job_id/details"
        element={<RecruitmentDetails />}
      />
      <Route path="/recruitment/:job_id/apply" element={<JobApplication />} />
      <Route path="/recruitment" element={<Recruitment />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/slash" element={<SlashScreen />} />
      <Route path="/contact-reason-WORK" element={<Contact />} />
      <Route path="/digit" element={<Digit />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/template" element={<TextTemplate />} />
      <Route path="/references" element={<OurRef />} />
      <Route path="/trainings" element={<TrainingsPage />} />
      <Route path="/trainings/:training" element={<TrainingDetails />} />
      <Route path="/expertises" element={<Expertise />} />
      <Route path="/expertises/:expertise" element={<ExpertiseDetails />} />
      <Route path="/services-produits" element={<Services />} />
      <Route path="/our-methods" element={<OurMethods />} />
      <Route path="/our-strategy" element={<Strategies />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/anounces" element={<AnnouncesList />} />

      {/* OVA store */}
      <Route path="/ova-store" element={<OvaStoreLayout />}>
        <Route index element={<Ovastore />} />
        <Route path="hosting" element={<Hosting />} />
        <Route path="cart" element={<CartPage />} />
        <Route path="store/:store" element={<LicensesEquipements />} />
        <Route path="store/:store/checkout" element={<Checkout />} />
        <Route path="store/:store/cart" element={<CartPage />} />
        <Route
          path="store/:store/:articleId"
          element={<StoreArticleDetails />}
        />
      </Route>

      {/* <Route path="/ova-store" element={<Ovastore />} />
      <Route path="/ova-store/hosting" element={<Hosting />} />
      <Route path="/ova-store/cart" element={<CartPage />} />
      <Route path="/ova-store/store/:store" element={<LicensesEquipements />} />
      <Route path="/ova-store/store/:store/checkout" element={<Checkout />} />
      <Route path="/ova-store/store/:store/cart" element={<CartPage />} />
      <Route
        path="/ova-store/store/:store/:articleId"
        element={<StoreArticleDetails />}
      /> */}

      {/*
       */}
      <Route
        path="/expertise/infrastructure"
        element={<ExpertiseInfrastructure />}
      />
      <Route
        path="/expertise/data-science"
        element={<ExpertiseDatascience />}
      />
      <Route
        path="/expertise/software-engineering"
        element={<ExpertiseSoftware />}
      />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
